import React from 'react'

import TopLayout from '../../layout/TopLayout';
import MainPageWrapper from "../../layout/MainPageWrapper";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default class SuccessPaymentScreen extends React.Component {
  constructor(props)  {
    super(props);
  }

  render() {
    return (
      <div>
        <TopLayout history={this.props.history}/>
        <div style={{background: 'white', textAlign: 'center', marginBottom: 150,height: '100%'}}>
            <div className={'alert'}>
            <h1>Успешно плащане!</h1>
            <h3 style={{color: 'green'}}><CheckCircleIcon color={'green'} fontSize={'large'}/></h3>
            <p>Платените опции за вашата обява са активирани! </p>
            </div>
            <button onClick={()=>{
                this.props.history.push('/my-listings');
            }} style={{color: 'white', backgroundColor: 'rgb(255, 119, 0)'}} className={'btn btn-warning'}>
                Назад към моите обяви
            </button>
            <div style={{height:1000}}>&nbsp;</div>
        </div>
      </div>
    )
  }
}
