import React, { Component } from 'react';

class Simple extends React.Component {
    state = {  }
    render() { 
        return (
            <div>Simple React Component</div>
          );
    }
}
 
export default Simple;