import React from "react";
import MainPageWrapper from "../../layout/MainPageWrapper";
import WhiteBoxWrapper from "../../layout/WhiteBoxWrapper";
import Header from "../../components/ListingCreate/Header";
import NetworkClient from "../../api/NetworkClient";
import { Redirect } from 'react-router-dom'
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { MessageSharp } from "@material-ui/icons";

class PasswordResetTokenScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            email: "",
            pass: "",
            passAgian: "",
            responseMsg: "",
            errors: null,
            isLoading: false,
        }

    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }
    handlePassChange(event) {
        this.setState({ pass: event.target.value });
    }
    handlePassAgainChange(event) {
        this.setState({ passAgian: event.target.value });
    }

    getParams() {
        const searchParams = new URLSearchParams(window.location.search);
        return {
            token: searchParams.get('token') || '',
            email: searchParams.get('email') || '',
        };
    }

    passwordChange() {
        var token = this.getParams().token;
        var email = this.getParams().email;
        var password = this.state.pass;
        var passAgain = this.state.passAgian;

        this.setState({ isLoading: true });
        if (password.length >= 6 && passAgain.length >= 6 && password === passAgain) {

            NetworkClient.post("/password/reset-change", { token, password, email }).then(
                response => {
                    this.setState({ responseMsg: response });
                    if (response.msg == "Успешно сменена парола!") {
                        this.setState({ isLoading: false, errors: null })
                    }
                }
            );

        }
        else {
            this.setState({ errors: "Паролите не съвпадат и трябва да са над 6 символа!" })
            console.log("error")
            this.setState({ isLoading: false })
        }


    }

    render() {
        return (
            <div>
                <Header history={this.props.history} backPage={"/auth"} />
                <MainPageWrapper>
                    <WhiteBoxWrapper>
                        <h3 className="mt-5">Смяна на паролата</h3>
                        <p>За да си смените паролата си, попълнете по-долу E-mail-а, посочен при публикуване на обявите или регистрация.</p>
                        <div className="py-5">
                            {this.state.errors && (
                                <div className="alert alert-warning" role="alert">
                                    {this.state.errors}
                                </div>
                            )}
                            {this.state.responseMsg.msg && (
                                <div>
                                    <h4 className="alert alert-success">
                                        {this.state.responseMsg.msg}
                                    </h4>
                                    <Link to="/auth" className="btn text-white" style={{ background: "rgb(59, 111, 182)" }}>Вход</Link>
                                </div>
                            )}

                            {!this.state.responseMsg && (
                                <div>
                                    <div className="form-group">
                                        <input
                                            value={this.getParams().email}
                                            readOnly
                                            onChange={this.handleEmailChange.bind(this)}
                                            type="email" className="form-control" placeholder="Email...">
                                        </input>

                                    </div>
                                    <div className="form-group">
                                        <input
                                            onChange={this.handlePassChange.bind(this)}
                                            type="password" className="form-control" placeholder="Нова парола">
                                        </input>

                                    </div>
                                    <div className="form-group">
                                        <input
                                            onChange={this.handlePassAgainChange.bind(this)}
                                            type="password" className="form-control" placeholder="Въведи отново новата парола"
                                        ></input>

                                    </div>
                                    <Button
                                        disabled={this.state.isLoading}
                                        onClick={this.passwordChange.bind(this)}
                                        className="btn btn-block text-white"
                                        style={{ background: "rgb(59, 111, 182)" }}>ПРОМЕНИ
                             </Button>
                                </div>
                            )}
                        </div>

                    </WhiteBoxWrapper>
                </MainPageWrapper>
            </div >
        );
    }
}

export default PasswordResetTokenScreen;
