import React from "react";
import { Row, Col } from "reactstrap";
import GoBackArrow from "../GoBackArrow";
import { Search } from "@material-ui/icons";

const style = {
  title: {
    ineHeight: 34,
    fontSize: 18,
    textAlign: "center",
    color: "#3b6fb6"
  }
};


export default function header(props) {
  return (
    <div
      style={{
        top: 0,
        width: "100%",
        zIndex: "2000",
        position: "fixed",
        height: "50px",
        backgroundColor: "white",
        color: "#3b6fb6",
        boxShadow: "0 1px 4px rgba(0,0,0,.3)"
      }}
    >
      <Row className="mt-2">
        <Col xs={2}>
          <GoBackArrow history={props.history} backPage={"/my-profile"} />
        </Col>
        <Col xs={8} className={"text-center pt-1"}>
          <span style={style.title}>Публикувани</span>
        </Col>
        <Col onClick={() => {
            if(props.hideOld === undefined)
            {
                props.history.push('/filters')
            }
            else
            {
                props.handleSearchClick();
            }
        }}>
            <Search style={{ fontWeight: "900", fontSize: 30, color: 'rgb(255, 119, 0)' }} />
        </Col>
      </Row>
    </div>
  );
}
