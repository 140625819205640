import React from 'react';
import {Container} from 'reactstrap';
const styles = {
    wrapper: {   
        borderRadius: '4px',
        background: '#fff'
    }
}

class WhiteBoxWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <Container fluid={true} style={styles.wrapper}>
                {this.props.children}
            </Container>
         );
    }
}
 
export default WhiteBoxWrapper;