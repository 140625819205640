import React, { Fragment } from "react";
import {
  Row,
  Col,
  FormGroup,
  Button,
  Modal,
  Card,
  ListGroupItem
} from "reactstrap";
// import GoBackArrow from "../../components/GoBackArrow";
import { Container, IconButton } from "@material-ui/core";
import {
  CheckCircle,
  AddCircleRounded,
  Check,
  FilterDrama,
  Input,
  KeyboardArrowLeft
} from "@material-ui/icons";
import "./CreateListingScreen.css";
import Drawer from "@material-ui/core/Drawer";
import NetworkClient from "../../api/NetworkClient";
import Header from "../../components/ListingCreate/Header";
import FooterFixed from "../../components/ListingCreate/FooterFixed";
import { fil } from "date-fns/esm/locale";
import { positions } from "@material-ui/system";
import Constants from "../../constants/Constants";
import Cookies from "js-cookie";
import FilterTiresAndWheels from "./FilterTiresAndWheels";
import { validate } from "@babel/types";
import CircularProgress from '@material-ui/core/CircularProgress';

const checkIconStyle = {
  blue: {
    color: "#3b6fb6"
  },
  hide: {
    display: "none"
  }
};

const styles = {
  listings: {
    marginTop: "10px"
  },
  box: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "17px",
    padding: 7
  },
  bottomBorder: {
    borderBottom: "1px solid #ddd"
  },
  blue: {
    color: "#3b6fb6"
  },
  orange: {
    color: "#f70"
  }
};

export default class CreateListing extends React.Component {
  componentDidMount() {
    window.scroll(0, 0);
    const isLogged = Cookies.get("api_token");
    if (!isLogged) {
      this.props.history.push("/auth");
    } else {
      NetworkClient.get("listing").then(response => {
        this.setState({ categories: response.categories });
        this.setState({
          shownCategories: this.state.categories.filter(x => x.parent == 0)
        });
        this.setState({ cities: response.cities });
      });
    }
  }
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }
  state = {
    isLoadingImages: false,
    isLoading: false,
    isDisabled: false,
    isSetImg: false,
    isSetTitle: false,
    isOpen: false,
    isCategories: false,
    categories: [],
    shownCategories: [],
    backCategories: [],
    selectedCategory: -1,
    extraFields: [],
    drawerData: { data: [], name: "", model: [] },
    selectedExtras: [], //For multiple fields
    selectedExtrasTemp: [], //For multiple fields,
    isFilled: [],
    isPriceNegotiable: false,
    priceTemp: "",
    price: "",
    priceToSend: 0,
    currancy: "ЛВ.",
    cities: [],
    DrawerCities: false,
    city: "",
    villages: [],
    DrawerVillages: false,
    village: "",
    selectedMainFields: {},
    cityId: -1,
    villageId: null,
    imagePaths: [],
    imageFields: [],
    imageRotation: [],
    isOpenImageModal: false,
    selectedImageIndex: -1,
    is_private_person: true,
    isAnon: false,
    //Gumi i djanti
    seasons: [
      { id: "Летни", name: "Летни" },
      { id: "Зимни", name: "Зимни" },
      { id: "Всесезонни", name: "Всесезонни" }
    ],
    width: [
      70,
      80,
      85,
      90,
      95,
      100,
      105,
      110,
      115,
      120,
      125,
      130,
      135,
      140,
      145,
      150,
      155,
      160,
      165,
      170,
      175,
      180,
      185,
      190,
      195,
      200,
      205,
      210,
      215,
      220,
      225,
      230,
      235,
      240,
      245,
      250,
      255,
      260,
      265,
      270,
      275,
      280,
      285,
      295,
      300,
      305,
      315,
      325,
      330,
      335,
      345,
      355,
      375,
      395,
      425,
      435,
      445
    ],
    height: [
      25,
      30,
      35,
      40,
      45,
      50,
      55,
      60,
      65,
      70,
      75,
      80,
      82,
      85,
      90,
      95,
      100,
      105,
      110,
      115,
      120
    ],
    diameter: [
      10,
      11,
      12,
      13,
      14,
      15,
      15.5,
      16,
      16.5,
      17,
      18,
      19,
      19.5,
      20,
      21,
      22,
      22.5,
      23,
      24,
      26,
      28
    ],
    wheelsBrands: [
      "Accelera",
      "Achilles",
      "Admiral",
      "Alliance",
      "America",
      "Atturo",
      "Autogrip",
      "Avon",
      "Barum",
      "BCT",
      "BFGoodrich",
      "Bridgestone",
      "Capitol",
      "Ceat",
      "Clear",
      "Continental",
      "Cooper",
      "Dayton",
      "Debica",
      "Delinte",
      "Dextero",
      "Dunlop",
      "Duro",
      "Durun",
      "Effiplus",
      "Eurostone",
      "Falken",
      "Federal",
      "Firestone",
      "Fortuna",
      "Fulda",
      "Fullway",
      "General",
      "Gislaved",
      "GoldenTyre",
      "Goodride",
      "Goodyear",
      "Gremax",
      "GTRadial",
      "Haida",
      "Hankook",
      "Hero",
      "HIFLY",
      "HighPerformer",
      "Infinity",
      "InsaTurbo",
      "Interco",
      "Interstate",
      "Kelly",
      "Kenda",
      "Kinforest",
      "KingMeiler",
      "KingsTire",
      "Kingstar",
      "Kleber",
      "Kormoran",
      "Kumho",
      "Lassa",
      "Lexani",
      "Linglong",
      "Maloya",
      "Marangoni",
      "Marix",
      "Marshal",
      "Mastersteel",
      "Matador",
      "Maxtrek",
      "Maxxis",
      "Meteor",
      "Michelin",
      "MickeyThompson",
      "Minerva",
      "Nankang",
      "Nexen",
      "Nokian",
      "Novex",
      "Pace",
      "Petlas",
      "Pirelli",
      "Pneumant",
      "Recip",
      "Regal",
      "Riken",
      "Roadstone",
      "Rockstone",
      "Rotalla",
      "Rotex",
      "Runway",
      "Sailun",
      "Sava",
      "Semperit",
      "Silverstone",
      "Sonny",
      "Star",
      "StarPerformer",
      "Starco",
      "Starfire",
      "Starmaxx",
      "Stunner",
      "Sunew",
      "Sunny",
      "Syron",
      "Tigar",
      "Toyo",
      "Trayal",
      "Triangle",
      "Tyfoon",
      "Uniroyal",
      "Viking",
      "Vredestein",
      "VSP",
      "Wanli",
      "Westlake",
      "WinterTact",
      "Yokohama",
      "Yokohama",
      "Zeetex",
      "Zeetex",
      "Zetum",
      "Zetum",
      "Други"
    ],
    material: ["Алуминиеви", "Железни", "Магнезиеви", "Други"],
    markaavto: [
      { id: 1, name: "AC" },
      { id: 2, name: "Acura" },
      { id: 79, name: "Aixam" },
      { id: 80, name: "Alfa romeo" },
      { id: 81, name: "Aro" },
      { id: 82, name: "Asia" },
      { id: 83, name: "Aston Martin" },
      { id: 84, name: "Audi" },
      { id: 85, name: "Austin" },
      { id: 86, name: "BMW" },
      { id: 87, name: "Bentley" },
      { id: 88, name: "Berliner" },
      { id: 89, name: "Bertone" },
      { id: 90, name: "Blue Bird" },
      { id: 91, name: "Borgward" },
      { id: 92, name: "Brilliance" },
      { id: 93, name: "Bugatti" },
      { id: 94, name: "Buick" },
      { id: 95, name: "Cadillac" },
      { id: 96, name: "Chevrolet" },
      { id: 97, name: "Chrysler" },
      { id: 98, name: "Citroen" },
      { id: 99, name: "Corvette" },
      { id: 100, name: "Dacia" },
      { id: 101, name: "Daewoo" },
      { id: 102, name: "Daihatsu" },
      { id: 103, name: "Daimler" },
      { id: 104, name: "Datsun" },
      { id: 105, name: "Dkw" },
      { id: 106, name: "Dodge" },
      { id: 107, name: "Dr" },
      { id: 108, name: "Eagle" },
      { id: 109, name: "FSO" },
      { id: 110, name: "Ferrari" },
      { id: 111, name: "Fiat" },
      { id: 112, name: "Ford" },
      { id: 113, name: "GOUPIL" },
      { id: 114, name: "Gaz" },
      { id: 115, name: "Geo" },
      { id: 116, name: "Gmc" },
      { id: 117, name: "Great wall" },
      { id: 118, name: "Haval" },
      { id: 119, name: "Heinkel" },
      { id: 120, name: "Hillman" },
      { id: 121, name: "Honda" },
      { id: 122, name: "Hummar" },
      { id: 123, name: "Hyundai" },
      { id: 124, name: "Ifa" },
      { id: 125, name: "Infinity" },
      { id: 126, name: "Innocenti" },
      { id: 127, name: "Isuzu" },
      { id: 128, name: "Iveco" },
      { id: 129, name: "Jaguar" },
      { id: 130, name: "Jeep" },
      { id: 131, name: "Jpx" },
      { id: 132, name: "Kia" },
      { id: 133, name: "Lada" },
      { id: 134, name: "Laforza" },
      { id: 135, name: "Lamborghini" },
      { id: 136, name: "Lancia" },
      { id: 137, name: "Land rover" },
      { id: 138, name: "Landwind" },
      { id: 139, name: "Ldv" },
      { id: 140, name: "Lexus" },
      { id: 141, name: "Lifan" },
      { id: 142, name: "Lincoln" },
      { id: 143, name: "Lotus" },
      { id: 144, name: "Mahindra" },
      { id: 145, name: "Meserati" },
      { id: 146, name: "Matra" },
      { id: 147, name: "Maybach" },
      { id: 148, name: "Mazda" },
      { id: 149, name: "McLaren" },
      { id: 150, name: "Mercedes-Benz" },
      { id: 151, name: "Mercury" },
      { id: 152, name: "Mg" },
      { id: 153, name: "Microcar" },
      { id: 154, name: "Mini" },
      { id: 155, name: "Mitsubishi" },
      { id: 156, name: "Morgan" },
      { id: 157, name: "Moskvich" },
      { id: 158, name: "Nissan" },
      { id: 159, name: "Oldsmobile" },
      { id: 160, name: "Oltsit" },
      { id: 161, name: "Opel" },
      { id: 162, name: "Perodua" },
      { id: 163, name: "Peugeot" },
      { id: 164, name: "Pgo" },
      { id: 165, name: "Plymouth" },
      { id: 166, name: "Polonez" },
      { id: 167, name: "Pontiac" },
      { id: 168, name: "Porsche" },
      { id: 169, name: "Proton" },
      { id: 170, name: "Renault" },
      { id: 171, name: "Rolls-Royce" },
      { id: 172, name: "Rover" },
      { id: 173, name: "SECMA" },
      { id: 174, name: "SH Auto" },
      { id: 175, name: "Saab" },
      { id: 176, name: "Samand" },
      { id: 177, name: "Saturn" },
      { id: 178, name: "Scion" },
      { id: 179, name: "Seat" },
      { id: 180, name: "Setra" },
      { id: 181, name: "Shatenet" },
      { id: 182, name: "Shuanghuan" },
      { id: 183, name: "Simca" },
      { id: 184, name: "Skoda" },
      { id: 185, name: "Smart" },
      { id: 186, name: "SsangYong" },
      { id: 187, name: "Subaru" },
      { id: 188, name: "Suzuki" },
      { id: 189, name: "Talbot" },
      { id: 190, name: "Tata" },
      { id: 191, name: "Tavria" },
      { id: 192, name: "Tazzari" },
      { id: 193, name: "Tempo" },
      { id: 194, name: "Terberg" },
      { id: 195, name: "Tesla" },
      { id: 196, name: "Tofas" },
      { id: 197, name: "Toyota" },
      { id: 198, name: "Trabant" },
      { id: 199, name: "Triumph" },
      { id: 200, name: "Uaz" },
      { id: 201, name: "VROMOS" },
      { id: 202, name: "VW" },
      { id: 203, name: "Volga" },
      { id: 204, name: "Volvo" },
      { id: 205, name: "Warszawa" },
      { id: 206, name: "Wartburg" },
      { id: 207, name: "Wiesmann" },
      { id: 208, name: "Xinkai" },
      { id: 209, name: "Xinshun" },
      { id: 210, name: "Zastava" },
      { id: 211, name: "Zaz" }
    ]
    //GUMI I DJANTI END
  };

  handlePrivatePerson = event => {
    this.setState({ is_private_person: event.target.checked });
  };

  toggleModal() {
    this.setState({
      isOpenImageModal: !this.state.isOpenImageModal,
      selectedImageIndex: -1
    });
  }

  handleImageClick(index) {
    this.setState({ selectedImageIndex: index });
  }

  handleImageUpload(event) {
    let files = Array.from(event.target.files);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("files[" + i + "]", file);
    });
    this.setState({isLoadingImages: true});

    let api_token = Cookies.get("api_token");
    fetch(`${Constants.BASE_URL_API}image-upload?api_token=${api_token}`, {
      method: "POST",
      body: formData
    })
      .then(res => res.json())
      .then(response => {
        let currentImages = this.state.imagePaths;
        let currentRotations = this.state.imageRotation;
        for (let i = 0; i < response.filePaths.length; i++) {
          currentRotations.push(0);
          currentImages.push(response.filePaths[i]);
        }
        this.setState({
          imagePaths: currentImages,
          imageRotation: currentRotations,
          isLoadingImages: false
        });
      });
  }
  handleChange(event) {
    const name = event.target.name;
    let value = event.target.value;
    const maxLength = event.target.maxLength;

    if (maxLength) {
      value = event.target.value.slice(0, maxLength)
      this.setState({ [name]: value })
    }

    if (!this.state.isFilled.includes(name) && value.length > 0) {
      let isFilled = this.state.isFilled;
      isFilled.push(name);
      this.setState({ isFilled });
    } else if (value.length == 0) {
      let isFilled = this.state.isFilled;
      let index = isFilled.indexOf(name);
      if (index > -1) {
        isFilled.splice(index, 1);
      }
      this.setState({ isFilled });
    }

    let fieldName = name;

    if (value.length > 0 || value != "") {
      //this.setState({ [fieldName]: true });
      let selectedMainFields = this.state.selectedMainFields;
      selectedMainFields[fieldName] = { name: fieldName, value: value };
      this.setState({ selectedMainFields: selectedMainFields });
    }
    /*if (!isNaN(value)) {
      this.setState({ [fieldName]: false });
    }*/
  }

  handlePriceChange(event) {
    let value = event.target.value;
    const name = event.target.name;
    const maxLength = event.target.maxLength;
    if (maxLength) {
      value = event.target.value.slice(0, maxLength)
      this.setState({ [name]: value })
    }
  }

  handleCurrancyChange = event => {
    this.setState({
      currancy: event.target.options[event.target.selectedIndex].text
    });
  };

  toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({
      isOpen: open,
      priceFieldForDrawer: false,
      isCategories: false,
      DrawerCities: false,
      DrawerVillages: false
    });
  };

  /* Categories start */
  loadExtraFilters(categoryId) {
    NetworkClient.get("extra-filters/" + categoryId).then(response => {
      this.setState({ extraFields: response.filters });
      this.setState({ selectedExtras: [] });
      this.setState({ selectedExtrasTemp: [] });
    });
  }
  changeShownCategories(categoryId, parent) {
    const { backCategories } = this.state;
    if (backCategories.filter(x => x == parent).length === 0) {
      backCategories.push(parent);
      this.setState({ backCategories });
    }

    let childCategories = this.state.categories.filter(
      x => x.parent == categoryId
    );

    if (childCategories.length === 0) {
      this.loadExtraFilters(categoryId);
      this.setState({ selectedCategory: categoryId });
      this.setState({ isOpen: false });
      return;
    }

    childCategories = childCategories.map(function (x) {
      x.icon = '';
      return x;
    });

    this.setState({ shownCategories: childCategories });
  }

  printCategories() {
    return this.state.shownCategories.map((c, i) => (
      <div
        onClick={() => {
          this.changeShownCategories(c.id, c.parent);
        }}
        key={i}
        style={Object.assign({}, styles.box, styles.bottomBorder, styles.blue)}
      >
        {c.icon ? <i style={{ color: c.color }} className={c.icon} /> : ""}{" "}
        {c.title}{" "}
        {c.id === this.state.selectedCategory ? (
          <Check
            className="float-right "
            style={{ color: "rgb(255, 119, 0)" }}
          />
        ) : (
            ""
          )}
      </div>
    ));
  }

  printBackCategory() {
    if (this.state.backCategories.length > 0) {
      return (
        <ListGroupItem
          style={Object.assign(
            {},
            styles.box,
            styles.bottomBorder,
            styles.blue
          )}
          onClick={() => {
            let { backCategories } = this.state;
            let parent = backCategories.pop();
            this.setState({ backCategories: backCategories });
            this.setState({
              shownCategories: this.state.categories.filter(
                x => x.parent == parent
              )
            });

            if (this.state.categories[0].parent !== parent) {
              parent = backCategories.pop();
              this.setState({ backCategories: backCategories });
              this.setState({
                shownCategories: this.state.categories.filter(
                  x => x.parent == parent
                )
              });
            }
          }}
        >
          <KeyboardArrowLeft /> Назад
        </ListGroupItem>
      );
    }

    return <div />;
  }
  /* Categories END */

  //**************** */
  //**************** */
  //* Category 24 Gumi I Djanti Logic  */
  //**************** */
  //**************** */

  setGumiIDjantiExtraFields(selName) {
    //**************** */
    //    Sezonnost
    //**************** */
    if (
      (selName == "Гуми" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "season") == -1
    ) {
      let ef = this.state.extraFields;
      let seasons = {
        data: this.state.seasons,
        isTextField: false,
        multiple: false,
        name: "season",
        selected: { id: "", name: "" },
        title: "Сезонност:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }
    //Premahni sezonnost ot djantite
    if (
      selName == "Джанти" &&
      this.state.extraFields.findIndex(x => x.name == "season") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "season");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //   Sezonnost End
    //**************** */

    //**************** */
    //   Width End
    //**************** */
    if (
      (selName == "Гуми" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "width_a") == -1
    ) {
      let ef = this.state.extraFields;
      let data = this.state.width.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "width_a",
        selected: { id: "", name: "" },
        title: "Ширина:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Джанти" &&
      this.state.extraFields.findIndex(x => x.name == "width_a") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "width_a");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }

    //**************** */
    //   Width End
    //**************** */

    //**************** */
    //   Height
    //**************** */
    if (
      (selName == "Гуми" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "height") == -1
    ) {
      let ef = this.state.extraFields;
      let data = this.state.height.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "height",
        selected: { id: "", name: "" },
        title: "Височина:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Джанти" &&
      this.state.extraFields.findIndex(x => x.name == "height") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "height");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //   Height ENd
    //**************** */

    //**************** */
    //   djanti_marka
    //**************** */
    if (
      (selName == "Гуми" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "djanti_marka") == -1
    ) {
      let ef = this.state.extraFields;
      let data = this.state.wheelsBrands.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "djanti_marka",
        selected: { id: "", name: "" },
        title: "Гуми Марка:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Джанти" &&
      this.state.extraFields.findIndex(x => x.name == "djanti_marka") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(
        x => x.name == "djanti_marka"
      );
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //   djanti_marka ENd
    //**************** */

    //**************** */
    //   Diameter
    //**************** */
    if (this.state.extraFields.findIndex(x => x.name == "diameter") == -1) {
      let ef = this.state.extraFields;
      let data = this.state.diameter.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "diameter",
        selected: { id: "", name: "" },
        title: "Диаметър:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //   Diameter End
    //**************** */

    //**************** */
    //   Material
    //**************** */
    if (
      (selName == "Джанти" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "material") == -1
    ) {
      let ef = this.state.extraFields;
      let data = this.state.material.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "material",
        selected: { id: "", name: "" },
        title: "Материал:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Гуми" &&
      this.state.extraFields.findIndex(x => x.name == "material") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "material");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //  End Material
    //**************** */
    if (
      (selName == "Джанти" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "markaavto") == -1
    ) {
      let ef = this.state.extraFields;
      let seasons = {
        data: this.state.markaavto,
        isTextField: false,
        multiple: false,
        name: "markaavto",
        link: "get-brand-models",
        linked: "modelavto",
        linkedTitle: "Модел автомобил:",
        selected: { id: "", name: "" },
        title: "Марка автомобил:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Гуми" &&
      this.state.extraFields.findIndex(x => x.name == "markaavto") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "markaavto");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
  }

  //**************** */
  //**************** */
  //* Form Logic  */
  //**************** */
  //**************** */

  printExtraFields() {
    if (this.state.extraFields.length == 0) {
      return "";
    }

    return this.state.extraFields.map((field, i) => {
      if (field.isTextField == true) {
        return (
          <li className="list-group-item" key={i}>
            <Row>
              <Col xs="1">
                <CheckCircle
                  className="check"
                  style={
                    this.state.isFilled.includes(field.name)
                      ? checkIconStyle.hide
                      : null
                  }
                />
              </Col>
              <Col xs="11">{field.title} *</Col>
            </Row>
            <Row>
              <Col xs="1">
                <CheckCircle
                  className="check"
                  style={
                    this.state.isFilled.includes(field.name)
                      ? checkIconStyle.blue
                      : checkIconStyle.hide
                  }
                />
              </Col>
              <Col xs="11">
                <input
                  // value={this.state.value}
                  key="fieldName"
                  onChange={this.handleChange}
                  name={field.name}
                  type="number" //todo: validation
                  pattern="\d*"
                  maxLength={255}
                  className="form-control"
                  required
                />
              </Col>
            </Row>
          </li>
        );
      }

      if (field.multiple == true) {
        return (
          <li
            key={i}
            onClick={() => this.extraFieldsClick.bind(this)(field)}
            className="list-group-item rightArrow"
          >
            <Row>
              <Col xs="1">
                <CheckCircle
                  style={
                    this.state.selectedExtras.length > 0
                      ? checkIconStyle.hide
                      : null
                  }
                  className="check"
                />
              </Col>
              <Col xs="11">{field.title} *</Col>
            </Row>
            <Row>
              <Col xs="1">
                <CheckCircle
                  className="check"
                  style={
                    this.state.selectedExtras.length > 0
                      ? checkIconStyle.blue
                      : checkIconStyle.hide
                  }
                />
              </Col>
              <Col xs="11">
                <input
                  value={this.state.selectedExtras.map(item => item)}
                  name="filter[extras][]"
                  type="text"
                  className="form-control"
                  readOnly
                />
              </Col>
            </Row>
          </li>
        );
      }

      if (
        this.state.selectedCategory === 24 &&
        (field.selected.name == "Гуми" ||
          field.selected.name == "Гуми и джанти" ||
          field.selected.name == "Джанти")
      ) {
        this.setGumiIDjantiExtraFields(field.selected.name);
      }

      //v samoto pole kogato e izbrano imame selected
      return (
        <div>
          <li
            key={i}
            onClick={() => this.extraFieldsClick.bind(this)(field)}
            className="list-group-item rightArrow"
          >
            <Row>
              <Col xs="1">
                <CheckCircle
                  style={
                    field.selected.name.toString().length > 0
                      ? checkIconStyle.hide
                      : null
                  }
                  className="check"
                />
              </Col>
              <Col xs="11">{field.title} *</Col>
            </Row>
            <Row>
              <Col xs="1">
                <CheckCircle
                  className="check"
                  style={
                    field.selected.name.toString().length > 0
                      ? checkIconStyle.blue
                      : checkIconStyle.hide
                  }
                />
              </Col>
              <Col xs="11">
                <input

                  value={field.selected.name}
                  name="title"
                  type="text"
                  className="form-control"
                  readOnly
                />
              </Col>
            </Row>
          </li>
        </div>
      );
    });
  }

  extraFieldsClick(field) {
    if (field.multiple == true && this.state.selectedExtras.length > 0) {
      const { selectedExtras } = this.state;
      this.setState({ selectedExtrasTemp: selectedExtras });
    }

    this.setState({ isCategories: false });
    this.setState({ isOpen: true });
    this.setState({
      drawerData: {
        data: field.data,
        name: field.name,
        multiple: field.multiple,
        selectedField: field
      }
    });
  }

  creatingForm() {
    return (
      <div
        className="card"
        style={{ color: "#bbb", fontSize: "15px", padding: "0 0" }}
      >
        {
          /**************** */
          //**************** */
          //* Images Modal  */
          //**************** */
          //**************** */
        }
        <Row>
          <Col xs="12">
            <Modal
              style={{ marginTop: "60%" }}
              isOpen={this.state.isOpenImageModal}
              toggle={this.toggleModal.bind(this)}
              className={this.props.className}
            >
              <Card className="text-center">
                <ListGroupItem
                  onClick={() => {
                    let imageRotations = this.state.imageRotation;
                    let imagePaths = this.state.imagePaths;
                    let selectedIndex = this.state.selectedImageIndex;
                    imageRotations.splice(selectedIndex, 1);
                    imagePaths.splice(selectedIndex, 1);
                    for (let i = 0; i < imageRotations.length; i++) {
                      this[
                        "Image" + i
                      ].style.transform = `rotate(${imageRotations[i]}deg)`;
                    }

                    this.setState({
                      imageRotation: imageRotations,
                      imagePaths: imagePaths,
                      selectedImageIndex: -1
                    });

                    this.toggleModal();
                  }}
                >
                  <span>Изтрий</span>
                </ListGroupItem>
                <ListGroupItem
                  onClick={() => {
                    let imageIndex = this.state.selectedImageIndex;
                    let rotations = this.state.imageRotation;

                    //let rotation = this.state.imageRotation[imageIndex];
                    if (rotations[imageIndex] == 270) {
                      rotations[imageIndex] = 0;
                    } else {
                      rotations[imageIndex] += 90;
                    }

                    this[
                      "Image" + imageIndex
                    ].style.transform = `rotate(${rotations[imageIndex]}deg)`;
                    this.setState({ imageRotation: rotations });
                  }}
                >
                  <span>Завърти</span>
                </ListGroupItem>
                <ListGroupItem
                  onClick={() => {
                    let imageRotations = this.state.imageRotation;
                    let imagePaths = this.state.imagePaths;
                    let selectedIndex = this.state.selectedImageIndex;
                    //Rotation
                    let tmpRot = imageRotations[selectedIndex];
                    imageRotations[selectedIndex] = imageRotations[0];
                    imageRotations[0] = tmpRot;
                    //Image Paths
                    let tmpPath = imagePaths[selectedIndex];
                    imagePaths[selectedIndex] = imagePaths[0];
                    imagePaths[0] = tmpPath;

                    for (let i = 0; i < imageRotations.length; i++) {
                      this[
                        "Image" + i
                      ].style.transform = `rotate(${imageRotations[i]}deg)`;
                    }

                    this.setState({
                      imageRotation: imageRotations,
                      imagePaths: imagePaths,
                      selectedImageIndex: -1
                    });

                    this.toggleModal();
                  }}
                >
                  <span>Направи основна</span>
                </ListGroupItem>
                <ListGroupItem onClick={this.toggleModal.bind(this)}>
                  <div>
                    <span className="font-weight-bold">Затвори</span>
                  </div>
                </ListGroupItem>
              </Card>
            </Modal>
          </Col>
        </Row>

        {/* Images modal end*/}
        <ul className="list-group list-group-flush">
          <form>
            <li className="list-group-item">
              <Row>
                <Col xs="12">
                  <CheckCircle className="check" />
                  Снимки
                </Col>
              </Row>
              <Row>
              
               
                {this.state.imagePaths.map((img, index) => (
                  <Col
                    key={index}
                    onClick={() => {
                      this.toggleModal();
                      this.handleImageClick(index);
                    }}
                    xs="3"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <div className="addButton" style={Object.assign({ marginLeft: 0 }, this.state.imagePaths.length > 4 && { marginTop: 10 })}>
                      {index === 0 && <div style={{ zIndex: 999, position: 'absolute', bottom: 0, color: 'white', background: 'rgba(0,0,0,0.7)' }}>Основна</div>}
                      <img
                        ref={img => {
                          this["Image" + index] = img;
                        }}
                        src={Constants.BASE_URL_IMAGE_TEMP + img}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </Col>
                ))}
                  {this.state.isLoadingImages && 
                  <Col xs="3" style={{paddingTop: 10, textAlign: 'center'}}>
                    <CircularProgress style={{width: 25, height: 25}} />
                  </Col>
                 }

                <Col xs="3" style={Object.assign({ marginLeft: 0 }, this.state.imagePaths.length > 4 && { marginTop: 10 })}>
                  <div className="addButton ">
                    <IconButton
                      className="addCircle"
                      variant="contained"
                      component="label"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        onChange={this.handleImageUpload.bind(this)}
                        style={{ display: "none" }}
                        multiple={true}
                      />
                      <AddCircleRounded color="disabled" />
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </li>
            <li className="list-group-item">
              <Row>
                <Col xs="1">
                  <CheckCircle
                    className="check"
                    style={
                      this.state.isFilled.includes("title")
                        ? checkIconStyle.hide
                        : null
                    }
                  />
                </Col>
                <Col xs="11">Заглавие *</Col>
              </Row>
              <Row>
                <Col xs="1">
                  <CheckCircle
                    className="check"
                    style={
                      this.state.isFilled.includes("title")
                        ? checkIconStyle.blue
                        : checkIconStyle.hide
                    }
                  />
                </Col>
                <Col xs="11">
                  <input
                    // value={this.state.value}
                    onChange={this.handleChange}
                    maxLength="100"
                    name="title"
                    type="text"
                    className="form-control"
                  />
                </Col>
              </Row>
            </li>
            <li
              className="list-group-item rightArrow"
              onClick={() => {
                this.setState({ isOpen: true });
                this.setState({ isCategories: true });
              }}
            >
              <Row>
                <Col xs="1">
                  {this.state.selectedCategory === -1 && (
                    <CheckCircle className="check" />
                  )}
                </Col>
                <Col xs="11">Рубрика *</Col>
              </Row>
              {this.state.selectedCategory !== -1 && (
                <Row>
                  <Col xs="1">
                    <CheckCircle
                      className="check"
                      style={checkIconStyle.blue}
                    />
                  </Col>
                  <Col xs="11">
                    <input
                      type="text"
                      value={
                        this.state.categories[
                          this.state.categories.findIndex(
                            x => x.id == this.state.selectedCategory
                          )
                        ].title
                      }
                      readOnly={true}
                      className={"form-control"}
                    />
                  </Col>
                </Row>
              )}
            </li>
            {this.printExtraFields.bind(this)()}
            <li
              onClick={() => {
                this.setState({ isOpen: true });
                this.setState({ priceFieldForDrawer: true });
                this.setState({ isCategories: false });
              }}
              className="list-group-item"
            >
              <Row>
                <Col xs="1">
                  <CheckCircle
                    className="check"
                    style={this.state.price ? checkIconStyle.hide : null}
                  />
                </Col>
                <Col xs="11">Цена *</Col>
              </Row>
              <Row>
                <Col xs="1">
                  <CheckCircle
                    className="check"
                    style={
                      this.state.price
                        ? checkIconStyle.blue
                        : checkIconStyle.hide
                    }
                  />
                </Col>
                <Col xs="11">
                  <input
                    name="lastPrice"
                    value={this.state.price}
                    type="text"
                    className="form-control"
                    readOnly
                  />
                </Col>
              </Row>
            </li>

            <li className="list-group-item">
              <Row>
                <Col xs="1">
                  <Col xs="1">
                    <CheckCircle
                      className="check"
                      style={
                        this.state.isFilled.includes("description")
                          ? checkIconStyle.hide
                          : null
                      }
                    />
                  </Col>
                </Col>
                <Col xs="11">Текст *</Col>
              </Row>
              <Row>
                <Col xs="1">
                  <CheckCircle
                    className="check"
                    style={
                      this.state.isFilled.includes("description")
                        ? checkIconStyle.blue
                        : checkIconStyle.hide
                    }
                  />
                </Col>
                <Col xs="11">
                  <textarea
                    name="description"
                    style={{ color: "#3b6fb6", fontWeight: 700 }}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </Col>
              </Row>
            </li>

            <li
              onClick={() => {
                this.setState({ isOpen: true, DrawerCities: true });
              }}
              className="list-group-item rightArrow"
            >
              <Row>
                <Col xs="1">
                  <Col xs="1">
                    <CheckCircle
                      className="check"
                      style={this.state.city ? checkIconStyle.hide : null}
                    />
                  </Col>
                </Col>
                <Col xs="11"> Населено място *</Col>
              </Row>
              <Row>
                <Col xs="1">
                  <CheckCircle
                    className="check"
                    style={
                      this.state.city
                        ? checkIconStyle.blue
                        : checkIconStyle.hide
                    }
                  />
                </Col>
                <Col xs="11">
                  <input
                    name="cities"
                    value={this.state.city}
                    type="text"
                    className="form-control"
                    readOnly
                  />
                </Col>
              </Row>
            </li>

            {this.state.villages.length > 0 && (
              <li
                onClick={() =>
                  this.setState({ isOpen: true, DrawerVillages: true })
                }
                className="list-group-item rightArrow"
              >
                <Row>
                  <Col xs="1">
                    <Col xs="1">
                      <CheckCircle
                        className="check"
                        style={this.state.village ? checkIconStyle.hide : null}
                      />
                    </Col>
                  </Col>
                  <Col xs="11"> Град/Село *</Col>
                </Row>
                <Row>
                  <Col xs="1">
                    <CheckCircle
                      className="check"
                      style={
                        this.state.village
                          ? checkIconStyle.blue
                          : checkIconStyle.hide
                      }
                    />
                  </Col>
                  <Col xs="11">
                    <input
                      name="village"
                      value={this.state.village}
                      type="text"
                      className="form-control"
                      readOnly
                    />
                  </Col>
                </Row>
              </li>
            )}
            <li className="list-group-item rightArrow">
              <Row>
                <Col xs="1" />
                <Col xs="11">Тип подател *</Col>
              </Row>
              <Row>
                <Col xs="1">
                  <CheckCircle className="check" style={checkIconStyle.blue} />
                </Col>
                <Col xs="11" className="pt-2">
                  <label className="privateFaceContainer">
                    Частно лице
                    <input
                      type="checkbox"
                      onChange={this.handlePrivatePerson}
                      checked={this.state.is_private_person}
                    />
                    <span className="checkmark" />
                  </label>
                </Col>
              </Row>
            </li>
            <li className="list-group-item">
              <Row>
                <Col xs="1">
                  <Col xs="1">
                    <CheckCircle
                      className="check"
                      style={
                        this.state.isFilled.includes("phone")
                          ? checkIconStyle.hide
                          : null
                      }
                    />
                  </Col>
                </Col>
                <Col xs="11">Телефон за връзка: *</Col>
              </Row>
              <Row>
                <Col xs="1">
                  <CheckCircle
                    className="check"
                    style={
                      this.state.isFilled.includes("phone")
                        ? checkIconStyle.blue
                        : checkIconStyle.hide
                    }
                  />
                </Col>
                <Col xs="11">
                  <input
                    maxLength="16"
                    type="number"
                    name="phone"
                    value={this.state.phone}
                    pattern="\d*"
                    style={{ color: "#3b6fb6", fontWeight: 700 }}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </Col>
              </Row>
            </li>

            <li className="list-group-item">
              <Row>
                <Col xs="1">
                  <Col xs="1">
                    <CheckCircle
                      className="check"
                      style={
                        this.state.isFilled.includes("isAnon")
                          ? checkIconStyle.hide
                          : null
                      }
                    />
                  </Col>
                </Col>
                <Col xs="11">Скрий името си: </Col>
              </Row>
              <Row>
                <Col xs="1">
                  <CheckCircle
                    className="check"
                    style={
                      this.state.isFilled.includes("isAnon")
                        ? checkIconStyle.blue
                        : checkIconStyle.hide
                    }
                  />
                </Col>
                <Col xs="11">
                  <label className="isAnonContainer">
                    Анонимна обява
                    <input
                      type="checkbox"
                      onChange={this.handleIsAnon}
                      checked={this.state.isAnon}
                    />
                    <span className="checkmark" />
                  </label>



                </Col>
              </Row>
            </li>

          </form>
        </ul>
      </div>
    );
  }

  handleIsAnon = event => {

    this.setState({ isAnon: event.target.checked });
  }

  //**************** */
  //**************** */
  //* Print drawer items logic  */
  //**************** */
  //**************** */

  transferTemporaryExtrasToPermanent = () => {
    if (
      this.state.priceTemp.length > 0 &&
      this.state.isPriceNegotiable === false
    ) {
      this.setState({
        price: this.state.priceTemp + " " + this.state.currancy,
        isOpen: false,
        priceFieldForDrawer: false,
        priceToSend: this.state.priceTemp
      });
    } else if (this.state.isPriceNegotiable) {
      this.setState({ price: "По договаряне", priceFieldForDrawer: false });
    }

    this.setState({
      selectedExtras: this.state.selectedExtrasTemp,
      isOpen: false,
      DrawerCities: false
    });
  };

  setSelectedExtra = field => {
    let arr = this.state.extraFields;
    let index = -1;
    const drawerName = this.state.drawerData.name;
    let drawerIsOpen = false;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name == drawerName) {
        index = i;
        break;
      }
    }

    if (index != -1) {
      arr[index].selected = field;
      if (arr[index].linked && arr[index].link) {
        NetworkClient.post(arr[index].link, { brand_id: field.id }).then(
          data => {
            const linkedName = arr[index].linked;
            let indx = -1;
            for (let j = 0; j < arr.length; j++) {
              if (arr[j].name == linkedName) {
                indx = j;
                break;
              }
            }

            if (indx != -1) {
              arr[indx].data = data;
              arr[indx].selected = { id: "", name: "" };
            }
          }
        );
      }
    }

    this.setState({ extraFields: arr, isOpen: drawerIsOpen });
  };

  setMultipleFilterExtra = field => {
    //remove element from state
    let index = this.state.selectedExtrasTemp.indexOf(field.name);

    if (index > -1) {
      let arr = [...this.state.selectedExtrasTemp];
      arr.splice(index, 1);
      this.setState({ selectedExtrasTemp: arr });
    }
    // add element to state array
    else {
      this.setState({
        selectedExtrasTemp: [...this.state.selectedExtrasTemp, field.name]
      });
    }
  };

  printDrawerData() {
    if (
      this.state.isCategories === false &&
      this.state.drawerData.data.length > 0
    ) {
      return (
        <div className="card my-2">
          <ul className="list-group list-group-flush">
            {this.state.drawerData.data.map((field, i) => (
              <li
                key={i}
                className="list-group-item"
                onClick={() => {
                  if (!this.state.drawerData.multiple) {
                    this.setSelectedExtra(field); //.bind(this)
                  } else {
                    this.setMultipleFilterExtra(field); //.bind(this)
                  }

                  if (
                    this.state.drawerData.selectedField.link != undefined &&
                    this.state.drawerData.selectedField.link.length > 0
                  ) {
                    const title = this.state.drawerData.selectedField.title;
                    const name = this.state.drawerData.selectedField.linked;
                    const url = this.state.drawerData.selectedField.link;
                    const linkedTitle = this.state.drawerData.selectedField
                      .linkedTitle;
                    NetworkClient.post(url, { brand_id: field.id }).then(
                      response => {
                        //title
                        //extraFields
                        let fields = this.state.extraFields;
                        let isExists = fields.findIndex(
                          x => x.title == linkedTitle
                        );
                        let hasItems = response.length != undefined;
                        if (isExists != -1 && !hasItems) {
                          fields.splice(isExists, 1);
                        }

                        let f = {
                          data: response,
                          multiple: false,
                          name: name,
                          selected: { id: "", name: "" },
                          title: linkedTitle
                        };

                        if (isExists == -1 && hasItems) {
                          let index = fields.findIndex(x => x.title == title);
                          fields.splice(index + 1, 0, f);
                        } else if (hasItems && isExists >= 0) {
                          fields[isExists] = f;
                        }

                        this.setState({ extraFields: fields });
                      }
                    );
                  }
                }}
              >
                {this.state.drawerData.selectedField.selected.id.length > 0 &&
                  this.state.drawerData.selectedField.selected.id == field.id}
                {(this.state.selectedExtrasTemp.indexOf(field.name) > -1 ||
                  (this.state.drawerData.selectedField.selected.id != "" &&
                    this.state.drawerData.selectedField.selected.id ==
                    field.id)) && (
                    <Check
                      className="float-right "
                      style={{ color: "rgb(255, 119, 0)" }}
                    />
                  )}
                {field.name}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return "";
  }

  //**************** */
  //**************** */
  //* Send data  */
  //**************** */
  //**************** */
  store() {
    this.setState({ isDisabled: true });
    let dataToSend = {};
    console.log(this.state.priceToSend);
    if (
      this.state.priceToSend === 0 &&
      this.state.isPriceNegotiable === false
    ) {
      this.setState({ isDisabled: false });
      alert("Моля въведете цена на обявата!");
      return;
    }

    const description =
      this.state.selectedMainFields.description !== undefined
        ? this.state.selectedMainFields.description.value
        : false;
    if (description === false) {
      this.setState({ isDisabled: false });
      alert("Моля въведете описание на обявата!");
      return;
    }

    if (this.state.selectedCategory === -1) {
      this.setState({ isDisabled: false });
      alert("Моля изберете категория на обявата!");
      return;
    }

    const title =
      this.state.selectedMainFields.title !== undefined
        ? this.state.selectedMainFields.title.value
        : false;
    if (title === false) {
      this.setState({ isDisabled: false });
      alert("Моля въведете заглавие на обявата!");
      return;
    }

    const phone =
      this.state.selectedMainFields.phone !== undefined
        ? this.state.selectedMainFields.phone.value
        : false;

    if (phone === false) {
      this.setState({ isDisabled: false });
      alert("Моля въведете телефон на обявата!");
      return;
    }

    if (this.state.cityId === -1) {
      this.setState({ isDisabled: false });
      alert("Моля въведете град!");
      return;
    }

    if (this.state.villages.length > 0 && this.state.villageId == null) {
      this.setState({ isDisabled: false });
      alert("Моля въведете град!");
      return;
    }

    dataToSend.city_id = this.state.cityId;
    dataToSend.village_id =
      this.state.villages.length > 0 ? this.state.villageId : null;
    dataToSend.title = title;
    dataToSend.phone = phone;
    dataToSend.category_id = this.state.selectedCategory;
    dataToSend.description = description;
    dataToSend.price = this.state.priceToSend;
    dataToSend.price_negotiable = this.state.isPriceNegotiable;
    dataToSend.isAnon = false;
    dataToSend.price_currency = this.state.currancy == "ЛВ." ? 1 : 2;
    dataToSend.images = this.state.imagePaths;
    dataToSend.rotations = this.state.imageRotation;
    dataToSend.is_private_person =
      this.state.is_private_person === true ? 1 : 0;
    dataToSend.isAnon =
      this.state.isAnon === true ? 1 : 0;


    if (this.state.extraFields.length > 0) {
      //sustoqnieto e otdelno
      const isNew =
        this.state.extraFields.find(x => x.name == "isNew") !== undefined
          ? this.state.extraFields.find(x => x.name == "isNew").selected.id
          : null;
      dataToSend.isNew = isNew;
      let filter = [];
      const fields = this.state.extraFields;
      let check = true;
      for (let i = 0; i < fields.length; i++) {
        let fieldName = fields[i].name;
        let selectedValue = fields[i].selected.id;
        if (
          fieldName != "extras" &&
          fieldName != "mileage" &&
          (selectedValue == undefined || selectedValue == "")
        ) {
          if (fields[i].isTextField == false) {
            check = false;
            break;
          }
          else {
            continue;
          }
        }

        if (fieldName == 'year') {
          let tmp = {};
          tmp[fieldName] = fields[i].selected.name;
          filter.push(tmp);
          continue;
        }

        if (
          fieldName != "isNew" &&
          fieldName != "extras" &&
          selectedValue != undefined
        ) {
          let tmp = {};
          tmp[fieldName] = selectedValue;
          filter.push(tmp);
        } else if (fieldName === "extras") {
          let selectedExtras = this.state.selectedExtras;
          let tmp = {};
          tmp[fieldName] = selectedExtras;
          filter.push(tmp);
        }
      }

      //input fields ot filters extra fields minavat v tova pole, mamata si traka :D
      if (Object.keys(this.state.selectedMainFields).length > 3) {
        let smf = this.state.selectedMainFields;
        let smfKeys = Object.keys(this.state.selectedMainFields);
        for (let i = 0; i < smfKeys.length; i++) {
          if (
            smf[smfKeys[i]].name != "title" &&
            smf[smfKeys[i]].name != "phone" &&
            smf[smfKeys[i]].name != "description"
          ) {
            if (
              smf[smfKeys[i]].value == undefined ||
              smf[smfKeys[i]].value == ""
            ) {
              check = false;
              break;
            }
            let tmp = {};
            tmp[smfKeys[i]] = smf[smfKeys[i]].value;
            filter.push(tmp);
          }
        }
      }

      if (!check) {
        this.setState({ isDisabled: false });
        alert("Моля въведете всички полета!");
        return;
      }

      dataToSend.filter = filter;
    }
    this.setState({ isLoading: true });
    NetworkClient.post("listing", dataToSend).then(response => {
      this.setState({ isDisabled: false, isLoading: false });
      this.props.history.push("/user/promote/listing/" + response.listing.slug);
    });
  }
  //**************** */
  //**************** */
  //* Render method  */
  //**************** */
  //**************** */

  render() {
    return (
      <div className={"create-listing"}>
        {this.state.isLoading == true &&
          <div style={{ background: 'rgba(0,0,0,0.5)', position: 'absolute', height: '10000px', width: '100%', zIndex: 9999, textAlign: 'center', paddingTop: 150 }}>
            <i style={{ color: 'white' }} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </div>
        }
        <Header history={this.props.history} backPage={"/"} />
        <Container>
          <p className="mt-5">
            <b>Публикуване на обява</b>
            <br />
            Маркираните със * полета са задължителни!
          </p>
          {this.creatingForm()}

          {this.state.isOpen && (
            <FooterFixed
              closedrawer={this.toggleDrawer(false)}
              handleClickOkButton={this.transferTemporaryExtrasToPermanent}
            />
          )}
        </Container>
        <footer
          className="container p-2 mt-3"
          style={{ background: "#ececec" }}
        >
          <Row>
            <Col xs="12">
              <Button
                style={{ background: "#3b6fb6", fontWeight: 700 }}
                size="lg"
                onClick={this.store.bind(this)}
                block
                disabled={this.state.isDisabled}
              >
                ПРОДЪЛЖИ
              </Button>
            </Col>
          </Row>
        </footer>
        <Drawer
          classes={{
            // root: "foo__modal",
            paper: "categories-drawer"
          }}
          open={this.state.isOpen}
          onClose={this.toggleDrawer(false)}
        >
          <Container style={{ background: "#e1e1e1", height: "100%" }}>
            {this.state.isCategories === true && (
              <div className="card my-2">
                {this.printBackCategory()}
                {this.printCategories()}
                <ul className="list-group list-group-flush">
                  <li
                    onClick={() => {
                      this.setState({ isOpen: false });
                    }}
                    className="list-group-item"
                  />
                </ul>
              </div>
            )}

            {this.state.priceFieldForDrawer === true && this.printPriceField()}
            {this.state.DrawerCities === true && this.printCities()}
            {this.state.DrawerVillages === true && this.printCities()}

            {!this.state.isCategories &&
              !this.state.priceFieldForDrawer &&
              !this.state.DrawerCities &&
              !this.state.DrawerVillages &&
              this.printDrawerData()}
          </Container>
        </Drawer>
      </div>
    );
  }

  //**************** */
  //**************** */
  //* Cities set and villages  */
  //**************** */
  //**************** */
  setCity = c => event => {
    this.setState({ villages: [] });
    if (c.name.length > 0) {
      NetworkClient.post("getVillages", { cityId: c.id }).then(data => {
        this.setState({ villages: data });
      });
    }

    this.setState({
      city: c.name,
      cityId: c.id,
      isOpen: false,
      DrawerCities: false,
      village: ""
    });
  };

  setVillage(v) {
    this.setState({
      village: v.name,
      villageId: v.id,
      isOpen: false,
      DrawerVillages: false
    });
  }

  //**************** */
  //**************** */
  //* Cities DRAWER  */
  //**************** */
  //**************** */
  printCities() {
    return (
      <div className="card my-2">
        <ul className="list-group list-group-flush">
          {this.state.DrawerCities &&
            this.state.cities.map((c, k) => (
              <li
                key={k}
                name="city"
                onClick={this.setCity.bind(this)(c)}
                // onClick={() => this.setCityVillage(c)}
                className="list-group-item"
                key={c.id}
              >
                {c.name}
                {c.name == this.state.city && (
                  <Check
                    className="float-right "
                    style={{ color: "rgb(255, 119, 0)" }}
                  />
                )}
              </li>
            ))}
          {this.state.DrawerVillages &&
            this.state.villages.map((v, k) => (
              <li
                key={k}
                name="village"
                onClick={() => this.setVillage(v)}
                // onClick={() => this.setCityVillage(c)}
                className="list-group-item"
                key={v.id}
              >
                {v.name}
                {v.name == this.state.village && (
                  <Check
                    className="float-right "
                    style={{ color: "rgb(255, 119, 0)" }}
                  />
                )}
              </li>
            ))}
        </ul>
      </div>
    );
  }

  //**************** */
  //**************** */
  //* PRICE DRAWER  */
  //**************** */
  //**************** */
  printPriceField() {
    return (
      <div className="card my-2">
        <ul className="list-group list-group-flush ">
          <li className="list-group-item" style={{ height: "80vh" }}>
            <FormGroup tag="fieldset">
              <FormGroup>
                <label className="containerRadio">
                  <input
                    maxLength="8"
                    type="radio"
                    name="price"
                    onChange={() => this.setState({ isPriceNegotiable: false })}
                    checked={
                      this.state.isPriceNegotiable === false && "checked"
                    }
                  />
                  <span className="checkmarkRadio" />
                  <input
                    maxLength="8"
                    onFocus={() => this.setState({ isPriceNegotiable: false })}
                    type="number"
                    pattern="\d*"
                    onKeyDown={ (e) =>{
                      let checkIfNum;
                      if (e.key !== undefined) {
                        // Check if it's a "e", ".", "+" or "-"
                        checkIfNum = e.key === "e" || e.key === "+" || e.key === "-" ;
                      }
                      else if (e.keyCode !== undefined) {
                        // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
                        checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
                      }
                      return checkIfNum && e.preventDefault();
                      //evt.key === 'e' && evt.preventDefault()
                    }  }
                    onChange={this.handlePriceChange.bind(this)}
                    value={this.state.priceTemp}
                    name="priceTemp"
                    className="form-control-sm"
                    style={{
                      width: 170,
                      opacity: "unset",
                      positions: "relative"
                    }}
                  />

                  <select
                    name="currancy"
                    onChange={this.handleCurrancyChange}
                    className="form-control-sm ml-2"
                  >
                    <option value="1">ЛВ</option>
                    <option value="2">€</option>
                  </select>
                </label>
              </FormGroup>
              <FormGroup className="mt-3">
                <label className="containerRadio">
                  <input
                    checked={this.state.isPriceNegotiable === true && "checked"}
                    type="radio"
                    name="price"
                    onChange={() => this.setState({ isPriceNegotiable: true })}
                  />
                  По договаряне
                  <span className="checkmarkRadio mr-4" />
                </label>
              </FormGroup>
            </FormGroup>
          </li>
        </ul>
      </div>
    );
  }
}
