import React from "react";
import { Row, Col } from "reactstrap";
import { Create, Send, Phone } from "@material-ui/icons";
import NetworkClient from "../api/NetworkClient";
import Constants from '../constants/Constants';
import TextareaAutosize from 'react-autosize-textarea';

class FooterMessageCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reply: "",
      count: 1,
      textAreaHeight: 16,
      textAreaSumming: 16
    };
  }

  handleChange(e) {
    // console.log(e.target.value);
    this.setState({
      reply: e.target.value
    });
  }

  render() {
    // console.log(this.props.conversationId == undefined && this.props.handleSend2);
    return (
      <div
        style={{
          backgroundColor: "#ececec",
          position: "fixed",
          bottom: 0,
          width: "100%"
        }}
      >

        <Row style={{ padding: "14px" }}>
            <Col xs={3}>
              {(this.props.listing === undefined)
                && <Create style={{ color: "#3b6fb6" }} />}
              {this.props.listing !== undefined
                && this.props.listing.isAnon !== undefined
                && <a href={'tel:' + this.props.listing.phone}
                  onClick={() => {
                    let fd = new FormData();
                    fd.append('_method', 'PUT');

                    fetch(Constants.BASE_DOMAIN_NAME + 'listing-phone-increment/' + this.props.listing.id, {
                      method: 'post',
                      body: fd
                    });
                  }}
                  style={{ background: 'rgb(59, 111, 182)', borderColor: 'rgb(59, 111, 182)' }}
                  className={'btn btn-info btn-block btn-rounded'}>
                  <Phone style={{ color: "white" }} />
                </a>}
            </Col>
            <Col
              xs={7}
              style={{
                background: "#fff",
                borderRadius: "25px",
                padding: "8px 10px",
                border: "1px solid #3b6fb6"
              }}
            >
              <textarea
                value={this.state.reply}
                onChange={this.handleChange.bind(this)}
                id="write"
                name="message"
                className="animated"
                rows="1"
                spellCheck="false"
                placeholder="Напиши съобщение..."
                autoComplete="off"
                style={{
                  outline: "none",
                  width: "100%",
                  resize: "none",
                  height: this.state.textAreaSumming + "px",
                  whiteSpace: "pre-line",
                  maxHeight: "135px",
                  minHeight: "16px",
                  wordWrap: "break-word",
                  margin: "0",
                  padding: "0",
                  border: "none",
                  fontSize: "16px",
                  lineHeight: "17px",
                  color: "#3b6fb6"
                }} />

            </Col>
            <Col xs={2}>
              <Send
                onClick={() => {
                  this.props.handleSend(
                    this.state.reply,
                    this.props.conversationId
                  );
                  this.setState({ reply: "" });
                }}
                style={{ color: "#3b6fb6" }}
              />
            </Col>
          </Row>
      </div>
    );
  }
}

export default FooterMessageCall;
