import React from "react";
import { Col } from "reactstrap";
import Axios from "axios";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import Constants from "../../constants/Constants";

class SingleListing extends React.Component {
  constructor(props) {
    super(props);
  }
  asd() {
    Axios.get("preview")
      .then(response => {
        // console.log(response.data);
      })
      .catch(error => {
        // console.log(error);
      });
  }
  navigate(e) {
    e.preventDefault();
    // this.props.history.push("/url");
    //this.props.ro.push("/preview");
  }

  render() {
    const image = this.props.listing.image
      ? Constants.BASE_URL_IMAGE + this.props.listing.image
      : Constants.BASE_IMAGE_NOT_FOUND;

    return (
      <Col xs={this.props.col} className={"mt-3"}>
        <Link to={"/obqva/" + this.props.listing.slug}>
          <div
            id={this.props.listing.id}
            style={{
              height: "192px",
              backgroundImage: `url('${image}')`,
              backgroundSize: "cover",
              position: "relative",
              backgroundPosition: "center center"
            }}
          >
            {this.props.listing.isTop && (
              <img
                src="https://prodavash.bg/img/vip.png"
                style={{ position: "absolute", top: 0, left: 0 }}
              />
            )}
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                color: "white",

                fontSize: "15 px",
                padding: "3px 7px",
                position: "absolute",
                bottom: 9
              }}
            >
              {this.props.listing.price_show}
              {this.props.listing.price_show ?
                <span>{this.props.listing.price_currency == 1
                  ? " лв."
                  : renderHTML("<span> &euro; </span>")}</span> : 'По договаряне'}

            </div>
          </div>
        </Link>
        <div style={{ backgroundColor: "white", padding: "5px 10px 5px" }}>
          <div
            style={{
              lineHeight: "14px",
              fontSize: "12px"
            }}
          >
            {this.props.listing.title.substr(0, 15)}...
          </div>
          <div
            style={{
              fontSize: "12px",
              lineHeight: "14px",
              color: "#aaa",
              marginTop: "5px",
              marginBottom: "2px"
            }}
          >
            {this.props.listing.city_name}
          </div>
        </div>
      </Col>
    );
  }
}

export default SingleListing;
