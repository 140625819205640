import React from 'react';
import {Container} from 'reactstrap';
import layoutStyles from '../styles/layoutStyles';

class MainPageWrapper extends React.Component {
    constructor(props) {
        super(props);
    }

    render() { 
        return (
            <div style={layoutStyles.whiteBox}>
                {this.props.children}
            </div>
         );
    }
}
 
export default MainPageWrapper;