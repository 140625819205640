import React from 'react'

const styles = {
    listings: {
      marginTop: "10px"
    },
    box: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: "17px",
      padding: 7
    },
    bottomBorder: {
      borderBottom: "1px solid #ddd"
    },
    blue: {
      color: "#3b6fb6"
    },
    orange: {
      color: "#f70"
    }
  };

export default class CategoriesList extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount(){
        const data = this.props.data;
        console.log(this.props.categories);
        this.setState(data);
    }

    changeShownCategories(categoryId, parent) {
        const { backCategories } = this.state;
        backCategories.push(parent);
        this.setState({ backCategories });
        let childCategories = this.state.categories.filter(
          x => x.parent == categoryId
        );
        if (childCategories.length === 0) {
          console.log("change page");
        }
    
        this.setState({ shownCategories: childCategories });
      }
    
      printCategories() {
        return this.state.shownCategories.map((c, i) => (
          <div
            onClick={() => {
              this.changeShownCategories(c.id, c.parent);
            }}
            key={i}
            style={Object.assign({}, styles.box, styles.bottomBorder, styles.blue)}
          >
            {c.icon ? <i style={{ color: c.color }} className={c.icon} /> : ""}{" "}
            {c.title}
          </div>
        ));
      }
    
      printBackCategory() {
        if (this.state.backCategories.length > 0) {
          return (
            <div
              style={Object.assign(
                {},
                styles.box,
                styles.bottomBorder,
                styles.blue
              )}
              onClick={() => {
                let { backCategories } = this.state;
                let parent = backCategories.pop();
                this.setState({ backCategories: backCategories });
                this.setState({
                  shownCategories: this.state.categories.filter(
                    x => x.parent == parent
                  )
                });
              }}
            >
              назад
            </div>
          );
        }
    
        return <div />;
      }
  
    render() {
    return (
      <div>
           <button onClick={()=>{this.setState({categories: []})}}>Test</button>
          <div>{JSON.stringify(this.props.categories)}</div>
          <button onClick={()=>{this.setState({categories: []})}}>Test</button>
        {this.printBackCategory()}
        {this.printCategories()}
      </div>
    )
  }
  
}
