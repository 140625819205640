import React, { Component } from 'react'
import Header from "../../components/ListingCreate/Header";
import { Row, Col } from "reactstrap";
import GoBackArrow from "../../components/GoBackArrow";
import {FavoriteBorder}  from "@material-ui/icons";
import Constants from "../../constants/Constants"
import SingleListingFullWidth from '../../partials/listings/SingleListingFullWidth';
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partials/loader/Loader';
import Pagination from '../../partials/pagination/Pagination';

export default class PublicProfileScreen extends React.Component {
    
   constructor(props)
   {
       super(props);
   } 

  styles = {
    b: {
        color: '#666'
    },
    inputSearch: {
        width: '100%',
        border:'1px solid #3b6fb6',
        height: 36,
        fontSize: 15,
        borderRadius: 5,
        padding:'0 10px'
    },
    checkbox: {
        height: 19,
        width: 19,
        background: '#fff',
        border: '1px solid #c5d1dd',
        borderRadius: 2,
    },
    coverStyles: {

    }
  }
  
  state = {
      user: {},
      filters: -1,
      topCategories: [],
      listings: [],
      keywords:'',
      title: 0,
      page: 1,
      lastPage: -1,
      isLoading: true,
      backPage: ''
  }

  componentDidMount() {
    this.loadListings();
    let backPage = this.backPage();
    this.setState({backPage, backPage});
  }

  generateUrl() {
    let userId = this.props.match.params.id;
    let url = `/user/profile/${userId}?page=${this.state.page}`;

    if(this.state.keywords)
    {
        url += `&word=${this.state.keywords}`;
    }
        
    if(this.state.filters > -1)
    {
        url += `&cat=${this.state.filters}`;
    }
  
    if(this.state.title == 1)
    {
        url += `&title=1`;
    }
    
    return url;
  }

  loadListings() {
        window.scrollTo(0,0);
        this.setState({isLoading: true}, ()=>{
        let url = this.generateUrl();
        NetworkClient.get(url)
        .then(response => {
            let {user} = response;
            if(user.mainPhoto === null)
            {
                user.mainPhoto = 'img/icons/usr-nophoto.svg'
            }  
            else
            {
                user.mainPhoto = 'storage/images/' + user.mainPhoto;
            }
    
            user.hasCover = false;
            this.styles.coverStyles = {backgroundColor: '#E3EBF0', height: 120};
            if(user.coverPhoto !== null)
            {
                //Constants.BASE_DOMAIN_NAME + this.state.user.mainPhoto
                user.hasCover = true;
                let bgUrl = `url(${Constants.BASE_DOMAIN_NAME + 'storage/images' + user.coverPhoto})`;
                this.styles.coverStyles = {background: bgUrl, height: 120, backgroundPosition: 'center center', backgroundSize: 'cover'};
            }
    
            this.setState({
                user: response.user, 
                topCategories: response.orderedCategories, 
                listings: response.listings.data,
                page: response.listings.current_page,
                lastPage: response.listings.last_page,
                isLoading: false})
        });
        });
      
  }

  backPage(){
      //let history = this.props.location.state.history;
      //console.log(this.props.history.goBack());
      let url = document.referrer;
      console.log(url);
      let splitted = url.split('/');
      splitted = splitted.slice(3);
      let newUrl ='/' + splitted.join('/');
      console.log(newUrl);
      return newUrl;
  }

  render() {
    return (
        <div>
            <Loader isLoading={this.state.isLoading}/>
            <div>
             <Row style={this.styles.coverStyles}>
                <Col xs={2}>
                    <GoBackArrow history={this.props.history} backPage={this.state.backPage}/>
                </Col>
                <Col xs={8} className={"text-center pt-1"}>
                </Col>
                <Col xs={2}>
                    <FavoriteBorder />
                </Col>
             </Row>
             <Row style={{padding: 10,background:'#fff',position: 'relative',minHeight: 150}}>
                <Col xs={12}>
                    <div>
                        <img
                            className="img-fluid"
                            style={{margin: '0 auto', width: 60,height:60,display:'block',position:'absolute',top:-40,left:0,right:0, backgroundColor:'white',padding:5}}
                            src={Constants.BASE_DOMAIN_NAME + this.state.user.mainPhoto}
                        />
                        <br/>
                        <div style={{textAlign:'center'}}>
                            <h1 style={{fontSize: 20}}>{this.state.user.name}</h1>
                            {/*--<div>Частно лице</div>*/}
                            <div>Последна активност: <b style={this.styles.b}>{this.state.user.last_activity}</b></div>
                            <div>В prodavash.bg от: <b style={this.styles.b}>{this.state.user.created_at}</b></div>
                        </div>
                    </div>
                </Col>
             </Row>
             <Row style={{background: 'white',marginTop: 10, padding: 9}}>
                 <Col xs={12}>
                    <div>
                        <input 
                            type="text" 
                            placeholder={"Търсене по ключови думи"} 
                            style={this.styles.inputSearch}
                            value={this.state.keywords}
                            onChange={(e)=>{this.setState({keywords: e.target.value})}}
                        />
                    </div>
                    <div className={'mt-1'}>
                        <input style={this.styles.checkbox} type="checkbox" value={this.state.title}  />
                        <span style={{fontSize: 21}}>&nbsp;&nbsp;Търси само в заглавията</span>
                    </div>
                 </Col>
             </Row>
             <Row style={{background: 'white',marginTop: 10, padding: 9}}>
                <Col xs={12}>
                    <select onChange={(txt)=>{ 
                        this.setState({filters:txt.target.value},()=>{
                            this.loadListings();
                        })
                        
                        }} value={this.state.filters} style={{border: 'none', width: '100%'}}>
                        <option>--- Всички категории ---</option>
                        {this.state.topCategories.map((c,key) => <option key={key} value={c.id}>{c.name}</option>)}
                    </select>
                </Col>
             </Row>
             {this.state.listings.map((l, key) => <SingleListingFullWidth key={key} listing={l} />)}
             <Pagination 
                currentPage={this.state.page} 
                totalPages={this.state.lastPage}
                nextButtonHandle={()=>{
                    this.setState({page: this.state.page+1},()=>{
                        this.loadListings();
                    })
                }}
                backButtonHandle={()=>{
                    this.setState({page: this.state.page-1},()=>{
                        this.loadListings();
                    })
                }}
                selectHandle={(p)=>{
                    this.setState({page: p},()=>{
                        this.loadListings();
                    })
                }}
                />
            </div>
        </div>
    )
  }
}
