import React from "react";
import MainPageWrapper from "../../layout/MainPageWrapper";
import WhiteBoxWrapper from "../../layout/WhiteBoxWrapper";
import Header from "../../components/ListingCreate/Header";
import NetworkClient from "../../api/NetworkClient";

class PasswordResetScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: [],
      msg: "",
      isLoading: false,
    };
  }

  passwordRecovery() {
    this.setState({ isLoading: true });
    NetworkClient.post("password/reset", { email: this.state.email }).then(
      response => {
        this.setState({ errors: response, msg: response.msg, isLoading: false });
      }
    );
  }

  handleEmailChange(event) {
    // console.log(event.target.value);
    this.setState({ email: event.target.value });
  }

  render() {
    return (
      <div>
        <Header history={this.props.history} backPage={"/auth"} />
        <MainPageWrapper>
          <WhiteBoxWrapper>
            <h1
              style={{
                marginTop: 57,
                fontSize: 17,
                color: "rgb(80, 92, 226)"
              }}
            >
              Забравена парола
            </h1>
            <p>
              За да си смените паролата си, попълнете по-долу E-mail-а, посочен
              при публикуване на обявите или регистрация.
            </p>
            <div className="row">
              <div className="col">
                {this.state.msg && (
                  <div className="alert alert-success" role="alert">
                    {this.state.msg}
                  </div>
                )}
              </div>
            </div>
            {!this.state.msg && (
              <div className="row p-2">
                <div className="col-xs-5">
                  <input
                    type="email"
                    onChange={this.handleEmailChange.bind(this)}
                    value={this.state.email}
                    className="form-control"
                  />
                </div>
                <div className="col-xs-3">
                  <button
                    onClick={() => this.passwordRecovery()}
                    className="btn text-white "
                    style={{ background: "#3b6fb6" }}
                    disabled={this.state.isLoading}
                  >
                    ИЗПРАТИ
                  </button>
                </div>

                <div className="row">
                  <div className="col">
                    <span className="text-danger">
                      {this.state.errors && this.state.errors.email}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </WhiteBoxWrapper>
        </MainPageWrapper>
      </div>
    );
  }
}

export default PasswordResetScreen;
