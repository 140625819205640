import React from "react";
import { KeyboardArrowLeft } from "@material-ui/icons";
import Constants from '../constants/Constants';
import { createBrowserHistory } from 'history';

class GoBackArrow extends React.Component {
  constructor(props) {
    super(props);
  }
  goBackArrow() {
    // console.log(document.URL);
    //alert(this.props.backPage);
    //this.props.history.push(this.props.backPage);
    this.props.history.goBack();
    /*
    if (document.URL === document.referrer) {
      this.props.history.push(this.props.backPage);
      return;
    }

    if (!document.referrer.includes("localhost")) {
      this.props.history.push(this.props.backPage);
      return;
    }

    this.props.history.push(this.props.backPage);*/
    //createBrowserHistory().goBack();
  }
  render() {
    return <KeyboardArrowLeft onClick={this.goBackArrow.bind(this)} fontSize="large" />;
  }
}

export default GoBackArrow;
