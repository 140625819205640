/*const constants = {
  BASE_URL_API: "http://localhost:8000/api/", //"http://prodavash.technocenter.eu/api/", //TODO:
  BASE_URL: "http://localhost:8000/",//"http://prodavash.technocenter.eu/", //TODO:
  BASE_URL_IMAGE: "http://localhost:8000/storage/images/", //"http://prodavash.technocenter.eu/storage/images/",
  BASE_URL_IMAGE_TEMP: "http://localhost:8000/storage/temporary/", //"http://prodavash.technocenter.eu/storage/temporary/",
  BASE_IMAGE_NOT_FOUND: "http://localhost:8000/images/no-images.jpg",
  BASE_DOMAIN_NAME: "http://localhost:8000/",//"http://prodavash.technocenter.eu/"
};*/


const constants = {
  BASE_URL_API: "https://prodavash.bg/api/", //TODO:
  BASE_URL: "https://prodavash.bg/", //TODO:
  BASE_URL_IMAGE: "https://prodavash.bg/storage/images/",
  BASE_URL_IMAGE_TEMP: "https://prodavash.bg/storage/temporary/",
  BASE_IMAGE_NOT_FOUND: "https://prodavash.bg/images/no-images.jpg",
  BASE_DOMAIN_NAME: "https://prodavash.bg/"
};


export default constants;
