import React from "react";
import MainPageWrapper from "../../layout/MainPageWrapper";
import WhiteBoxWrapper from "../../layout/WhiteBoxWrapper";
import TopLayout from "../../layout/TopLayout";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button, Row, Col, Input } from "reactstrap";
import NetworkClient from "../../api/NetworkClient";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import constants from "../../constants/Constants";

class RegisterScreen extends React.Component {
  componentDidMount() {
    const isLogged = Cookies.get("api_token") ? true : false;
    if (isLogged) {
      this.props.history.push("/");
    }
  }

  register() {
    const { email, password, name, confirmPassword } = this.state;

    if (
      email.length == 0 ||
      password.length == 0 ||
      name.length == 0 ||
      confirmPassword.length == 0
    ) {
      alert("Всички полета са задължителни за да се регистрирате!");
      return;
    }

    if (password != confirmPassword) {
      alert("Паролата и повторете паролата не съвпада!");
      return;
    }

    NetworkClient.post("register", {
      email,
      password,
      name,
      password_confirmation: password
    }).then(
      response => {
        //Cookies.set("api_token", response.api_token);
        //localStorage.setItem("name", response.name);
        // localStorage.setItem("email", email);
        //this.props.history.push("/");
        //window.location.reload(true);
        this.setState({ success: true, error: false });
      },
      error => {
        this.setState({ error: true });
      }
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      error: false,
      name: "",
      success: false
    };
  }

  render() {
    return (
      <div>
        <TopLayout history={this.props.history} />
        <MainPageWrapper>
          <WhiteBoxWrapper>
            <div style={{ marginTop: 1 }}>&nbsp;</div>
            <div
              style={{
                width: "100%",
                border: "1px solid #3b6fb6",
                display: "flex",
                textAlign: "center"
              }}
            >
              <Link to={"/auth"} style={{ width: "49%" }}>
                Вход
              </Link>
              <Link
                to={"/register"}
                style={{ width: "51%", color: "white", background: "#3b6fb6" }}
              >
                Регистрация
              </Link>
            </div>
            <div style={{ fontSize: 14, marginTop: 5, marginBottom: 15 }}>
              За да управлявате лесно и бързо обявите си, моля създайте си
              акаунт.
            </div>
            <Row>
              <Col xs={12}>
                <a
                  style={{ background: "#365494", borderColor: "#365494" }}
                  className={"btn btn-info btn-block"}
                  href={
                    constants.BASE_DOMAIN_NAME +
                    "login/facebook?is_api=1"
                  }
                >
                  Регистрация с Facebook
                </a>
              </Col>
            </Row>
            <Row className={"mt-2"}>
              <Col xs={5}>
                <hr />
              </Col>
              <Col xs={2} className={"text-center"}>
                ИЛИ
              </Col>
              <Col xs={5}>
                <hr />
              </Col>
            </Row>
            <Row>
              {this.state.success == true && (
                <Col xs={12} className={"mt-1 mb-1"}>
                  <div className={"alert alert-success"}>
                    Моля потвърдете профила в имейла си!
                  </div>
                </Col>
              )}
              {this.state.error == true && (
                <Col xs={12} className={"mt-1 mb-1"}>
                  <div style={{ color: "red" }}>Съществуващ имейл!</div>
                </Col>
              )}
              <Col xs={12} className={"mt-3"}>
                <Input
                  type={"text"}
                  placeholder={"Име"}
                  value={this.state.name}
                  onChange={event => {
                    this.setState({ name: event.target.value });
                  }}
                />
              </Col>
              <Col xs={12} className={"mt-3"}>
                <Input
                  type={"text"}
                  placeholder={"E-mail"}
                  value={this.state.email}
                  onChange={event => {
                    this.setState({ email: event.target.value });
                  }}
                />
              </Col>
              <Col xs={12} className={"mt-3"}>
                <Input
                  type={"password"}
                  placeholder={"Парола"}
                  value={this.state.password}
                  onChange={event => {
                    this.setState({ password: event.target.value });
                  }}
                />
              </Col>
              <Col xs={12} className={"mt-3"}>
                <Input
                  type={"password"}
                  placeholder={"Потвърдете паролата"}
                  value={this.state.confirmPassword}
                  onChange={event => {
                    this.setState({ confirmPassword: event.target.value });
                  }}
                />
              </Col>
              <Col xs={12} className={"mt-3"}>
                <Button
                  onClick={this.register.bind(this)}
                  style={{ background: "#3b6fb6", borderColor: "#3b6fb6" }}
                  className={"btn btn-info btn-block"}
                >
                  Регистрация
                </Button>
              </Col>
              <Col xs={12} className={"mt-3 mb-3"}>
                Регистрирайки се приемам общите условия на сайта.
              </Col>
            </Row>
          </WhiteBoxWrapper>
        </MainPageWrapper>
      </div>
    );
  }
}

export default RegisterScreen;
