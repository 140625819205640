import React from 'react';
import Header from '../../components/ListingCreate/Header';
import { Link } from "react-router-dom";
export default class TermsScreen extends React.Component {
    render() {
        return (
            <div>
                <Header history={this.props.history} backPage={"/my-profile"} />
                <div className="container mt-5" style={{ backgroundColor: 'white' }}>
                    <h1> Декларация за поверителност на Prodavash.BG </h1>

                    <p style={{ fontWeight: 500 }}>Настоящите общи условия уреждат отношенията между "ДАИЛ" ЕООД, ЕИК: 120541683, гр. Смолян, ул. "Васил Райдовски 3", Телефон: +359 88 733 2297, и лицата- ползватели на услугите на сайта www.prodavash.bg.<br />
                        Prodavash.bg е сайт за безплатни обяви. Prodavash.bg е собственост на "ДАИЛ" ЕООД.</p>

                    <p> Тази страница се използва за информиране на посетителите на уебсайта относно нашите правила за събиране, използване и разкриване на лична информация, ако някой реши да използва нашата услуга, уебсайтът www.prodavash.bg. </p>

                    <p> Ако решите да използвате нашата услуга, тогава приемате събирането и използването на информация във връзка с тази политика. Личната информация, която събираме, се използва за предоставяне и подобряване на услугата. Няма да използваме или споделяме вашата информация с никого, освен както е описано в тази Декларация за поверителност. </p>

                    <p> Термините, използвани в тази Декларация за поверителност, имат същите значения, както в нашите Общи условия, достъпни на адрес www.prodavash.bg, освен ако не е посочено друго в тази Декларация за поверителност. </p>

                    <h2> Събиране и използване на информация </h2>

                    <p> За по-добро преживяване по време на използването на нашата услуга може да изискаме да ни предоставите определена лична информация, включително, но не само, вашето име, телефонен номер и пощенски адрес. Информацията, която събираме, ще бъде използвана за връзка или идентифициране на вас. </p>

                    <h2> Регистрационни данни </h2>

                    <p> Искаме да Ви информираме, че всеки път, когато посещавате нашата услуга, ние събираме информация, която вашият браузър ни изпраща, който се нарича Log Data. Тези данни от дневника могат да включват информация като адрес на интернет протокола на компютъра ("IP"), версия на браузъра, страници на нашата услуга, които посещавате, час и дата на вашето посещение, времето, прекарано на тези страници, и друга статистика. </p>

                    <h2> Бисквитки </h2>

                    <p> Бисквитките са файлове с малко количество данни, които обикновено се използват като анонимен уникален идентификатор. Те се изпращат до браузъра ви от уебсайта, който посещавате, и се съхраняват на твърдия диск на компютъра ви. </p>

                    <p> Нашият уебсайт използва тези „бисквитки“ за събиране на информация и за подобряване на нашата услуга. Имате възможност да приемете или да откажете тези „бисквитки“ и да знаете кога се изпраща „бисквитка“ към компютъра ви. Ако решите да откажете нашите „бисквитки“, може да не успеете да използвате някои части от нашата услуга. </p>

                    <p> Можете да научите как да управлявате „бисквитките“ в уеб браузъра си, като следвате <a href="https://privacypolicies.com/blog/browser-cookies-guide/"> Ръководството за бисквитките на браузърите </a>.</p>

                    <h2> Доставчици на услуги </h2>

                    <p> Възможно е да наемаме фирми и физически лица от трети страни поради следните причини: </p>

                    <ul>
                        <li> За улесняване на нашата услуга; </li>
                        <li> За да предоставите услугата от наше име; </li>
                        <li> Извършване на услуги, свързани с услугите; или </li>
                        <li> За да ни помогнете при анализирането на използването на нашата услуга. </li>
                    </ul>

                    <p> Искаме да информираме нашите потребители, че тези трети страни имат достъп до вашата лична информация. Причината е да изпълняваме възложените им задачи от наше име. Те обаче са задължени да не разкриват или използват информацията за други цели. </p>

                    <h2> Сигурност </h2>

                    <p> Ние ценим Вашето доверие при предоставянето на Вашата лична информация, като по този начин се стремим да използваме търговски приемливи средства за неговата защита. Но не забравяйте, че нито един метод на предаване по интернет, или методът на електронно съхранение не е 100% сигурен и надежден, и не можем да гарантираме абсолютната сигурност. </p>

                    <h2> Връзки към други сайтове </h2>

                    <p> Услугата ни може да съдържа връзки към други сайтове. Ако кликнете върху връзка от трета страна, ще бъдете насочени към този сайт. Имайте предвид, че тези външни сайтове не се управляват от нас. Затова ви съветваме да прегледате Декларацията за поверителност на тези уебсайтове. Нямаме контрол и не поемаме отговорност за съдържанието, правилата за поверителност или практиките на сайтове или услуги на трети страни. </p>

                    <h2> Поверителност на децата </h2>

                    <p> Нашите услуги не се отнасят за лица под 18-годишна възраст. Лица ненавършили 18 години, следва да използват сайта под надзора на родител или попечител/настойник.</p>

                    <h2> Промени в тази Декларация за поверителност </h2>

                    <p> Понякога може да актуализираме нашата политика за поверителност. Затова ви съветваме периодично да преглеждате тази страница за промени. Ще ви уведомим за всички промени, като публикуваме новата Декларация за поверителност на тази страница. Тези промени влизат в сила веднага след публикуването им на тази страница.</p>
                    <h2> Плащане</h2>
                    <p>Стоките през сайта не могат да се заплащат.</p>
                    <p>
                        Плащанията за услуги (промо-опции за обяви), предлагани чрез сайта prodavash.bg се извършват в брой /EasyPay/, Visa, MasterCard, Visa Electron, С банков превод, ePay.bg. Цените на услугите се обявяват от сайта. <br />
                        Връщането на суми на Потребителя се извършва от Администратора в 14-дневен срок от датата на получаване на подписания формуляр.
                        Подлежащите на връщане суми се превеждат по банковата сметка, посочена от Потребителя във формуляра за възстановяване на суми.
<br />
                        Потребителят не може да се откаже от платена услуга и да иска възстановяване на сумата, предплатена за ползването на съответната услуга, ако услугата вече е изпълнена или е започнало нейното изпълнение от Администратора,
                        с изключение на случаите, описани по-долу.
<br />
                        Потребителят не може да претендира възстановяване на предплатени суми за активирани промо-опции за обяви, във всички случаи, когато профилът му
                        е блокиран и обявите му са свалени от Администратора заради действия на потребителя при използване на платформата Prodavash.BG,
                        които са в нарушение на ОУ и приложенията към него.
</p>

                    <h2> Плащане с SMS (промотиране на обяви)</h2>
                    <div className="row">
                        <div className="col-md-12">
                            <p>За да използвате SMS услугата изпратете SMS с текст silver на номер 1094 (4.80лв.)<br />
                                Изпратете SMS на номер 1096 със съдържание
 gold (6.00лв.)<br />
                                Изпратете 2 SMS-а на номер 1096 със съдържание
 platinum (12.00лв.)<br /></p>
                            <p>"ДАИЛ" ЕООД не е мобилен оператор или оператор на плащания чрез технологията SMS (SMS-плащания) и не носи отговорност за недоставени или неправилно доставени SMS- съобщения.</p>
                        </div>
                    </div>
                    <h2>Политика за защита на личните данни</h2>
                    <p>
                        Име: "ДАИЛ" ЕООД <br />ЕИК: 120541683 <br /> Адрес:  гр. Смолян, ул. "Васил Райдовски 3"
</p>
                    <p>
                        Целта на събирането и обработването на данни е предлагането на услуги и инструменти, където потребителите могат да участват в дейности, включително но не само да се регистрират и да създадат профил,
                        да публикуват или да разглеждат списъци с онлайн обяви, да комуникират с останалите потребители, да си разменят съобщения и да изпълняват множество свързани функции на сайта Prodavash.BG.
                    Личните данни са информация, която служи за Вашата идентификация, т.е. име, електронна поща. „ДАИЛ” ЕООД, под никаква форма няма да събира Ваши лични данни, освен необходимите за извършване на действия по уеб сайта, Prodavash.BG.
</p>
                    <p>
                        Задължителната информация, за да се регистрирате е eлектронната ви поща.
                    Предоставянето на личните ви данни в останалата функционална част на сайта е напълно доброволно по ваше желание. Физическото лице търсещо или предлагащо чрез онлайн обяви в Prodavash.BG самостоятелно преценява дали и какви данни за себе си да предостави.
</p>
                    <p>
                        Prodavash.BG съхранява Вашите лични данни за срок не по-дълъг от съществуването на профила ви в уебсайта. След изтичането на този срок, Prodavash.BG полага необходимите грижи да изтрие и унищожи всички Ваши данни, без ненужно забавяне (до 1 месец).
</p>
                    <h2> Свържете се с нас </h2>

                    <p> Ако имате въпроси или предложения за нашата Политика за поверителност, не се колебайте да се свържете с нас <Link to="/contacts">тук</Link>. </p>
                </div></div>)
    }
}