import React from "react";
import SingleListing from '../../partials/listings/SingleListing';


export default class ShowListings extends React.Component {
    constructor(props) {
        super(props);
    }

    printListings() {
        return this.props.listings.map((listing, i) => (
            <SingleListing listing={listing} key={i} col={6} />
        ));
    }

    render() {
        return <div className={'row'} >
            {this.printListings()}
        </div>
    }
}