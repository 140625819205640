import React from "react";
import Header from "../../components/ListingCreate/Header";
import {
  Modal,
  Container,
  Card,
  Row,
  Col,
  Label,
  Button,
  FormGroup,
  ListGroupItem
} from "reactstrap";
import NetworkClient from "../../api/NetworkClient";

const style = {
  leftBtn: {
    borderRadius: "5px 0 0 5px",
    border: "1px solid #3b6fb6"
  },
  rightBtn: {
    borderRadius: "0 5px 5px 0",
    border: "1px solid #3b6fb6"
  },
  defaultBtn: {
    color: "#3b6fb6"
  },
  activeBtn: {
    background: "#3b6fb6",
    color: "white"
  }
};
class ContactsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactForm: true,
      contactInfo: false,

      name: "",
      email: "",
      phone: "",
      message: "",

      response: "",
      errors: []
    };
  }
  reset() {
    this.setState({
      name: "",
      email: "",
      phone: "",
      message: ""
    });
  }

  submitInputDate() {
    let name = this.state.name;
    let email = this.state.email;
    let phone = this.state.phone;
    let message = this.state.message;

    NetworkClient.post("contacts", {
      name,
      email,
      phone,
      message
    }).then(response => {
      // console.log(response);

      this.setState({ response: response.msg, errors: response });
      if (response.msg) {
        this.reset();
      }
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getContactForm() {
    return (
      <div className="mt-4">
        {this.state.response && (
          <div className="mb-5 text-center">
            <span className="p-3 mb-2 bg-success text-white ">
              {this.state.response && this.state.response}
            </span>
          </div>
        )}
        <FormGroup>
          <Label>* Вашето име:</Label>
          <input
            name="name"
            value={this.state.name}
            onChange={e => this.handleChange(e)}
            className="form-control"
            required
          ></input>
          <span className="text-danger">
            {this.state.errors.name && this.state.errors.name}
          </span>
        </FormGroup>
        <FormGroup>
          <Label>* Вашият e-mail:</Label>
          <input
            name="email"
            type="email"
            value={this.state.email}
            onChange={e => this.handleChange(e)}
            className="form-control"
            required
          ></input>
          <span className="text-danger">
            {this.state.errors.email && this.state.errors.email}
          </span>
        </FormGroup>
        <FormGroup>
          <Label>Вашият телефон:</Label>
          <input
            name="phone"
            value={this.state.phone}
            onChange={e => this.handleChange(e)}
            className="form-control"
          ></input>
        </FormGroup>
        <FormGroup>
          <Label>* Съобщение:</Label>
          <textarea
            name="message"
            value={this.state.message}
            onChange={e => this.handleChange(e)}
            className="form-control"
            rows="5"
            required
          ></textarea>
          <span className="text-danger">
            {this.state.errors.message && this.state.errors.message}
          </span>
        </FormGroup>
        <button
          onClick={() => this.submitInputDate()}
          className="btn text-white btn-block"
          style={{ background: "#3b6fb6" }}
        >
          ИЗПРАТИ
        </button>
      </div>
    );
  }

  getContactInfo() {
    return (
      <div className="mt-4">
        <h3>КОНТАКТИ</h3>
        <ul className=" list-unstyled">
          <li>
            <b>Prodavash.bg</b>
          </li>
          <li>“ДАИЛ“ ЕООД</li>
          <li>ЕИК: 120541683</li>
          <li>гр. Смолян</li>
          <li>ул. Васил Райдовски 3</li>
          <li>Телефон: +359 88 733 2297</li>
          <li>Email: prodavash.bg@abv.bg</li>
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Header history={this.props.history} backPage={"/my-profile"}></Header>
        <Container
          style={{
            background: "#e1e1e1",
            height: "100vh",
            paddingBottom: 20,
            paddingTop: 55
          }}
        >
          <Card className="p-2">
            <Row>
              <Col xs={6} className="pr-0 pull-right">
                <button
                  onClick={() =>
                    this.setState({ contactForm: true, contactInfo: false })
                  }
                  className="btn btn-block"
                  style={Object.assign(
                    {},
                    this.state.contactForm && style.activeBtn,
                    style.leftBtn
                  )}
                >
                  Пишете ни
                </button>
              </Col>
              <Col xs={6} className="pl-0 ">
                <button
                  onClick={() =>
                    this.setState({ contactForm: false, contactInfo: true })
                  }
                  className="btn btn-block"
                  style={Object.assign(
                    {},
                    this.state.contactInfo && style.activeBtn,
                    style.rightBtn
                  )}
                >
                  Контакти
                </button>
              </Col>
            </Row>
            {this.state.contactForm
              ? this.getContactForm()
              : this.getContactInfo()}
          </Card>
        </Container>
      </div>
    );
  }
}

export default ContactsScreen;
