import React from "react";
import { Row, Col, Container, ListGroup, ListGroupItem } from "reactstrap";
import Svg from "../../userWallpaper.svg";
import GoBackArrow from "./GoBackArrow";
import { EditOutlined, Add } from "@material-ui/icons";
import constants from "../constants/Constants";

class UserHeader extends React.Component {
  state = {
    name: "",
    mainPhoto: ""
  };
  componentDidMount() {
    const name = localStorage.getItem("name");
    let mainPhoto = localStorage.getItem("mainPhoto");
    if(mainPhoto == null)
    {
      mainPhoto = 'img/icons/usr-nophoto.svg';
      localStorage.setItem('mainPhoto', mainPhoto);
    }

    this.setState({ name: name, mainPhoto: mainPhoto });
  }
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          background: "url(" + Svg + ")",
          // height: "147px",
          color: "white"
        }}
      >
        <Row className={"text-center " + (this.props.showArrow ? "" : " pr-3")}>
          {this.props.showArrow ? (
            <Col xs={2} className="mt-2">
              <GoBackArrow history={this.props.history} backPage={"/"} />
            </Col>
          ) : (
              <Col xs={2}></Col>
            )}

          <Col xs={8}>
            <div className="mt-3">
              <img
                style={{
                  border: "2px solid",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  color: "white"
                }}
                width={80}
                height={80}
                src={constants.BASE_DOMAIN_NAME + this.state.mainPhoto}
              />
              <p style={{ fontSize: 22, wordBreak: 'break-all' }}>{this.state.name}</p>
            </div>
          </Col>
          <Col xs={2} className={"mt-2 "}>
            <EditOutlined
              onClick={() => {
                this.props.history.push("/profile-info");
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default UserHeader;
