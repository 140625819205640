import React from 'react'
import NetworkClient from '../../api/NetworkClient';
import WhiteBoxWrapper from '../../layout/WhiteBoxWrapper'
import {Container, Row, Col} from 'reactstrap';
import SingleListing from '../../partials/listings/SingleListing';
import MainPageWrapper from '../../layout/MainPageWrapper';

export default class DeleteScreen extends React.Component {
  state = {
    posts: [],
    name: ''
  }

  componentDidMount() {
      NetworkClient.get('posts').then(data => {
          let d = data.splice(0,3);
          this.setState({name: 'Gosho'})
          this.setState({posts: d})
      })
  }  

  sendPostRequest() {
      //input name="age"
      NetworkClient.post('posts', {age: 21, name: this.state.name})
      .then(r => {
          alert(r.name);
      })
  }
     
  render() {
    return (
      <MainPageWrapper>
        {this.state.posts.map((r, i)=><div key={i}> {r.title} </div>)}
        <div>
            <input 
                    type="text" 
                    value={this.state.name} 
                    onChange={(text) => {this.setState({name: text.target.value})}} 
            />

            <button 
                onClick={this.sendPostRequest.bind(this)} 
                className={'btn btn-info'}>
                Test
            </button>    
        </div>
        <WhiteBoxWrapper>
            <Row className={'mb-5'}>
                <Col>Hello</Col>
            </Row>
        </WhiteBoxWrapper>
            <Row>
                <SingleListing col={6}></SingleListing>
            </Row>
      </MainPageWrapper>
    )
  }
}
