import React from "react";
import TopLayout from "./../../layout/TopLayout";
import { Container, Row, Col, Card, Button } from "reactstrap";
import WhiteBoxWrapper from "../../layout/WhiteBoxWrapper";
import MainPageWrapper from "../../layout/MainPageWrapper";
import SingleListing from "../../partials/listings/SingleListing";
import NetworkClient from "../../api/NetworkClient";
import CategoriesList from "../../components/Categories/CategoriesList";
import { Link } from "react-router-dom";
import { Add, KeyboardArrowLeft } from "@material-ui/icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from "js-cookie";
import AdSense from 'react-adsense';

//<div class="container"></div>
//<div class="row"></div>
//<div class="col-sm-12">
const styles = {
  listings: {
    marginTop: "10px"
  },
  box: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "17px",
    padding: 7
  },
  bottomBorder: {
    borderBottom: "1px solid #ddd"
  },
  blue: {
    color: "#3b6fb6"
  },
  orange: {
    color: "#f70"
  }
};

class HomeScreen extends React.Component {
  state = {
    categories: [],
    shownCategories: [],
    backCategories: [],
    listings: [],
    listingsCount: 0,
    tranSitionButton: "",
    isLoading: true,
    isTriggeredCategorySelect: false,
    selectedCategory: -1
  };

  componentDidMount() {
    NetworkClient.get("home").then(response => {
      let categories = response.categories;
      categories.unshift({color: "rgb(255, 119, 0)",
      icon: "fas fa-shopping-bag",
      id: -1,
      parent: 0,
      title: "Всички рубрики",})
      this.setState({
        listingsCount: response.countListings,
        categories: response.categories,
        isLoading: false
      });
      this.setState({
        shownCategories: this.state.categories.filter(x => x.parent == 0)
      });
      this.setState({ listings: response.listings });
    });

    //this.state.shownCategories = this.state.categories.filter(x=>x.parent == 0);
  }

  changeShownCategories(categoryId, parent) {
    const { backCategories } = this.state;
    backCategories.push(parent);
    this.setState({ backCategories, isTriggeredCategorySelect: true, selectedCategory: categoryId });
    let childCategories = this.state.categories.filter(
      x => x.parent == categoryId
    );
    if (childCategories.length === 0) {
      this.props.history.push('filters?category_id=' + categoryId);
    }

    this.setState({ shownCategories: childCategories });
  }

  printCategories() {
    return this.state.shownCategories.map((c, i) => (
      <div
        onClick={() => {
          this.changeShownCategories(c.id, c.parent);
        }}
        key={i}
        style={Object.assign({}, styles.box, styles.bottomBorder, styles.blue)}
      >
        {c.icon ? <i style={{ color: c.color }} className={c.icon} /> : ""}{" "}
        {c.title}
      </div>
    ));
  }

  printBackCategory() {
    if (this.state.backCategories.length > 0) {
      return (
          <div>
            <div
              style={Object.assign(
                {},
                styles.box,
                styles.bottomBorder,
                styles.blue
              )}
              onClick={() => {
                let { backCategories } = this.state;
                let parent = backCategories.pop();
                this.setState({ backCategories: backCategories });
                this.setState({
                  shownCategories: this.state.categories.filter(
                    x => x.parent == parent
                  )
                });
              }}
            >
              <KeyboardArrowLeft /> Назад
            </div>
            <div
                style={Object.assign(
                    {},
                    styles.box,
                    styles.bottomBorder,
                    styles.blue
                )}
                onClick={() => {
                  this.props.history.push('filters?category_id=' + this.state.selectedCategory);
                }}
            >
               Всички обяви от категорията
            </div>
          </div>
      );
    }

    return <div />;
  }

  printListings() {
    return this.state.listings.map((listing, i) => (
      <SingleListing listing={listing} key={i} col={6} />
    ));
  }
  footer() {
    return (
      <Card className="text-center mt-3 p-2">
        <Link to="/filters?isn=1">Виж най-новите обяви</Link>
      </Card>
    );
  }

  componentDidUpdate() {
    window.addEventListener("scroll", this.floatAddButton);
    //window.addEventListener("onscroll", this.floatAddButtonIos);
  }

  floatAddButton = e => {
    if (window.scrollY > 400 && window.scrollY < 2000) {
      this.setState({ tranSitionButton: 80 });
    } else {
      this.setState({ tranSitionButton: -80 });
    }
  };

  transitionBtn() {
    return (
      <div
        style={{
          position: "fixed",
          zIndex: 300,
          left: "50%",
          bottom: this.state.tranSitionButton,
          transform: "translateX(-50%)",
          // WebkitTransform: "translateX(-50%)",
          // msTransform: "translateX(-50%)",
          transition: "0.4s ease-in-out",
          WebkitTransition: "0.4s ease-in-out"
        }}
      >
        <Link
          className="btn text-white"
          to="listing/create"
          style={{ background: "#f70", fontWeight: 700 }}
          onClick={() => {
            if (!Cookies.get("api_token")) {
              Cookies.set("redirect_to_add_listing", true)
            }
          }}
        >
          <Add className="" /> Добави обява
        </Link>

      </div>
    )
  }

  render() {
    return (
      <div>
        <TopLayout count={this.state.listingsCount} history={this.props.history} />
        <MainPageWrapper>
        {this.state.isLoading &&
        <div style={{textAlign: 'center'}}>
            <CircularProgress />
        </div>
        }
        {!this.state.isLoading &&
            <div>
          <WhiteBoxWrapper>

            <div
              style={Object.assign(
                {},
                styles.box,
                styles.bottomBorder,
                styles.orange
              )}
            >
              <Link
                to="listing/create"
                onClick={() => {
                  if (!Cookies.get("api_token")) {
                    Cookies.set("redirect_to_add_listing", true)
                  }
                }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <span style={{ fontWeight: "bold", fontSize: "27px" }}>+ </span>
                <strong> Добави</strong> безплатна обява
              </Link>
            </div>
            {this.printBackCategory()}
            {this.printCategories()}

          </WhiteBoxWrapper>

          {!this.state.isTriggeredCategorySelect &&
              <AdSense.Google
              client='ca-pub-6108900312282823'
              slot='3028449836'
              style={{ display: 'block' }}
              format='auto'
              responsive='true'
            />}
          <Row>{!this.state.isTriggeredCategorySelect && this.printListings()}</Row>

          {this.transitionBtn()}

          {this.footer()}
          </div>}
        </MainPageWrapper>
      </div>
    );
  }
}

export default HomeScreen;
