import React from "react";
import MainPageWrapper from "../../layout/MainPageWrapper";
import WhiteBoxWrapper from "../../layout/WhiteBoxWrapper";
import TopLayout from "../../layout/TopLayout";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button, Row, Col, Input } from "reactstrap";
import NetworkClient from "../../api/NetworkClient";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import constants from "../../constants/Constants";

class AuthScreen extends React.Component {
  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }

  componentDidMount() {
    const isLogged = Cookies.get("api_token") ? true : false;
    if (isLogged) {
      this.props.history.push("/");
    }
  }

  getParams() {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      verified: searchParams.get('verified') || '',
    };
  }

  loginAttempt() {
    const { email } = this.state;
    const { password } = this.state;
    NetworkClient.post("login", { email, password }).then(
      response => {
        if (response.error) {
          this.setState({ activateEmail: true });
        } else {
          Cookies.set("api_token", response.api_token);
          localStorage.setItem("name", response.name);
          localStorage.setItem("mainPhoto", response.mainPhoto);
          this.props.history.push("/");
        }
      },
      error => {
        this.setState({ error: true });
      }
    );
    //Cookies.set('ap', 'yes');
    // window.location.reload(true);
  }

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      email: "",
      password: "",
      error: false,
      activateEmail: false
    };
  }

  render() {
    return (
      <div>
        <TopLayout history={this.props.history} />
        <MainPageWrapper>
          <WhiteBoxWrapper>
            <div style={{ marginTop: 1 }}>&nbsp;</div>
            <div
              style={{
                width: "100%",
                border: "1px solid #3b6fb6",
                display: "flex",
                textAlign: "center"
              }}
            >
              <Link
                to={"/auth"}
                style={{ width: "49%", color: "white", background: "#3b6fb6" }}
              >
                Вход
              </Link>
              <Link to={"/register"} style={{ width: "49%" }}>
                Регистрация
              </Link>
            </div>
            <div style={{ fontSize: 14, marginTop: 5, marginBottom: 15 }}>
              Влезте в акаунта си и използвайте всички възможности на
              Prodavash.BG
            </div>
            {this.getParams().verified == 1 &&
              <div className="alert alert-success">
                Успешно потвърдена регистрация!
            </div>
            }
            <Row>
              <Col xs={12}>
                <a
                  style={{ background: "#365494", borderColor: "#365494" }}
                  className={"btn btn-info btn-block"}
                  href={
                    constants.BASE_DOMAIN_NAME +
                    "login/facebook?is_api=1"
                  }
                >
                  Вход с Facebook
                </a>
              </Col>
            </Row>
            <Row className={"mt-2"}>
              <Col xs={5}>
                <hr />
              </Col>
              <Col xs={2} className={"text-center"}>
                ИЛИ
              </Col>
              <Col xs={5}>
                <hr />
              </Col>
            </Row>
            <Row>
              {this.state.error == true && (
                <Col xs={12} className={"mt-1 mb-1"}>
                  <div style={{ color: "red" }}>Грешни данни</div>
                </Col>
              )}

              {this.state.activateEmail == true && (
                <Col xs={12} className={"mt-1 mb-1"}>
                  <div style={{ color: "red" }}>
                    Моля потвърдете профила в имейла си.
                  </div>
                </Col>
              )}
              <Col xs={12} className={"mt-3"}>
                <Input
                  type={"text"}
                  placeholder={"E-mail"}
                  value={this.state.email}
                  onChange={event => {
                    this.setState({ email: event.target.value });
                  }}
                />
              </Col>
              <Col xs={12} className={"mt-3"}>
                <Input
                  type={"password"}
                  placeholder={"Парола"}
                  value={this.state.password}
                  onChange={event => {
                    this.setState({ password: event.target.value });
                  }}
                />
              </Col>
              <Col xs={12} className={"mt-3"}>
                <Link to="/password/reset">Забравена парола</Link>
              </Col>
              <Col xs={12} className={"mt-3"}>
                <Button
                  onClick={this.loginAttempt.bind(this)}
                  style={{ background: "#3b6fb6", borderColor: "#3b6fb6" }}
                  className={"btn btn-info btn-block"}
                >
                  Вход
                </Button>
              </Col>
              <Col xs={12} className={"mt-3 mb-3"}>
                Влизайки в профила си приемам общите условия на сайта
              </Col>
            </Row>
          </WhiteBoxWrapper>
        </MainPageWrapper>
      </div>
    );
  }
}

export default AuthScreen;
