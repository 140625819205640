import React from "react";
import { Container, Row, Col } from "reactstrap";
import Drawer from "@material-ui/core/Drawer";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  User,
  Message,
  Search,
  Reorder,
  Add,
  AccountBoxOutlined,
  AccountBox,
  HomeOutlined,
  StyleOutlined,
  Home,
  DesktopWindowsOutlined,
  Computer
} from "@material-ui/icons";
import { Button } from "@material-ui/core";
import UserHeader from "../components/UserHeader";
import Cookies from "js-cookie";
import NetworkClient from '../api/NetworkClient';

const styles = {
  top: {
    background: "white",
    boxShadow: " 0 1px 4px rgba(0,0,0,.3)"
  },
  font: {
    fontFamily: "Open Sans"
  }
};

class TopLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const isLogged = Cookies.get("api_token") ? true : false;
    this.setState({ isLogin: isLogged });
    NetworkClient.get('listing-count').then(response => {
      let { LoginPages } = this.state;
      LoginPages[0].title = response.count + ' обяви';
      this.setState({ LoginPages: LoginPages });
    })
  }

  toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ isOpen: open });
  };

  state = {
    isLogin: true,
    isOpen: false,
    pages: [
      {
        title: "Начало",
        link: "/",
        textColor: "black",
        icon: <Home style={{ color: "#3b6fb6", marginRight: "10px" }} />
      },
      {
        title: "Добави обява",
        link: "listing/create",
        textColor: "#f70",
        icon: <Add style={{ color: "#f70", marginRight: "10px" }} />
      },
      {
        title: "Съобщения",
        link: "messages",
        textColor: "black",
        icon: <Message style={{ color: "#3b6fb6", marginRight: "10px" }} />
      },
      {
        title: "Моят профил",
        link: "my-profile",
        textColor: "black",
        icon: <AccountBox style={{ color: "#3b6fb6", marginRight: "10px" }} />
      },
      {
        title: "Десктоп версия",
        link: "https://prodavash.bg/set-cookie-for-web",
        textColor: "black",
        icon: <Computer style={{ color: "#3b6fb6", marginRight: "10px" }} />
      }
    ],
    LoginPages: [
      {
        title: `${this.props.count} обяви`,
        link: "/filters?isn=1",
        textColor: "black",
        icon: (
          <HomeOutlined
            fontSize="large"
            style={{ color: "#3b6fb6", marginRight: "10px" }}
          />
        )
      },
      {
        title: "Добави обява",
        link: "listing/create",
        textColor: "#f70",
        icon: <Add fontSize="large" style={{ marginRight: "10px" }} />
      },
      {
        title: "Съобщения",
        link: "messages",
        textColor: "black",
        icon: (
          <Message
            fontSize="large"
            style={{ color: "#3b6fb6", marginRight: "10px" }}
          />
        )
      },
      {
        title: "Моите обяви",
        link: "my-listings",
        textColor: "black",
        icon: (
          <StyleOutlined
            fontSize="large"
            style={{ color: "#3b6fb6", marginRight: "10px" }}
          />
        )
      },
      {
        title: "Моят профил",
        link: "my-profile",
        textColor: "black",
        icon: (
          <AccountBox
            fontSize="large"
            style={{ color: "#3b6fb6", marginRight: "10px" }}
          />
        )
      },
      {
        title: "Десктоп версия",
        link: "https://prodavash.bg/set-cookie-for-web",
        textColor: "black",
        icon: (
          <DesktopWindowsOutlined
            fontSize="large"
            style={{ color: "#3b6fb6", marginRight: "10px" }}
          />
        )
      }
    ]
  };

  renderMenu() {
    let menuItems = this.state.isLogin
      ? this.state.LoginPages
      : this.state.pages;

    return menuItems.map((page, index) => (
      <li
        className={"rightArrow"}
        key={index}
        style={{
          borderBottom: "1px solid #ddd",
          fontSize: "17px",
          padding: "0 10px",
          fontFamily: "Tahoma,Arial,sans-serif",
          lineHeight: "44px"
        }}

      >

        {page.title != 'Десктоп версия' && <Link
          style={{ color: page.textColor }}
          to={page.link}
          onClick={page.title == "Добави обява" && this.toggleDrawer(false),
            () => {
              if (!Cookies.get("api_token")) {
                Cookies.set("redirect_to_add_listing", true)
              }
            }}
        >
          {page.icon}
          {page.title}
        </Link>}

        {page.title == 'Десктоп версия' && <a
          style={{ color: page.textColor }}
          href={page.link}
          onClick={this.toggleDrawer(false)}
        >
          {page.icon}
          {page.title}
        </a>}

      </li>
    ));
  }

  render() {
    return (
      <Container className={"sticky-top"} fluid={true} >
        <Row style={styles.top}>
          <Col style={{ padding: 0 }} xs={2} className={"text-left"}>
            <Button onClick={this.toggleDrawer(true)}>
              <Reorder style={{ color: "#3b6fb6" }} />
            </Button>
          </Col>

          <Col xs={8} className={"text-center pt-1"}>
            <Link
              to="/"
              style={{
                fontFamily: "Arial, cursive",
                color: "rgb(255, 119, 0)",
                fontSize: "1.5rem",
                fontWeight: 800
              }}
            >
              <span style={{ color: "rgb(80, 92, 226)" }}>PRODAVASH</span>.BG
            </Link>
          </Col>
          <Col xs={2} className={"text-right"}>
            <Button onClick={() => {
              this.props.history.push('/filters');
            }}>
              <a>
                <Search
                  style={{
                    color: "#f70",
                    fontSize: 30,
                    position: "absolute",
                    top: -4,
                    right: 30
                  }}
                />
              </a>
            </Button>
          </Col>
        </Row>
        <Drawer
          open={this.state.isOpen}
          style={{ marginTop: 50 }}
          onClose={this.toggleDrawer(false)}
        >
          <div style={{ width: 250 }}>
            {this.state.isLogin ? <UserHeader history={this.props.history} /> : null}
            <ul style={{ listStyleType: "none", padding: "5px 0px 0px 5px" }}>
              {this.renderMenu()}
            </ul>
          </div>
        </Drawer>
      </Container>
    );
  }
}

export default TopLayout;
