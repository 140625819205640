import React, { Component } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from "reactstrap";
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";
import { LightgalleryItem } from "react-lightgallery";
import "./SliderPreviewListing.css";
import constants from "../constants/Constants";

const sliderStyle = {
  height: "230px",
  width: "100%"
};

const PhotoItem = ({ image, imagesLightSlider, asd }) => (
  <div>
    {imagesLightSlider[asd]
      ? imagesLightSlider.map((item, k) => {
        return (
          <LightgalleryItem
            key={k}
            group="any"
            src={constants.BASE_DOMAIN_NAME + "storage/images/" + item}
          >
            {item == imagesLightSlider[asd] ? (
              <img
                src={
                  constants.BASE_DOMAIN_NAME +
                  "storage/images/" +
                  imagesLightSlider[asd]
                }
              />
            ) : null}
          </LightgalleryItem>
        );
      })
      : ""}
  </div>
);

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      images: this.props.images, //["68592824420190707070046.jpg", "202948161120190707070100.jpg"]
      isOpen: true
    };



    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);

    this.lightGallery = this.lightGallery.bind(this);
  }

  componentWillReceiveProps({ images }) {
    if (images) {
      this.setState({ ...this.state.images, images })

    }
  }

  lightGallery() {
    this.setState({ isOpen: !this.state.isOpen });
    // console.log("clicked");
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.images.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.images.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = this.state.images.map((item, keyy) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          slide={true}
          onClick={() => {
          }}
          key={item}
        >
          <LightgalleryProvider>
            <div style={sliderStyle}>
              {/* <img src={"http:///storage/images/" + item} /> */}

              <PhotoItem
                // image={"http://127.0.0.1:8000/storage/images/" + i}
                imagesLightSlider={this.state.images}
                asd={keyy}
              />
            </div>
          </LightgalleryProvider>
        </CarouselItem>
      );
    });

    return (
      <div>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          slide={true}
          interval={2100}
        >
          <CarouselIndicators
            items={this.state.images}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </div>
    );
  }
}

export default Slider;
