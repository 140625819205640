import React, { Component } from 'react'
import {
  Row,
  Col,
  Container,
  Card,
  ListGroupItem,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  Button
} from "reactstrap";

export default class Pagination extends Component {

  state = {
    pages: []
  }

  /**
   * Props:
   * currentPage
   * backButtonHandle
   * nextButtonHandle
   * totalPages
  */
  constructor(props) {
    super(props);
  }

  render() {

    let pages =[];
    for(let i = 1; i <= this.props.totalPages; i++)
    {
      pages.push(i);
    }

    return (
      <div>
        <Row style={{ background: '#ececec', padding: 25 }}>
      <Col xs={2}>
        <Button className={"btn btn-secondary btn-sm"} disabled={this.props.currentPage === 1}
          onClick={() => {
            this.props.backButtonHandle();
            /*this.setState({ currentPage: this.state.currentPage - 1 }, () => {
              window.scrollTo(0, 0);
              this.fetchListings();
            })*/

          }}
        >Назад</Button>
      </Col>
      <Col xs={7} style={{fontSize: 15, textAlign:'center'}}>
        стр. <select onChange={(e)=>{this.props.selectHandle(e.target.value)}}>
            {pages.map(i=><option selected={this.props.currentPage===i}>{i}</option>)}
          </select> от {this.props.totalPages}
      </Col>
      <Col xs={3}>
        <Button className={"btn btn-secondary btn-sm"} disabled={this.props.currentPage === this.props.totalPages}
          onClick={() => {
            this.props.nextButtonHandle();
            /*window.scrollTo(0, 0);
            this.setState({ currentPage: this.state.currentPage + 1 }, () => {
              this.fetchListings();
            })*/
          }}>Напред</Button>
      </Col>
    </Row>
      </div>
    )
  }
}
