import React from "react";
import { Link } from "react-router-dom";
import { Container, ListGroup, ListGroupItem } from "reactstrap";
import Header from "../../components/ListingCreate/Header";
import NetworkClient from '../../api/NetworkClient';
import Cookies from "js-cookie";

export default class SettingsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Header history={this.props.history} backPage={"/my-profile"} />
        <Container
          style={{
            background: "#e1e1e1",
            height: "100%",
            paddingBottom: 20,
            paddingTop: 55
          }}
        >
          <ListGroup>
            <ListGroupItem className="rightArrow">
              <Link to="/profile-info">Потребителски профил</Link>
            </ListGroupItem>
            <ListGroupItem className="rightArrow">
              <Link to="/profile-password">Промяна на парола</Link>
            </ListGroupItem>
            <ListGroupItem onClick={() => {
              if (window.confirm('Потвърдете изтриването на профила. Действието е необратимо!')) {
                NetworkClient.post('delete-user', { is_api: 1 })
                  .then(response => {
                    Cookies.remove("api_token");
                    localStorage.removeItem("name");
                    localStorage.removeItem("profilePicture");
                    this.props.history.push('/');
                  })

              }

            }} className="rightArrow mt-3">
              <a >
                Изтриване на профила
              </a>
            </ListGroupItem>
          </ListGroup>
        </Container>
      </div>
    );
  }
}
