import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Card, Label } from "reactstrap";
import Header from "../../components/ListingCreate/Header";
import "./PromoteListingScreen.css";
import NetworkClient from "../../api/NetworkClient";
import "./ProfileInfoScreen.css";
import Cookies from "js-cookie";
import Constants from "../../constants/Constants";
import constants from "../../constants/Constants";

export default class UserProfileInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      file: null,
      mainImage: "img/icons/usr-nophoto.svg"
    };
  }

  componentDidMount() {
    NetworkClient.get("user/profile").then(user => {
      this.setState({ name: user.name });
      if (user.mainPhoto != null && user.mainPhoto.length > 0) {
        this.setState({ mainImage: "storage/images/" + user.mainPhoto });
      }
    });
  }

  profileUpdate() {
    const formData = new FormData();
    let api_token = Cookies.get("api_token");
    if (this.state.file !== null) {
      console.log(this.state.file);
      formData.append("image", this.state.file);
    }

    formData.append("name", this.state.name);

    fetch(`${Constants.BASE_URL_API}profile-update?api_token=${api_token}`, {
      method: "POST",
      body: formData
    })
      .then(res => res.json())
      .then(response => {

        if(response.mainPhoto != null)
        {
          localStorage.setItem(
            "mainPhoto",
            "storage/images/" + response.mainPhoto
          );
          this.setState({ mainImage: "storage/images/" + response.mainPhoto });
        }
       
        localStorage.setItem("name",this.state.name);
        
        this.fileInput.value  = null;
        alert('Успешно редактиран профил!');
      });
  }

  handleImageUpload(event) {
    let file = event.target.files[0];
    this.setState({ file: file });
  }

  deleteProfilePicture() {
    NetworkClient.post('delete-profile-picture').then(response => {
      this.setState({mainImage: 'img/icons/usr-nophoto.svg'});
      this.setState({file: null});
      localStorage.setItem(
        "mainPhoto",'img/icons/usr-nophoto.svg');
      alert('Успешно изтрита профилна снимка!');
    })
  }

  render() {
    return (
      <div>
        <Header history={this.props.history} backPage={"/profile-settings"} />
        {/* <form onSubmit={this.onFormSubmit} method="post"> */}
        <Container
          style={{
            background: "#e1e1e1",
            paddingBottom: 70,
            paddingTop: 50
          }}
        >
          <Card className="p-2 mt-2">
            <Label>Снимка:</Label>
            {this.state.mainImage != 'img/icons/usr-nophoto.svg' && 
            <button className={'btn btn-danger'} onClick={this.deleteProfilePicture.bind(this)}>Премахни снимката</button>}
            <div className="profile-picture text-center">
              <img
                className="img-fluid"
                src={constants.BASE_DOMAIN_NAME + this.state.mainImage}
              />
            </div>
            <div>
              <input ref={ref=> this.fileInput = ref} type="file" onChange={this.handleImageUpload.bind(this)} />
            </div>
          </Card>

          <Card className="p-2 mt-2">
            <Label>Имена:</Label>
            <input
              type="text"
              onChange={event => {
                this.setState({ name: event.target.value });
              }}
              name="names"
              value={this.state.name}
            />
          </Card>

          {/*<Card className="p-2 mt-2">
            <Label>Телефони:</Label>
            <input type="text" name="phone" value={this.state.user.phone} />
            <input
              type="text"
              name="names"
              value={this.state.user.name}
              className="mt-2"
            />
          </Card>*/}
        </Container>

        <div className="footer p-2">
          <Button
            type="button"
            onClick={() => {
              this.profileUpdate();
            }}
            className="btn btn-block text-white "
            style={{ background: "#3b6fb6", fontWeight: 700 }}
          >
            ЗАПИС
          </Button>
        </div>
      </div>
    );
  }
}
