import React from "react";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import style2 from "./Breadcrumb.css";
import { Link } from "react-router-dom";

const Example = props => {
  return (
    <Breadcrumb className="mt-2 mb-2" style={{overflowX: 'scroll'}}>
    <div style={{minWidth: 1000}}>
    {
      props.categories.map((cat,index) => <BreadcrumbItem key={index}>
        <Link to={'/filters?category_id=' + cat.id}>{cat.name}</Link>
       </BreadcrumbItem>)
    }
    </div>
    </Breadcrumb>
  );
};

export default Example;
