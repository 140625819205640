import React from "react";
import { Row, Col, Container, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import NumberBadge from "../../components/NumberBadge";
import { EditOutlined, Add } from "@material-ui/icons";
import UserHeader from "../../components/UserHeader";
import Cookies from "js-cookie";
import NetworkClient from '../../api/NetworkClient';

const styles = {
  title: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#466eb5",
    padding: "10px 10px 10px"
  },
  rightArrow: {
    background:
      "url(" +
      " data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIxNHB4IiBoZWlnaHQ9IjIwcHgiIHZpZXdCb3g9IjAgMCAxNCAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO30KCS5zdDF7ZmlsbDojQThBOEE4O30KCS5zdDJ7ZmlsbDojNzY3Njc2O30KCS5zdDN7ZmlsbDojNDQ5QkVDO30KPC9zdHlsZT4KPGcgaWQ9IkFycm93XzI0XzEiPgoJPHBvbHlnb24gaWQ9IlhNTElEXzRfIiBjbGFzcz0ic3QwIiBwb2ludHM9IjEsMjAgMTQsMjAgMTQsMCAxLDAgCSIvPgoJPGcgaWQ9IlhNTElEXzFfIj4KCQk8cG9seWdvbiBpZD0iWE1MSURfM18iIGNsYXNzPSJzdDEiIHBvaW50cz0iMSwyMCA3LjgsMTAgMSwwIDcuMiwwIDE0LDEwIDcuMiwyMCAJCSIvPgoJPC9nPgo8L2c+CjxnIGlkPSJBcnJvd18yNCI+Cgk8cmVjdCBpZD0iWE1MSURfMTNfIiB5PSIxMjAiIGNsYXNzPSJzdDAiIHdpZHRoPSIxNCIgaGVpZ2h0PSIyMCIvPgoJPGcgaWQ9IlhNTElEXzhfIj4KCQk8cG9seWdvbiBpZD0iWE1MSURfMTJfIiBjbGFzcz0ic3QyIiBwb2ludHM9IjAsMTIwIDYuOCwxMTAgMCwxMDAgNy4yLDEwMCAxNCwxMTAgNy4yLDEyMCAJCSIvPgoJPC9nPgoJPGcgaWQ9IlhNTElEXzJfIj4KCQk8cG9seWdvbiBpZD0iWE1MSURfMTRfIiBjbGFzcz0ic3QzIiBwb2ludHM9IjAsMjIwIDYuOCwyMTAgMCwyMDAgNy4yLDIwMCAxNCwyMTAgNy4yLDIyMCAJCSIvPgoJPC9nPgo8L2c+Cjwvc3ZnPgo=" +
      ")" +
      "98% no-repeat white"
  }
};
export default class ProfileMenuScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listingCount: 0,
      activeMessages: 0
    };
  }

  componentDidMount() {
    const isLogged = Cookies.get("api_token");
    if (!isLogged) {
      this.props.history.push("/auth");
    }
    else {
      NetworkClient.get('user/menu')
        .then(response => {
          this.setState({ listingCount: response.listingCount, activeMessages: response.activeMessages, archivedMessages: response.archivedMessages })
        });
    }
  }

  Logout() {
    Cookies.remove("api_token");
    localStorage.removeItem("name");
    window.location.replace("/");
  }

  render() {
    return (
      <div>
        <UserHeader showArrow={true} history={this.props.history} backPage={"/"} />
        <Container
          style={{
            color: "black",
            backgroundColor: "#e1e1e1",
            height: "100%",
            fontSize: "17px",
            paddingBottom: "20px"
          }}
        >
          <div style={styles.title}>
            Моите обяви
          </div>
          <ListGroup>
            <ListGroupItem
              style={{
                color: "rgb(255, 119, 0)",
                fontSize: "18px",
                fontWeight: "700"
              }}
              onClick={() => {
                this.props.history.push('/listing/create');
              }}
            >
              <Add fontSize="large" />
              Добави обява
            </ListGroupItem>
            <ListGroupItem onClick={() => {
              this.props.history.push('/my-listings');
            }} style={styles.rightArrow}>
              <a>
                Публикувани
              </a>
              <NumberBadge count={this.state.listingCount} />
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              Отказани <NumberBadge />
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              Чакащи проверка
              <NumberBadge />
            </ListGroupItem>
          </ListGroup>
          <div style={styles.title}>
            Портфейл{" "}
            <span
              className="badge badge-pill p-2 text-white"
              style={{ background: "#ff7700", fontWeight: "400" }}
            >
              1 лв.
            </span>
          </div>
          <ListGroup>
            <ListGroupItem style={styles.rightArrow}>
              Захранване на портфейла
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              Промо пакети
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              Плащания / Фактури
            </ListGroupItem>
          </ListGroup>
          <div style={styles.title}>Съобщения</div>
          <ListGroup>
            <ListGroupItem onClick={() => {
              this.props.history.push('/messages/');
            }} style={styles.rightArrow}>
              <a>
                Текущи съобщения
              </a>
              <NumberBadge count={this.state.activeMessages} />
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              <Link to={"/messages?archived=1"}>
                Архивирани
              </Link>
              <NumberBadge count={this.state.archivedMessages} />
            </ListGroupItem>
          </ListGroup>
          <div style={styles.title}>Избрани</div>
          <ListGroup>
            <ListGroupItem style={styles.rightArrow}>
              Обяви
              <NumberBadge />
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              Търсения
              <NumberBadge />
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              Профили
              <NumberBadge />
            </ListGroupItem>
          </ListGroup>
          <div style={styles.title}>Доставка</div>
          <ListGroup>
            <ListGroupItem style={styles.rightArrow}>
              Моите доставки
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              Нова доставка
            </ListGroupItem>
          </ListGroup>
          <div style={styles.title}>Други</div>
          <ListGroup>
            <ListGroupItem style={styles.rightArrow}>
              <Link to="/profile-settings/">Настройки</Link>
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              <Link to="/contacts/"> Връзка с нас</Link>
            </ListGroupItem>
            <ListGroupItem style={styles.rightArrow}>
              <Link to="/terms">
                Общи условия
              </Link>
            </ListGroupItem>
            <ListGroupItem onClick={this.Logout} style={styles.rightArrow}>
              <a>
                Изход
              </a>
            </ListGroupItem>
          </ListGroup>
        </Container>
      </div>
    );
  }
}
