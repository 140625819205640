import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import zIndex from "@material-ui/core/styles/zIndex";
import { tsConstructorType } from "@babel/types";

export default class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer
        className="container p-2 mt-3 fixed-bottom"
        style={{
          background: "#ececec",
          zIndex: 2000,
          borderTop: "2px solid #ddd",
          bottom: 0
        }}
      >
        <Row>
          <Col xs="6">
            <Button
              onClick={this.props.closedrawer}
              style={{
                color: "#3b6fb6",
                background: "white",
                fontWeight: 300,
                border: "1px solid #3b6fb6"
              }}
              size="lg"
              block
            >
              Отказ
            </Button>
          </Col>
          <Col xs="6">
            <Button
               onClick={this.props.handleClickOkButton}
              style={{ background: "#3b6fb6", fontWeight: 700 }}
              size="lg"
              block
            >
              ОК
            </Button>
          </Col>
        </Row>
      </footer>
    );
  }
}
