import React, { Component } from 'react'
import { Row, Col } from "reactstrap";
import renderHTML from "react-render-html";
import {Link} from 'react-router-dom';
import {FavoriteBorder}  from "@material-ui/icons";
import Constants from "../../constants/Constants";

export default class SingleListingFullWidth extends Component {
    imgLink = 'https://t4.ftcdn.net/jpg/02/26/96/25/240_F_226962583_DzHr45pyYPdmwnjDoqz6IG7Js9AT05J4.jpg';
    constructor(props) {
        super(props);
    }

    render() {
        const image = this.props.listing.image
        ? Constants.BASE_URL_IMAGE + this.props.listing.image
        : Constants.BASE_IMAGE_NOT_FOUND;

        return (
            <Link to={"/obqva/" + this.props.listing.slug}>
                <Row style={{ background: 'white', marginTop: 10, padding: 9 }}>
                    <Col xs={3}>
                        <div
                            style={
                                {
                                    backgroundImage: `url(${image})`,
                                    width: 90,
                                    height: 70,
                                    backgroundPosition: 'center center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }
                            }>
                            {this.props.listing.isTop &&
                            <img
                                src="https://prodavash.bg/img/vip.png"
                                style={{ position: "absolute", top: 0, left: 15 }}
                            />}
                            &nbsp;
                    </div>
                    </Col>
                    <Col xs={9} className={"pl-4"}>
                        <div style={{ fontSize: 17, color: '#3b6fb6' }}>{this.props.listing.title}</div>
                        <div style={{ fontSize: 13, color: '#999' }}>{this.props.listing.city.name}, публикувана</div>
                        <Row>
                            <Col xs={8}>
                            <div style={{ fontSize: 17, color: '#303030' }}>
                                {this.props.listing.price_show}
                                {this.props.listing.price_show ?
                                    <span>{this.props.listing.price_currency == 1
                                    ? " лв."
                                    : renderHTML("<span> &euro; </span>")}</span> : 'По договаряне'}
                            </div>
                            </Col>
                            <Col xs={2}>
                                <FavoriteBorder style={{width:32,height:32, color:'#3b6fb6'}}/>
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col xs={12} style={{fontSize: 11, color: '#555', marginTop: 5}}>
                            {this.props.listing.description.substr(0, 121)}...
                    </Col>
                </Row>
            </Link>
        )
    }
}
