import React from 'react';

class AboutScreen extends React.Component {
    state = {  }
    render() { 
        return ( 
            <div>Hello</div>
         );
    }
}
 
export default AboutScreen;