import React from "react";
import { Link } from "react-router-dom";

import {
  Modal,
  Container,
  Card,
  Row,
  Col,
  Label,
  Button,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import Header from "../../components/ListingCreate/Header";
import {
  AirplanemodeActive,
  Sync,
  Star,
  NotificationImportant
} from "@material-ui/icons";
import "./PromoteListingScreen.css";
import { FormGroup } from "@material-ui/core";
import NetworkClient from "../../api/NetworkClient";

const name = localStorage.getItem("name");
const email = localStorage.getItem("email");

export default class PasswordScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentPass: "",
      newPass: "",
      newPass2: "",
      responseMsg: "",
      email: "",
      errors: []
    };
  }

  componentDidMount() {
    this.setState({ name: name });
  }

  handleCurrentPassChange(e) {
    // let currentPass = e.target.value;
    this.setState({ currentPass: e.target.value });
  }
  handleNewPassChange(e) {
    this.setState({ newPass: e.target.value });
  }
  handleNewPass2Change(e) {
    this.setState({ newPass2: e.target.value });
  }
  onFormSubmit() {
    // NetworkClient.post(`/user/password/${userId}`, {
    this.setState({ isLoading: true });
    NetworkClient.post("/user/password", {
      name: name,
      email: this.state.email,
      oldPassword: this.state.currentPass,
      password: this.state.newPass,
      passwordConfirmation: this.state.newPass2
    }).then(response => {
      this.setState({ responseMsg: response, errors: response, isLoading: false });
    });
  }
  render() {
    return (
      <div>
        <Header history={this.props.history} backPage={"/profile-settings"} />
        {/* <form onSubmit={this.onFormSubmit} method="post"> */}
        <Container
          style={{
            background: "#e1e1e1",
            height: "100vh",
            paddingBottom: 20,
            paddingTop: 55
          }}
        >
          {this.state.responseMsg.status && (
            <div className={this.state.responseMsg.status} role="alert">
              {this.state.responseMsg.msg}
            </div>
          )}

          <Card className="p-3">
            <FormGroup>
              <Label
                style={{ color: "#3b6fb6", fontWeight: 700, fontSize: 16 }}
              >
                Стара парола:
              </Label>

              <input
                onChange={this.handleCurrentPassChange.bind(this)}
                // value={this.state.currentPass}
                name="currentPass"
                type="password"
                className="form-control"
              />
              <span className="text-danger">
                {this.state.errors.oldPassword && this.state.errors.oldPassword}
              </span>
            </FormGroup>
          </Card>

          <Card className="p-3 mt-2">
            <FormGroup>
              <Label
                style={{ color: "#3b6fb6", fontWeight: 700, fontSize: 16 }}
              >
                Нова парола:
              </Label>

              <input
                onChange={this.handleNewPassChange.bind(this)}
                // value={this.state.newPass}
                name="newPass"
                type="password"
                className="form-control"
              />
              <span className="text-danger">
                {this.state.errors.password && this.state.errors.password}
              </span>
            </FormGroup>
          </Card>

          <Card className="p-3 mt-2">
            <FormGroup>
              <Label
                style={{ color: "#3b6fb6", fontWeight: 700, fontSize: 16 }}
              >
                Повтори паролата:
              </Label>

              <input
                onChange={this.handleNewPass2Change.bind(this)}
                // value={this.state.newPass2}
                name="newPass2"
                type="password"
                className="form-control"
              />
              <span className="text-danger">
                {this.state.errors.passwordConfirmation &&
                  this.state.errors.passwordConfirmation}
              </span>
            </FormGroup>
          </Card>
        </Container>
        <div
          className="p-2"
          style={{
            backgroundColor: "#ececec",
            borderTop: "2px solid #ddd",
            position: "fixed",
            bottom: 0,
            width: "100%",
            height: "57px"
          }}
        >
          <Button
            onClick={this.onFormSubmit.bind(this)}
            type="submit"
            disabled={this.state.isLoading}
            className="btn btn-block text-white "
            style={{ background: "#3b6fb6", fontWeight: 700 }}
          >
            ЗАПИС
          </Button>
        </div>
        {/* </form> */}
      </div>
    );
  }
}
