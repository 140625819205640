import React from "react";
import { Card, Col } from "reactstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
const isSeenStyle = {
  border: "solid tomato"
};

export default class SingleMessageItem extends React.Component {
  constructor(props) {
    super(props);
    // this.setState({ isArchivedMessage: this.props.isArchived });
  }

  render() {
    let isSeen = this.props.isSeen;
    return (
      <div>
        <Card
          className="mt-2"
          style={{ border: isSeen != true ? "solid tomato" : "" }}
        >
          <Link
            to={"/message/" + this.props.id}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <Col style={{ paddingLeft: 0 }} className={"p-2"}>
              <span xs={9} style={{ fontWeight: "700" }}>
                Аз, {this.props.name} ({this.props.count})
              </span>
              <span
                xs={3}
                style={{ fontSize: 12, color: "#999", float: "right" }}
              >
                {this.props.created}
              </span>
            </Col>

            <hr style={{ padding: 0, margin: 0 }} />
            <Col className={"mb-2 p-2"}>
              <div xs={12}>{this.props.replay}</div>
            </Col>
          </Link>
        </Card>
      </div>
    );
  }
}
