import React from "react";
import BackArrow from "../../../components/GoBackArrow";
import FooterMessageCall from "../../../components/FooterMessageAndCall";
import { TuneRounded, MessageOutlined, MoreVert } from "@material-ui/icons/";
import { Card, Col, Row, ListGroupItem, Modal } from "reactstrap";
import { Container, Link, IconButton, Button } from "@material-ui/core";
import "./ConversationScreen.css";
import NetworkClient from "../../../api/NetworkClient";
import { withRouter } from "react-router-dom";

export default class Conversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: [],
      replies: [],
      modal: false,
      conversationId: -1
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  loadMessage() {
    NetworkClient.get(
      "/message/" + this.props.match.params.conversationId
    ).then(data => {
      this.setState({ msg: data, replies: data.replies });
      window.scrollTo(0, document.body.scrollHeight);
    });
  }

  componentDidMount() {
    const conversationId = this.props.match.params.conversationId;
    //{conversationId: conversationId}
    //{conversationId}
    this.setState({ conversationId });
    // console.log(this.props.match.params.conversationId);
    this.loadMessage();
  }

  showTop() {
    return (
      <div
        //   style={{  }}
        style={{
          zIndex: "999",
          width: "100%",
          height: "50px",
          backgroundColor: "white",
          color: "#3b6fb6",
          boxShadow: "0 1px 4px rgba(0,0,0,.3)",
          inHeight: "50px",
          paddingTop: "9px"
        }}
      >
        <BackArrow history={this.props.history} backPage={"/messages"} />

        <div
          style={{
            left: "50%",
            position: "absolute",
            display: "inline",
            textAlign: "center",
            transform: "translateX(-50%)",
            WebkitTransform: "translateX(-50%)",
            msTransform: "translateX(-50%)",
            lineHeight: "34px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: "18px"
          }}
        >
          <img src="https://prodavash.bg/img/icons/usr-nophoto.svg" />
          {this.state.msg.user_name}
        </div>

        <MoreVert
          onClick={this.toggleModal}
          fontSize="large"
          style={{ float: "right", margin: "5px" }}
        />
        <Modal
          style={{ marginTop: "40%" }}
          isOpen={this.state.modal}
          toggle={this.toggleModal}
        >
          <Card className="text-center">
            <ListGroupItem
              onClick={() =>
                this.archiveConversation(this.props.match.params.conversationId)
              }
            >
              {this.state.msg.is_archived ? "Възстанови" : "Архивирай"}
            </ListGroupItem>
            {this.state.msg.is_archived && (
              <ListGroupItem
                onClick={() =>
                  this.deleteConversation(
                    this.props.match.params.conversationId
                  )
                }
              >
                Изтрий
              </ListGroupItem>
            )}
            <ListGroupItem>
              <a href="">
                <span className="font-weight-bold">Отмени</span>
              </a>
            </ListGroupItem>
          </Card>
        </Modal>
      </div>
    );
  }

  toggleModal() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  deleteConversation = conversationId => {
    NetworkClient.delete(`/user/messages/delete/${conversationId}`).then(
      response => {
        console.log(response);
        window.history.back();
      }
    );
  };

  archiveConversation = conversationId => {
    // console.log(asd);

    NetworkClient.post(`/message/archive/${conversationId}`).then(response => {
      console.log(response);
      window.history.back();
    });
  };

  sendMessage(reply, conversationId) {
    //let listingId = this.state.
    if (reply.length == 0) {
      alert("Моля въведете съобщение!");
      return;
    }

    NetworkClient.post(`/message/reply/${conversationId}`, {
      reply: reply
    }).then(response => {
      this.loadMessage();
    });
  }

  render() {
    return (
      <div>
        {this.showTop()}
        <div className="rightArrowBlue p-2">
          <Link
            to={"/obqva/" + this.state.msg.slug}
            style={{
              text: "normal",
              textDecoration: "inherit",
              color: "inherit"
            }}
          >
            {this.state.msg.listing_title}
            <br />
            <b>
              {this.state.msg.price}
              {this.state.msg.currency}
            </b>
          </Link>
        </div>
        <Container
          className="mt-4 text-center"
          style={{
            overflowY: "scroll",
            paddingBottom: "65px",
            minHeight: "80vh"
          }}
        >
          {this.state.replies.map((m, k) => (
            <Row className="mt-3" key={k}>
              <Col>
                <span style={{ color: "#666", fontSize: 11 }}>
                  {m.created_at}
                </span>
                <div
                  className={m.is_right == true ? "right-user" : "left-user"}
                >
                  {m.reply}
                </div>
              </Col>
            </Row>
          ))}
        </Container>
        <FooterMessageCall
          conversationId={this.state.conversationId}
          handleSend={this.sendMessage.bind(this)}
        />
      </div>
    );
  }
}
