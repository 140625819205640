import React from "react";
import {
  Modal,
  Container,
  Card,
  Row,
  Col,
  Button,
  FormGroup,
  ListGroupItem
} from "reactstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Header from "../../components/MyListings/Header";
import { IconButton } from "@material-ui/core";
import { LinearScale, MoreHoriz } from "@material-ui/icons";
import NetworkClient from "../../api/NetworkClient";
import "./MyListingsScreen.css";
import constants from "../../constants/Constants";
import Cookies from "js-cookie";

const styles = {
  activeButton: {
    width: "49%",
    backgroundColor: "#3b6fb6"
  },
  defaultButton: {
    borderRadius: "0px 5px 5px 0px",
    width: "49%",
    border: "1px solid #3b6fb6",
    background: "white",
    color: "#3b6fb6"
  },
  leftBtnBorder: {
    borderRadius: "5px 0px 0px 5px"
  },
  rightBtnBorder: {
    borderRadius: "0px 5px 5px 0px"
  }
};

export default class MyListings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listings: [],
      totalListing: "",
      modal: false,
      selectedId: -1,
      showSuccessRefresh: false,
      showErrorRefresh: false,
      refresh: 0,
      allListingsCopy: [],
      showSearch: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  load() {
    NetworkClient.get("/user/listings").then(data => {
      this.setState({
        listings: data.listings,
        allListingsCopy: data.listings,
        totalListing: data.listings.length,
        refresh: data.refresh
      });
      window.scroll(0, 0);
    });
  }

  refreshListing() {
    NetworkClient.put("listing-refresh/" + this.state.selectedId).then(
      response => {
        this.load();
        if (response.success) {
          this.setState({ modal: false, showSuccessRefresh: true });
        } else {
          this.setState({ modal: false, showErrorRefresh: true });
        }
      }
    );
  }

  componentDidMount() {
    const isLogged = Cookies.get("api_token");
    if (!isLogged) {
      this.props.history.push("/auth");
    }
    else
    {
      this.load();
    }

  }

  toggleModal() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleSearchClick() {
    this.setState({showSearch: !this.state.showSearch})
  }

  render() {
    return (
      <div>
        <Header history={this.props.history}
                handleSearchClick={this.handleSearchClick.bind(this)}
                hideOld={true}
                backPage={"/my-profile"} />

        <Container
          style={{
            background: "#e1e1e1",
            height: "100%",
            paddingBottom: 20,
            paddingTop: 55
          }}
        >
          <div className="mb-2">
            <Button
              className="btn btn-secondary btn-sm btn-block"
              style={{ background: "#3b6fb6" }}
            >
              Публикувани ({this.state.totalListing})
            </Button>
          </div>

          <Card className="p-2">
            {this.state.showSearch &&
            <div className="form-group has-search">
              <span className="fa fa-search form-control-feedback" />
              <input onChange={(e)=>{
                let keyword = e.target.value.toLowerCase();
                if(keyword.length === 0)
                {
                  this.setState({listings: this.state.allListingsCopy})
                }
                else
                {
                  let {listings} = this.state;
                  listings = listings.filter(l => String(l.title).toLowerCase().includes(keyword));
                  this.setState({listings: listings});
                }
              }} type="text" className="form-control" placeholder="Заглавие..." />
            </div>
            }

            <div>
              Имаш {this.state.refresh} обновявания.
            </div>
          </Card>
          {this.state.showSuccessRefresh && (
            <div
              onClick={() => {
                this.setState({ showSuccessRefresh: false });
              }}
              className={"alert alert-success mt-3"}
            >
              Успешно обновена обява! <span className={"float-right"}>X</span>
            </div>
          )}

          {this.state.showErrorRefresh && (
            <div
              onClick={() => {
                this.setState({ showErrorRefresh: false });
              }}
              className={"alert alert-danger mt-3"}
            >
              Нямате обновявания, моля закупете ако искате да обновите обявата!{" "}
              <span className={"float-right"}>X</span>
            </div>
          )}

          {this.PrintListing()}
          <Modal
            style={{ marginTop: "40%" }}
            isOpen={this.state.modal}
            toggle={this.toggleModal}
          >
            <Card className="text-center">
              <ListGroupItem>
                <a
                  onClick={() => {
                    let id = this.state.selectedId;
                    let index = this.state.listings.findIndex(x => x.id === id);
                    this.props.history.push(
                      `/obqva/${this.state.listings[index].slug}`
                    );
                  }}
                >
                  Преглед
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <Link to={`/listing/edit/${this.state.selectedId}`}>
                  Редактирай
                </Link>
              </ListGroupItem>
              <ListGroupItem
                onClick={() => {
                  this.refreshListing();
                }}
              >
                <a>Обнови</a>
              </ListGroupItem>
              <ListGroupItem
                onClick={() => {
                  if (window.confirm("Потвърди изтриването") === true) {
                    NetworkClient.delete(
                      `listing/${this.state.selectedId}`
                    ).then(response => {
                      if (response.success) {
                        this.setState({ selectedId: -1, modal: false });
                        this.load();
                      }
                    });
                  }
                }}
              >
                <a>Изтрий</a>
              </ListGroupItem>
              <ListGroupItem>
                <Link to={`/user/promote/listing/${this.state.selectedId != -1 ?  this.state.listings.find( x=> x.id == this.state.selectedId).slug :''}`}>
                  Промотирай
                </Link>
              </ListGroupItem>
              <ListGroupItem style={{ display: "none" }}>
                Сподели обявата чрез:
                <Button>facebook viber.. whatsapp</Button>
              </ListGroupItem>
              <ListGroupItem>
                <a onClick={this.toggleModal}>
                  <span className="font-weight-bold">Отмени</span>
                </a>
              </ListGroupItem>
            </Card>
          </Modal>
        </Container>
      </div>
    );
  }
  PrintListing() {
    return this.state.listings.map((l, index) => (
      <Card className="mt-3 p-2" key={index}>
        <Row>
          <Col xs="4">
            {l.image !== null && <img
              style={{ width: 90, height: 70 }}
              href={constants.BASE_DOMAIN_NAME + "storage/images/" + l.slug}
              src={constants.BASE_DOMAIN_NAME + "storage/images/" + l.image}
              className="img-fluid"
            />}

            {l.image === null && <img
              style={{ width: 90, height: 70 }}
              href={constants.BASE_DOMAIN_NAME + "storage/images/" + l.slug}
              src={constants.BASE_IMAGE_NOT_FOUND}
              className="img-fluid"
            />}

          </Col>
          <Col xs="8">
            <Link
              to={"/obqva/" + l.slug}
              style={{ color: "#3b6fb6", fontSize: 17 }}
            >
              {l.title}
            </Link>

            <div style={{ fontSize: 13, color: "#999" }}>
              {/* от: днес до: 27 септ */}
              публикувана: {l.created_at}
            </div>

            <div style={{ fontSize: 17, fontWeight: 700 }}>
              {l.price === null
                ? "По договаряне "
                : l.price_show + " " + l.price_currency}
            </div>
          </Col>
        </Row>
        <hr />
        <Row style={{ fontSize: 11 }} className="text-center">
          <Col xs="4">
            <span style={{ fontSize: 18, fontWeight: 700, display: "block" }}>
              {l.views}
            </span>
            Преглеждания
          </Col>
          <Col xs="4">
            <span style={{ fontSize: 18, fontWeight: 700, display: "block" }}>
              {l.mobileCalls}
            </span>
            Телефон
          </Col>
          <Col xs="4">
            <span style={{ fontSize: 18, fontWeight: 700, display: "block" }}>
              {l.messagesCount}
            </span>
            Съобщения
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Button
              style={{
                background: "#3b6fb6",
                width: "34%",
                fontSize: 12,
                marginRight: 10
              }}
              className=""
              onClick={() => {
                this.setState({ selectedId: l.id }, () => {
                  this.refreshListing();
                });
              }}
            >
              Обнови
            </Button>
            <Button
              className=""
              href={"/user/promote/listing/" + l.slug}
              style={{
                background: "#3b6fb6",
                width: "34%",
                fontSize: 12
              }}
            >
              Промотирай
            </Button>
            <IconButton
              onClick={() => {
                this.setState({ selectedId: l.id });
                this.toggleModal();
              }}
              className="mr-3"
            >
              <MoreHoriz style={{ fontSize: 34, color: "#3b6fb6" }} />
            </IconButton>
          </Col>
        </Row>
      </Card>
    ));
  }
}
