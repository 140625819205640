import React from "react";
import {
  Row,
  Col,
  Container,
  Card,
  ListGroupItem,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  Button
} from "reactstrap";
import GoBackArrow from "../../components/GoBackArrow";
import {
  EditOutlined,
  Search,
  CloseRounded,
  CloseOutlined,
  Close,
  Check,
  HighlightOffOutlined,
  HighlightOffRounded,
  HighlightOff,
  KeyboardArrowLeft
} from "@material-ui/icons";
import Header from "../../components/ListingCreate/Header";
import { TextField, InputAdornment, Drawer } from "@material-ui/core";
import NetworkClient from "../../api/NetworkClient";
import ShowListings from './ShowListings';
import 'url-search-params-polyfill';
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { PaginationItem, PaginationLink } from 'reactstrap';
import Cookies from 'js-cookie';
import AdSense from 'react-adsense';
import Pagination from '../../partials/pagination/Pagination';
import Constants from '../../constants/Constants';
const style = {
  active: {
    background: "#3b6fb6",
    color: "white",
    border: "1px solid #3b6fb6"
  },
  default: {
    background: "white",
    color: "#3b6fb6",
    border: "1px solid #3b6fb6"
  }
};

const styles = {
  listings: {
    marginTop: "10px"
  },
  box: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "17px",
    padding: 7
  },
  bottomBorder: {
    borderBottom: "1px solid #ddd"
  },
  blue: {
    color: "#3b6fb6"
  },
  orange: {
    color: "#f70"
  }
};

class FiltersScreen extends React.Component {
  INITIAL_STATE = {
    isOpenfilterDrawer: false,
    isExtras: false,
    priceFields: false,
    priceFrom: "",
    priceTo: "",
    priceFromTemp: "",
    priceToTemp: "",

    cities: [],
    citiesFields: false,
    city: "",
    villages: [],
    villagesFields: false,
    village: "",
    villageId: -1,
    cityId: -1,

    sortFields: false,
    sortBy: "Дата",
    sortByDate: true,
    sortByPriceAsc: !true,
    sortByPriceDesc: !true,

    privatePersons: "",
    allPersons: true,
    podatel: "Всички",
    podatelFields: false,

    listings: [],
    totalPages: 1,
    currentPage: 1,
    isFilters: true,
    isEmergency: false,
    hasPhoto: false,
    name: '',
    categories: [],
    selectedCategory: -1,
    isOpen: false,
    backCategories: [],
    shownCategories: [],
    extraFields: [],
    selectedExtras: [],
    selectedExtrasTemp: [],
    drawerData: [],
    isLoading: false,
    //Gumi i djanti
    seasons: [
      { id: "Летни", name: "Летни" },
      { id: "Зимни", name: "Зимни" },
      { id: "Всесезонни", name: "Всесезонни" }
    ],
    width: [
      70,
      80,
      85,
      90,
      95,
      100,
      105,
      110,
      115,
      120,
      125,
      130,
      135,
      140,
      145,
      150,
      155,
      160,
      165,
      170,
      175,
      180,
      185,
      190,
      195,
      200,
      205,
      210,
      215,
      220,
      225,
      230,
      235,
      240,
      245,
      250,
      255,
      260,
      265,
      270,
      275,
      280,
      285,
      295,
      300,
      305,
      315,
      325,
      330,
      335,
      345,
      355,
      375,
      395,
      425,
      435,
      445
    ],
    height: [
      25,
      30,
      35,
      40,
      45,
      50,
      55,
      60,
      65,
      70,
      75,
      80,
      82,
      85,
      90,
      95,
      100,
      105,
      110,
      115,
      120
    ],
    diameter: [
      10,
      11,
      12,
      13,
      14,
      15,
      15.5,
      16,
      16.5,
      17,
      18,
      19,
      19.5,
      20,
      21,
      22,
      22.5,
      23,
      24,
      26,
      28
    ],
    wheelsBrands: [
      "Accelera",
      "Achilles",
      "Admiral",
      "Alliance",
      "America",
      "Atturo",
      "Autogrip",
      "Avon",
      "Barum",
      "BCT",
      "BFGoodrich",
      "Bridgestone",
      "Capitol",
      "Ceat",
      "Clear",
      "Continental",
      "Cooper",
      "Dayton",
      "Debica",
      "Delinte",
      "Dextero",
      "Dunlop",
      "Duro",
      "Durun",
      "Effiplus",
      "Eurostone",
      "Falken",
      "Federal",
      "Firestone",
      "Fortuna",
      "Fulda",
      "Fullway",
      "General",
      "Gislaved",
      "GoldenTyre",
      "Goodride",
      "Goodyear",
      "Gremax",
      "GTRadial",
      "Haida",
      "Hankook",
      "Hero",
      "HIFLY",
      "HighPerformer",
      "Infinity",
      "InsaTurbo",
      "Interco",
      "Interstate",
      "Kelly",
      "Kenda",
      "Kinforest",
      "KingMeiler",
      "KingsTire",
      "Kingstar",
      "Kleber",
      "Kormoran",
      "Kumho",
      "Lassa",
      "Lexani",
      "Linglong",
      "Maloya",
      "Marangoni",
      "Marix",
      "Marshal",
      "Mastersteel",
      "Matador",
      "Maxtrek",
      "Maxxis",
      "Meteor",
      "Michelin",
      "MickeyThompson",
      "Minerva",
      "Nankang",
      "Nexen",
      "Nokian",
      "Novex",
      "Pace",
      "Petlas",
      "Pirelli",
      "Pneumant",
      "Recip",
      "Regal",
      "Riken",
      "Roadstone",
      "Rockstone",
      "Rotalla",
      "Rotex",
      "Runway",
      "Sailun",
      "Sava",
      "Semperit",
      "Silverstone",
      "Sonny",
      "Star",
      "StarPerformer",
      "Starco",
      "Starfire",
      "Starmaxx",
      "Stunner",
      "Sunew",
      "Sunny",
      "Syron",
      "Tigar",
      "Toyo",
      "Trayal",
      "Triangle",
      "Tyfoon",
      "Uniroyal",
      "Viking",
      "Vredestein",
      "VSP",
      "Wanli",
      "Westlake",
      "WinterTact",
      "Yokohama",
      "Yokohama",
      "Zeetex",
      "Zeetex",
      "Zetum",
      "Zetum",
      "Други"
    ],
    material: ["Алуминиеви", "Железни", "Магнезиеви", "Други"],
    markaavto: [
      { id: 1, name: "AC" },
      { id: 2, name: "Acura" },
      { id: 79, name: "Aixam" },
      { id: 80, name: "Alfa romeo" },
      { id: 81, name: "Aro" },
      { id: 82, name: "Asia" },
      { id: 83, name: "Aston Martin" },
      { id: 84, name: "Audi" },
      { id: 85, name: "Austin" },
      { id: 86, name: "BMW" },
      { id: 87, name: "Bentley" },
      { id: 88, name: "Berliner" },
      { id: 89, name: "Bertone" },
      { id: 90, name: "Blue Bird" },
      { id: 91, name: "Borgward" },
      { id: 92, name: "Brilliance" },
      { id: 93, name: "Bugatti" },
      { id: 94, name: "Buick" },
      { id: 95, name: "Cadillac" },
      { id: 96, name: "Chevrolet" },
      { id: 97, name: "Chrysler" },
      { id: 98, name: "Citroen" },
      { id: 99, name: "Corvette" },
      { id: 100, name: "Dacia" },
      { id: 101, name: "Daewoo" },
      { id: 102, name: "Daihatsu" },
      { id: 103, name: "Daimler" },
      { id: 104, name: "Datsun" },
      { id: 105, name: "Dkw" },
      { id: 106, name: "Dodge" },
      { id: 107, name: "Dr" },
      { id: 108, name: "Eagle" },
      { id: 109, name: "FSO" },
      { id: 110, name: "Ferrari" },
      { id: 111, name: "Fiat" },
      { id: 112, name: "Ford" },
      { id: 113, name: "GOUPIL" },
      { id: 114, name: "Gaz" },
      { id: 115, name: "Geo" },
      { id: 116, name: "Gmc" },
      { id: 117, name: "Great wall" },
      { id: 118, name: "Haval" },
      { id: 119, name: "Heinkel" },
      { id: 120, name: "Hillman" },
      { id: 121, name: "Honda" },
      { id: 122, name: "Hummar" },
      { id: 123, name: "Hyundai" },
      { id: 124, name: "Ifa" },
      { id: 125, name: "Infinity" },
      { id: 126, name: "Innocenti" },
      { id: 127, name: "Isuzu" },
      { id: 128, name: "Iveco" },
      { id: 129, name: "Jaguar" },
      { id: 130, name: "Jeep" },
      { id: 131, name: "Jpx" },
      { id: 132, name: "Kia" },
      { id: 133, name: "Lada" },
      { id: 134, name: "Laforza" },
      { id: 135, name: "Lamborghini" },
      { id: 136, name: "Lancia" },
      { id: 137, name: "Land rover" },
      { id: 138, name: "Landwind" },
      { id: 139, name: "Ldv" },
      { id: 140, name: "Lexus" },
      { id: 141, name: "Lifan" },
      { id: 142, name: "Lincoln" },
      { id: 143, name: "Lotus" },
      { id: 144, name: "Mahindra" },
      { id: 145, name: "Meserati" },
      { id: 146, name: "Matra" },
      { id: 147, name: "Maybach" },
      { id: 148, name: "Mazda" },
      { id: 149, name: "McLaren" },
      { id: 150, name: "Mercedes-Benz" },
      { id: 151, name: "Mercury" },
      { id: 152, name: "Mg" },
      { id: 153, name: "Microcar" },
      { id: 154, name: "Mini" },
      { id: 155, name: "Mitsubishi" },
      { id: 156, name: "Morgan" },
      { id: 157, name: "Moskvich" },
      { id: 158, name: "Nissan" },
      { id: 159, name: "Oldsmobile" },
      { id: 160, name: "Oltsit" },
      { id: 161, name: "Opel" },
      { id: 162, name: "Perodua" },
      { id: 163, name: "Peugeot" },
      { id: 164, name: "Pgo" },
      { id: 165, name: "Plymouth" },
      { id: 166, name: "Polonez" },
      { id: 167, name: "Pontiac" },
      { id: 168, name: "Porsche" },
      { id: 169, name: "Proton" },
      { id: 170, name: "Renault" },
      { id: 171, name: "Rolls-Royce" },
      { id: 172, name: "Rover" },
      { id: 173, name: "SECMA" },
      { id: 174, name: "SH Auto" },
      { id: 175, name: "Saab" },
      { id: 176, name: "Samand" },
      { id: 177, name: "Saturn" },
      { id: 178, name: "Scion" },
      { id: 179, name: "Seat" },
      { id: 180, name: "Setra" },
      { id: 181, name: "Shatenet" },
      { id: 182, name: "Shuanghuan" },
      { id: 183, name: "Simca" },
      { id: 184, name: "Skoda" },
      { id: 185, name: "Smart" },
      { id: 186, name: "SsangYong" },
      { id: 187, name: "Subaru" },
      { id: 188, name: "Suzuki" },
      { id: 189, name: "Talbot" },
      { id: 190, name: "Tata" },
      { id: 191, name: "Tavria" },
      { id: 192, name: "Tazzari" },
      { id: 193, name: "Tempo" },
      { id: 194, name: "Terberg" },
      { id: 195, name: "Tesla" },
      { id: 196, name: "Tofas" },
      { id: 197, name: "Toyota" },
      { id: 198, name: "Trabant" },
      { id: 199, name: "Triumph" },
      { id: 200, name: "Uaz" },
      { id: 201, name: "VROMOS" },
      { id: 202, name: "VW" },
      { id: 203, name: "Volga" },
      { id: 204, name: "Volvo" },
      { id: 205, name: "Warszawa" },
      { id: 206, name: "Wartburg" },
      { id: 207, name: "Wiesmann" },
      { id: 208, name: "Xinkai" },
      { id: 209, name: "Xinshun" },
      { id: 210, name: "Zastava" },
      { id: 211, name: "Zaz" }
    ],
    suggestedCategories:  [],
    suggestedKeywords: [],
    suggestedKeyword: null,
    isSearchOnFocus: false
  };

  constructor(props) {
    super(props);
    this.state = this.INITIAL_STATE;
  }

  setGumiIDjantiExtraFields(selName) {
    //**************** */
    //    Sezonnost
    //**************** */
    if (
      (selName == "Гуми" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "season") == -1
    ) {
      let ef = this.state.extraFields;
      let seasons = {
        data: this.state.seasons,
        isTextField: false,
        multiple: false,
        name: "season",
        selected: { id: "", name: "" },
        title: "Сезонност:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }
    //Premahni sezonnost ot djantite
    if (
      selName == "Джанти" &&
      this.state.extraFields.findIndex(x => x.name == "season") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "season");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //   Sezonnost End
    //**************** */

    //**************** */
    //   Width End
    //**************** */
    if (
      (selName == "Гуми" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "width_a") == -1
    ) {
      let ef = this.state.extraFields;
      let data = this.state.width.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "width_a",
        selected: { id: "", name: "" },
        title: "Ширина:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Джанти" &&
      this.state.extraFields.findIndex(x => x.name == "width_a") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "width_a");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }

    //**************** */
    //   Width End
    //**************** */

    //**************** */
    //   Height
    //**************** */
    if (
      (selName == "Гуми" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "height") == -1
    ) {
      let ef = this.state.extraFields;
      let data = this.state.height.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "height",
        selected: { id: "", name: "" },
        title: "Височина:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Джанти" &&
      this.state.extraFields.findIndex(x => x.name == "height") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "height");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //   Height ENd
    //**************** */

    //**************** */
    //   djanti_marka
    //**************** */
    if (
      (selName == "Гуми" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "djanti_marka") == -1
    ) {
      let ef = this.state.extraFields;
      let data = this.state.wheelsBrands.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "djanti_marka",
        selected: { id: "", name: "" },
        title: "Гуми Марка:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Джанти" &&
      this.state.extraFields.findIndex(x => x.name == "djanti_marka") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(
        x => x.name == "djanti_marka"
      );
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //   djanti_marka ENd
    //**************** */

    //**************** */
    //   Diameter
    //**************** */
    if (this.state.extraFields.findIndex(x => x.name == "diameter") == -1) {
      let ef = this.state.extraFields;
      let data = this.state.diameter.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "diameter",
        selected: { id: "", name: "" },
        title: "Диаметър:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //   Diameter End
    //**************** */

    //**************** */
    //   Material
    //**************** */
    if (
      (selName == "Джанти" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "material") == -1
    ) {
      let ef = this.state.extraFields;
      let data = this.state.material.map(r => ({ id: r, name: r }));
      let seasons = {
        data: data,
        isTextField: false,
        multiple: false,
        name: "material",
        selected: { id: "", name: "" },
        title: "Материал:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Гуми" &&
      this.state.extraFields.findIndex(x => x.name == "material") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "material");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
    //**************** */
    //  End Material
    //**************** */
    if (
      (selName == "Джанти" || selName == "Гуми и джанти") &&
      this.state.extraFields.findIndex(x => x.name == "markaavto") == -1
    ) {
      let ef = this.state.extraFields;
      let seasons = {
        data: this.state.markaavto,
        isTextField: false,
        multiple: false,
        name: "markaavto",
        link: "get-brand-models",
        linked: "modelavto",
        linkedTitle: "Модел автомобил:",
        selected: { id: "", name: "" },
        title: "Марка автомобил:"
      };
      ef.push(seasons);
      this.setState({ extraFields: ef });
    }

    if (
      selName == "Гуми" &&
      this.state.extraFields.findIndex(x => x.name == "markaavto") != -1
    ) {
      let ef = this.state.extraFields;
      let index = this.state.extraFields.findIndex(x => x.name == "markaavto");
      ef.splice(index, 1);
      this.setState({ extraFields: ef });
    }
  }

  //printExtraFields
  printExtraFields() {
    if (this.state.extraFields.length == 0) {
      return "";
    }

    return this.state.extraFields.map((field, i) => {
      console.log(this.state.selectedExtras);
      if (field.isTextField == true) {
        return (
          <li className="list-group-item" key={i}>
            <Row>
              <Col xs="12">{field.title}</Col>
            </Row>
            <Row>
              <Col xs="12">
                <input
                  // value={this.state.value}
                  key="fieldName"
                  onChange={this.handleChange}
                  name={field.name}
                  type="number" //todo: validation
                  pattern="\d*"
                  className="form-control"
                  required
                />
              </Col>
            </Row>
          </li>
        );
      }

      if (field.multiple == true) {
        return (
          <li
            key={i}
            onClick={() => this.extraFieldsClick.bind(this)(field)}
            className="list-group-item rightArrow"
          >
            <Row>
              <Col xs="12">{field.title}</Col>
            </Row>
            <Row>
              <Col xs="12">
                <input
                  value={this.state.selectedExtras.map(item => item)}
                  name="filter[extras][]"
                  type="text"
                  className="form-control"
                  readOnly
                />
              </Col>
            </Row>
          </li>
        );
      }

      if (
        this.state.selectedCategory === 24 &&
        (field.selected.name == "Гуми" ||
          field.selected.name == "Гуми и джанти" ||
          field.selected.name == "Джанти")
      ) {
        this.setGumiIDjantiExtraFields(field.selected.name);
      }

      //v samoto pole kogato e izbrano imame selected
      return (
        <div>
          <li
            key={i}
            onClick={() => this.extraFieldsClick.bind(this)(field)}
            className="list-group-item rightArrow"
          >
            <Row>
              <Col xs="12">{field.title} </Col>
            </Row>
            <Row>
              <Col xs="12">
                <input
                  value={field.selected.name}
                  name="title"
                  type="text"
                  className="form-control"
                  readOnly
                />
              </Col>
            </Row>
          </li>
        </div>
      );
    });
  }

  fetchListings() {
    this.setState({ isLoading: true });
    const { priceFrom, priceTo, sortByPriceAsc, sortByPriceDesc, sortByDate, name, allPersons, cityId, villageId } = this.state;
    let data = { priceFrom, priceTo, is_api: 1, page: this.state.currentPage };
    //SORT
    if (!sortByDate) {
      if (sortByPriceAsc) {
        data.op = 'l';
      }
      else {
        data.op = 'r';
      }
    }
    //Emergency
    if (this.state.isEmergency) {
      data.isEmergency = 1;
    }

    if (this.state.hasPhoto) {
      data.hasPhoto = 1;
    }

    if (name.length > 0) {
      data.name = name;
    }

    if (!allPersons) {
      data.pp = 1;
    }

    if (cityId > -1) {
      data.city_id = cityId;
    }

    if (villageId > -1) {
      data.village_id = villageId;
    }

    //EXTRA FIELDS
    if (this.state.extraFields.length > 0) {
      //sustoqnieto e otdelno
      const isNew =
        this.state.extraFields.find(x => x.name == "isNew") !== undefined
          ? this.state.extraFields.find(x => x.name == "isNew").selected.id
          : null;
      data.isNew = isNew;
      let filter = [];
      const fields = this.state.extraFields;
      for (let i = 0; i < fields.length; i++) {
        let fieldName = fields[i].name;
        let selectedValue = fields[i].selected.id;
        if (
          fieldName != "extras" &&
          fieldName != "mileage" &&
          (selectedValue == undefined || selectedValue == "")
        ) {
          continue;
        }

        if (fieldName == 'yf' || fieldName == 'yt') {
          data[fieldName] = selectedValue;
          continue;
        }

        if (
          fieldName != "isNew" &&
          fieldName != "extras" &&
          selectedValue != undefined
        ) {
          let tmp = {};
          tmp[fieldName] = selectedValue;
          filter.push(tmp);
        } else if (fieldName === "extras") {
          let selectedExtras = this.state.selectedExtras;
          let tmp = {};
          tmp[fieldName] = selectedExtras;
          filter.push(tmp);
        }
      }
      console.log(filter);
      data.filter = filter;
    }
    data.category_id = this.state.selectedCategory;

    localStorage.setItem('filters_state', JSON.stringify(this.state));

    NetworkClient.post('listings', data)
      .then(response => {
        this.setState({ isFilters: false })
        this.setState({ listings: response.listings })
        this.setState({ isLoading: false })
        this.setState({ totalPages: response.totalPages })
        this.setState({ currentPage: response.currentPage })

      })
  }

  getParams() {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      category_id: searchParams.get('category_id') || '',
      isn: searchParams.get('isn') || '',
    };
  }

  toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({
      isOpenfilterDrawer: open,
      priceFields: false,
      isCategories: false,
      citiesFields: false,
      villagesFields: false,
      sortFields: false,
      podatelFields: false
    });
  };

  resetFieldsDrawer(open) {
    this.setState({
      isOpenfilterDrawer: open,
      priceFields: false,
      isCategories: false,
      citiesFields: false,
      villagesFields: false,
      sortFields: false,
      podatelFields: false,
      isExtras: false
    });
  }

  handlePriceFromChange(event) {
    let value = event.target.value;
    this.setState({ priceFromTemp: value });
  }
  handlePriceToChange(event) {
    let value = event.target.value;
    this.setState({ priceToTemp: value });
  }

  footerSearch() {
    return (
      <div
        className="p-2"
        style={{
          backgroundColor: "#ececec",
          borderTop: "2px solid #ddd",
          position: "fixed",
          bottom: 0,
          width: "100%",
          height: 57
        }}
      >
        <Button
          type="button"
          onClick={() => {
            this.setState({ currentPage: 1 }, () => {
              this.fetchListings();
            })
          }}
          className="btn btn-block text-white "
          style={{ background: "#3b6fb6", fontWeight: 700 }}
        >
          <Search />
          ТЪРСЕНЕ
        </Button>
      </div>
    );
  }

  footerDrawer() {
    return (
      <footer
        className="container p-2 mt-3 fixed-bottom"
        style={{
          background: "#ececec",
          zIndex: 2000,
          borderTop: "2px solid #ddd",
          bottom: 0
        }}
      >
        <Row>
          <Col xs="6">
            <Button
              // onClick={() => this.setState({ isOpenfilterDrawer: false })}
              onClick={this.toggleDrawer(false)}
              style={{
                color: "#3b6fb6",
                background: "white",
                fontWeight: 300,
                border: "1px solid #3b6fb6"
              }}
              size="lg"
              block
            >
              Отказ
            </Button>
          </Col>
          <Col xs="6">
            <Button
              onClick={this.transferTemporaryToPermanent}
              style={{ background: "#3b6fb6", fontWeight: 700 }}
              size="lg"
              block
            >
              ОК
            </Button>
          </Col>
        </Row>
      </footer>
    );
  }
  transferTemporaryToPermanent = () => {
    if (
      this.state.priceFromTemp.length > 0 ||
      this.state.priceToTemp.length > 0
    ) {
      this.setState({
        priceFrom: this.state.priceFromTemp,
        priceTo: this.state.priceToTemp,
        isOpenfilterDrawer: false
      });
    }
    console.log(this.state.isExtras);
    if (this.state.isExtras) {
      this.setState({
        selectedExtras: this.state.selectedExtrasTemp,
        isOpenfilterDrawer: false,
      });
    }


    this.resetFieldsDrawer(false);
  };

  getPrice() {
    let priceFrom = "";
    let priceTo = "";

    if (this.state.priceFrom > 0) {
      priceFrom = "от " + this.state.priceFrom + "лв ";
    }
    if (this.state.priceTo > 0) {
      priceTo = " до " + this.state.priceTo + "лв ";
    }

    return priceFrom + priceTo;
  }

  printFilters() {
    return (<Card className="pb-5">
      <ListGroupItem>
        <a onClick={() => {
          localStorage.removeItem('filters_state');
          let items = this.INITIAL_STATE;
          items.categories = this.state.categories;
          items.backCategories = [];
          items.shownCategories = this.state.categories.filter(x => x.parent == 0);
          items.cities = this.state.cities;
          this.setState(items);


        }}>Изчисти</a>
      </ListGroupItem>
      <ListGroupItem>
        <TextField
          placeholder="Търсене в PRODAVASH.BG"
          className="form-control"
          style={{
            border: "1px solid #3b6fb6",
            borderRadius: 5
            //   lineHeight: "1"
          }}
          value={this.state.name}
          onFocus={()=>{
            this.setState({isSearchOnFocus: true})
          }}
          onBlur={()=>{
            setTimeout(()=>{
              this.setState({isSearchOnFocus: false})
            }, 500);

          }}
          onChange={(event) => {
            let keyword = event.target.value;
            if(keyword.length >= 2)
            {
              let url = Constants.BASE_URL_API + 'autocomplete-search?name='+keyword;
              fetch(url)
                  .then((response) => response.json())
                  .then((json) => {
                     let suggestedCategories = json.categories;
                     let suggestedKeywords = json.suggested;
                     this.setState({suggestedCategories, suggestedKeywords, suggestedKeyword: keyword});
                  })
            }
            this.setState({ name: keyword })
          }}
          type="text"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
      </ListGroupItem>
      {this.state.isSearchOnFocus && this.state.suggestedCategories.map(c =><ListGroupItem>
        <Row onClick={()=>{
          this.setState({ isLoading: true }, ()=>{
            let categoryId = c.category_id;
            NetworkClient.get('get-used-categories/' + categoryId)
                .then(r => {
                  let selectedCategoriesInOrder = r.categories;
                  for (let i = 0; i < selectedCategoriesInOrder.length; i++) {
                    let cat = selectedCategoriesInOrder[i];
                    this.changeShownCategories(cat.cat, cat.parent);
                  }
                  this.setState({ isLoading: false, isFilters: false }, () => {
                    this.fetchListings();
                  })
            })
          });


        }}>
          <Col xs={"12"} style={{fontSize: 14, color: '#3b6fb6', fontWeight:700}}>
            {this.state.suggestedKeyword}
          </Col>
          <Col xs={"12"} style={{fontSize: 14, color: '#bbb'}}>
            {c.category_name.substr(0, 37)}...
          </Col>
        </Row>
      </ListGroupItem>)}
      {this.state.isSearchOnFocus && this.state.suggestedKeywords.map(c =><ListGroupItem>
        <Row onClick={()=>{
          this.setState({name: c}, ()=>{
            this.fetchListings();
          })
        }}>
          <Col xs={"12"} style={{fontSize: 14, color: 'black', fontWeight:700}}>
            {c}
          </Col>
        </Row>
      </ListGroupItem>)}
      <li
        className="list-group-item rightArrow"
        onClick={() => {
          this.setState({ isOpenfilterDrawer: true });
          this.setState({ isCategories: true });
        }}
      >
        <Row>
          <Col xs="12">Рубрика</Col>
        </Row>
        {this.state.selectedCategory !== -1 && (
          <Row>
            <Col xs="12">
              <input
                type="text"
                value={
                  this.state.categories[
                    this.state.categories.findIndex(
                      x => x.id == this.state.selectedCategory
                    )
                  ].title
                }
                readOnly={true}
                className={"form-control"}
              />
            </Col>
          </Row>
        )}
      </li>

      {this.printExtraFields()}

      <ListGroupItem onClick={() =>
        this.setState({
          priceFields: true
        }, () => {
          this.setState({ isOpenfilterDrawer: true })
        })
      } className={this.state.priceFrom ? "" : "rightArrow"}>
        <Label>Цена</Label>
        <Row>
          <Col
            xs={10}
          >
            <input
              type="text"
              className="form-control"
              // value={this.state.priceFrom + " - " + this.state.priceTo}
              value={this.getPrice()}
              readOnly
            />
          </Col>
          <Col>
            {(this.state.priceFrom || this.state.priceTo) && (
              <HighlightOff
                onClick={() =>
                  this.setState({ priceFrom: "", priceTo: "" })
                }
                style={{ marginRight: 0 }}
              />
            )}
          </Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem onClick={() =>
        this.setState({
          isOpenfilterDrawer: true
        },() =>
        {
         // alert('ff');
          this.setState({ citiesFields: true});
        })
      } className={!this.state.city && "rightArrow"}>
        <Label>Населено място</Label>
        <Row>
          <Col
            xs={10}
          >
            <input
              name="cities"
              value={this.state.city}
              type="text"
              className="form-control"
              readOnly
            />
          </Col>
          <Col>
            {this.state.city && (
              <HighlightOff
                onClick={() =>
                  this.setState({
                    city: "",
                    cityId: -1,
                    villageId: -1,
                    village: "",
                    villages: []
                  })
                }
                style={{ marginRight: 0 }}
              />
            )}
          </Col>
        </Row>
      </ListGroupItem>
      {this.state.villages.length > 0 && (
        <ListGroupItem onClick={() =>
          this.setState({
            isOpenfilterDrawer: true,
            villagesFields: true
          })
        }
          xs={10} className={!this.state.village && "rightArrow"}>
          <Label> Град/Село</Label>
          <Row>
            <Col

            >
              <input
                name="village"
                value={this.state.village}
                type="text"
                className="form-control"
                readOnly
              />
            </Col>
            {/* <Col>
              {this.state.village && (
                <HighlightOff
                  onClick={() => this.setState({ village: "" })}
                  style={{ marginRight: 0 }}
                />
              )}
            </Col> */}
          </Row>
        </ListGroupItem>
      )}

      <ListGroupItem
        className="rightArrow"
        onClick={() =>
          this.setState({
            isOpenfilterDrawer: true,
            sortFields: true
          })
        }
      >
        <Label>Сортиране</Label>
        <Row>
          <Col>
            <input
              type="text"
              name="sortBy"
              value={this.state.sortBy}
              readOnly
              className="form-control"
            />
          </Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem className="rightArrow">
        <Label>Тип подател</Label>
        <Row>
          <Col
            onClick={() =>
              this.setState({
                isOpenfilterDrawer: true,
                podatelFields: true
              })
            }
          >
            <input
              value={this.state.podatel}
              type="text"
              className="form-control"
              readOnly
            ></input>
          </Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <label className="privateFaceContainer">
          Само обяви тип "Спешно"
          <input
            type="checkbox"
            onChange={() => { this.setState({ isEmergency: !this.state.isEmergency }) }}
            checked={this.state.isEmergency}
          />
          <span className="checkmark" />
        </label>

        <label className="privateFaceContainer">
          Само обяви със снимка
          <input
            type="checkbox"
            onChange={() => { this.setState({ hasPhoto: !this.state.hasPhoto }) }}
            checked={this.state.hasPhoto}
          />
          <span className="checkmark" />
        </label>
      </ListGroupItem>
    </Card>);
  }

  printHeader() {
    return (<div
      style={{
        top: 0,
        width: "100%",
        zIndex: "2000",
        position: "fixed",
        height: "50px",
        backgroundColor: "white",
        color: "#3b6fb6",
        boxShadow: "0 1px 4px rgba(0,0,0,.3)",
        fontSize: 27
      }}
    >
      <Row>
        <Col xs={2}>
          <GoBackArrow history={this.props.history} backPage={"/"} />
        </Col>
        <Col xs={8} className={"text-center pt-1"}>
          <span
            onClick={() => {
              this.props.history.push('/');
            }}
            style={{
              fontFamily: "Arial, cursive",
              color: "#F70",
              fontSize: "1.5rem",
              fontWeight: "800"
            }}
          >
            <span style={{ color: "#505ce2" }}>PRODAVASH</span>.BG
          </span>
        </Col>
        <Col xs={2}>
          {this.state.isFilters && (
            <Link to="/listing/create">
              <Add style={{ fontSize: 37, color: "tomato" }} />
            </Link>
          )}

          {!this.state.isFilters && (
            <a onClick={() => {
              this.initLoad(true);

            }}>
              <Search style={{ fontSize: 37, color: "tomato" }} />
            </a>
          )}
        </Col>
      </Row>
    </div>)
  }

  renderPagination() {
    return (<div>
      <Pagination
          currentPage={this.state.currentPage}
          totalPages={this.state.totalPages}
          nextButtonHandle={()=>{
            window.scrollTo(0, 0);
            this.setState({ currentPage: this.state.currentPage + 1 }, () => {
              this.fetchListings();
            })
          }}
          backButtonHandle={()=>{
            this.setState({ currentPage: this.state.currentPage - 1 }, () => {
              window.scrollTo(0, 0);
              this.fetchListings();
            })
          }}
          selectHandle={(p)=>{
            this.setState({currentPage: p},()=>{
              window.scrollTo(0, 0);
              this.fetchListings();
            })
          }}
      />
    </div>)
  }

  render() {
    return (
      <div className="create-listing" >
        {this.state.isLoading == true &&
          <div style={{ background: 'rgba(0,0,0,0.5)', position: 'absolute', height: '10000px', width: '100%', zIndex: 9999, textAlign: 'center', paddingTop: 150 }}>
            <i style={{ color: 'white' }} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </div>
        }
        {this.printHeader()}

        <Container
          style={{
            background: "#e1e1e1",
            paddingBottom: 20,
            paddingTop: 55
          }}
        >
          {this.state.isFilters && this.printFilters()}
          <AdSense.Google
            client='ca-pub-6108900312282823'
            slot='3028449836'
            style={{ display: 'block' }}
            format='auto'
            responsive='true'
          />
          {!this.state.isFilters && <ShowListings listings={this.state.listings} />}
          {!this.state.isFilters && this.renderPagination()}

        </Container>
        {this.state.isOpenfilterDrawer
          ? this.footerDrawer()
          : this.state.isFilters && this.footerSearch()}
        {this.filterDrawer()}
      </div>
    );
  }


  filterDrawer() {
    return (
      <Drawer
        classes={{
          // root: "foo__modal",
          paper: "categories-drawer"
        }}
        open={this.state.isOpenfilterDrawer}
        onClose={this.toggleDrawer(false)}
      >
        <Container style={{ background: "#e1e1e1", height: "100%" }}>
          {(this.state.citiesFields || this.state.villagesFields) && this.printCities()}
          {this.printDrawerData()}
          {this.state.isCategories === true && (
            <div className="card my-2">
              {this.printBackCategory()}
              {this.printCategories()}
              <ul className="list-group list-group-flush">
                <li
                  onClick={() => {
                    this.setState({ isOpenfilterDrawer: false });
                  }}
                  className="list-group-item"
                />
              </ul>
            </div>
          )}
          {
            this.state.isCategories === false &&
            (this.state.priceFields === true
              || this.state.isCategories == true || this.state.citiesFields == true ||
              this.state.villagesFields == true ||
              this.state.sortFields == true || this.state.podatelFields == true) &&
            <Card className="mt-2 p-2 ">
              {this.state.priceFields == true && this.printPriceFields()}
              {this.state.sortFields == true && this.printSortFields()}

              {/*this.state.citiesFields && this.printCities()*/}
              {/*this.state.villagesFields && this.printCities()*/}
              {this.state.podatelFields && this.printPodatelFields()}
            </Card>
          }
        </Container>
      </Drawer>
    );
  }

  setPodatel(event) {
    let podatel = event.target.value;

    this.setState({
      podatel: podatel,
      allPersons: false,
      privatePersons: false
    });

    if (podatel == "Всички") {
      this.setState({
        allPersons: true
      });
    } else if (podatel == "Частни") {
      this.setState({
        privatePersons: true
      });
    }
  }

  printPodatelFields() {
    return (
      <Row>
        <Col className="text-center">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              name="podatel"
              onClick={this.setPodatel.bind(this)}
              className="btn"
              type="button"
              style={this.state.allPersons ? style.active : style.default}
              value="Всички"
            >
              Всички
            </button>

            <button
              name="podatel"
              onClick={this.setPodatel.bind(this)}
              className="btn"
              type="button"
              style={this.state.privatePersons ? style.active : style.default}
              value="Частни"
            >
              Частни
            </button>
          </div>
        </Col>
      </Row>
    );
  }

  filterSortedBy = sort => event => {
    this.setState({
      sortByDate: false,
      sortByPriceAsc: false,
      sortByPriceDesc: false
    });

    if (sort == "sortByPriceAsc") {
      this.setState({
        sortBy: "Най-евтини"
      });
    } else if (sort == "sortByPriceDesc") {
      this.setState({
        sortBy: "Най-скъпи"
      });
    } else {
      this.setState({
        sortBy: "Дата"
      });
    }

    this.setState({
      [sort]: true
    });
  };

  printSortFields() {
    return (
      <Row>
        <Col className="text-center">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              className="btn"
              type="button"
              onClick={this.filterSortedBy.bind(this)("sortByDate")}
              style={this.state.sortByDate ? style.active : style.default}
            >
              Дата
            </button>
            <button
              className="btn"
              type="button"
              onClick={this.filterSortedBy.bind(this)("sortByPriceAsc")}
              style={this.state.sortByPriceAsc ? style.active : style.default}
            >
              Най-евтини
            </button>
            <button
              className="btn"
              type="button"
              onClick={this.filterSortedBy.bind(this)("sortByPriceDesc")}
              style={this.state.sortByPriceDesc ? style.active : style.default}
            >
              Най-скъпи
            </button>
          </div>
        </Col>
      </Row>
    );
  }
  //EXTRA FIELDS
  loadExtraFilters(categoryId) {
    //listings-filters
    NetworkClient.get("listings-filters/" + categoryId).then(response => {
      this.setState({ extraFields: response.filters });
      this.setState({ selectedExtras: [] });
      this.setState({ selectedExtrasTemp: [] });
    });
  }

  extraFieldsClick(field) {
    if (field.multiple == true && this.state.selectedExtras.length > 0) {
      const { selectedExtras } = this.state;
      this.setState({ selectedExtrasTemp: selectedExtras });
    }

    this.setState({ isExtras: true });
    this.setState({ isCategories: false });
    this.setState({ isOpenfilterDrawer: true });
    this.setState({
      drawerData: {
        data: field.data,
        name: field.name,
        multiple: field.multiple,
        selectedField: field
      }
    });
  }

  setSelectedExtra = field => {
    let arr = this.state.extraFields;
    let index = -1;
    const drawerName = this.state.drawerData.name;
    let drawerIsOpen = false;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name == drawerName) {
        index = i;
        break;
      }
    }

    if (index != -1) {
      arr[index].selected = field;
      if (arr[index].linked && arr[index].link) {
        NetworkClient.post(arr[index].link, { brand_id: field.id }).then(
          data => {
            const linkedName = arr[index].linked;
            let indx = -1;
            for (let j = 0; j < arr.length; j++) {
              if (arr[j].name == linkedName) {
                indx = j;
                break;
              }
            }

            if (indx != -1) {
              arr[indx].data = data;
              arr[indx].selected = { id: "", name: "" };
            }
          }
        );
      }
    }

    this.setState({ extraFields: arr, isOpenfilterDrawer: drawerIsOpen });
  };

  setMultipleFilterExtra = field => {
    //remove element from state
    let index = this.state.selectedExtrasTemp.indexOf(field.name);

    if (index > -1) {
      let arr = [...this.state.selectedExtrasTemp];
      arr.splice(index, 1);
      this.setState({ selectedExtrasTemp: arr });
    }
    // add element to state array
    else {
      this.setState({
        selectedExtrasTemp: [...this.state.selectedExtrasTemp, field.name]
      });
    }
  };

  printDrawerData() {
    const { priceFields, isCategories, citiesFields, villagesFields, sortFields, podatelFields } = this.state;
    let check = priceFields == false
      && isCategories == false && citiesFields == false &&
      villagesFields == false &&
      sortFields == false && podatelFields == false;
    /*let check =
    let priceFields: false,
    isCategories: false,
    citiesFields: false,
    villagesFields: false,
    sortFields: false,
    podatelFields: false,*/

    if (
      check && this.state.drawerData.data != undefined && this.state.drawerData.data.length != undefined &&
      this.state.drawerData.data.length > 0
    ) {
      return (
        <div className="card drawerPadding">
          <ul className="list-group list-group-flush">
            {this.state.drawerData.data.map((field, i) => (
              <li
                key={i}
                className="list-group-item"
                onClick={() => {
                  if (!this.state.drawerData.multiple) {
                    this.setSelectedExtra(field); //.bind(this)
                  } else {
                    this.setMultipleFilterExtra(field); //.bind(this)
                  }

                  if (
                    this.state.drawerData.selectedField.link != undefined &&
                    this.state.drawerData.selectedField.link.length > 0
                  ) {
                    const title = this.state.drawerData.selectedField.title;
                    const name = this.state.drawerData.selectedField.linked;
                    const url = this.state.drawerData.selectedField.link;
                    const linkedTitle = this.state.drawerData.selectedField
                      .linkedTitle;
                    NetworkClient.post(url, { brand_id: field.id }).then(
                      response => {
                        //title
                        //extraFields
                        let fields = this.state.extraFields;
                        let isExists = fields.findIndex(
                          x => x.title == linkedTitle
                        );
                        let hasItems = response.length != undefined;
                        if (isExists != -1 && !hasItems) {
                          fields.splice(isExists, 1);
                        }

                        let f = {
                          data: response,
                          multiple: false,
                          name: name,
                          selected: { id: "", name: "" },
                          title: linkedTitle
                        };

                        if (isExists == -1 && hasItems) {
                          let index = fields.findIndex(x => x.title == title);
                          fields.splice(index + 1, 0, f);
                        } else if (hasItems && isExists >= 0) {
                          fields[isExists] = f;
                        }

                        this.setState({ extraFields: fields });
                      }
                    );
                  }
                }}
              >
                {this.state.drawerData.selectedField.selected.id.length > 0 &&
                  this.state.drawerData.selectedField.selected.id == field.id}
                {(this.state.selectedExtrasTemp.indexOf(field.name) > -1 ||
                  (this.state.drawerData.selectedField.selected.id != "" &&
                    this.state.drawerData.selectedField.selected.id ==
                    field.id)) && (
                    <Check
                      className="float-right "
                      style={{ color: "rgb(255, 119, 0)" }}
                    />
                  )}
                {field.name}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return "";
  }
  //EXTRA FIELDS END


  ///CATEGORIES START
  changeShownCategories(categoryId, parent) {
    const { backCategories } = this.state;
    if (backCategories.filter(x => x == parent).length === 0) {
      backCategories.push(parent);
      this.setState({ backCategories });
    }

    let childCategories = this.state.categories.filter(
      x => x.parent == categoryId
    );
    if (childCategories.length === 0) {
      this.loadExtraFilters(categoryId);
      this.setState({ selectedCategory: categoryId });
      this.setState({ isOpenfilterDrawer: false });
      this.setState({
        priceFields: false,
        isCategories: false,
        citiesFields: false,
        villagesFields: false,
        sortFields: false,
        podatelFields: false
      });

      return;
    }
    else {
      this.setState({ extraFields: [] });
      this.setState({ selectedExtras: [] });
      this.setState({ selectedExtrasTemp: [] });
      this.setState({ selectedCategory: categoryId });

    }

    this.setState({ shownCategories: childCategories });
  }

  printCategories() {
    return this.state.shownCategories.map((c, i) => (
      <div
        onClick={() => {
          this.changeShownCategories(c.id, c.parent);
        }}
        key={i}
        style={Object.assign({}, styles.box, styles.bottomBorder, styles.blue)}
      >
        {c.icon && c.parent === 0 ? <i style={{ color: c.color }} className={c.icon} /> : ""}{" "}
        {c.title}{" "}
        {c.id === this.state.selectedCategory ? (
          <Check
            className="float-right "
            style={{ color: "rgb(255, 119, 0)" }}
          />
        ) : (
            ""
          )}
      </div>
    ));
  }

  printSelectedCategoryDrawer() {

    if (this.state.selectedCategory !== -1) {
      let c = this.state.categories[
        this.state.categories.findIndex(
          x => x.id == this.state.selectedCategory
        )
      ];
      return (
        <ListGroupItem>
          <span style={{ fontWeight: 700 }}> {c.icon && c.parent === 0 ? <i style={{ color: c.color }} className={c.icon} /> : ""}{" "} {c.title} </span>
        </ListGroupItem>
      )
    }

    return <div></div>;
  }

  printBackCategory() {
    if (this.state.backCategories.length > 0) {
      return (
        <div>
          {this.printSelectedCategoryDrawer()}
          <ListGroupItem
            style={Object.assign(
              {},
              styles.box,
              styles.bottomBorder,
              styles.blue
            )}
            onClick={() => {
              let { backCategories } = this.state;
              let parent = backCategories.pop();
              this.setState({ backCategories: backCategories });
              this.setState({
                shownCategories: this.state.categories.filter(
                  x => x.parent == parent
                )
              });

              if (this.state.categories[0].parent !== parent) {
                parent = backCategories.pop();
                this.setState({ backCategories: backCategories });
                this.setState({
                  shownCategories: this.state.categories.filter(
                    x => x.parent == parent
                  )
                });
              }
            }}
          >
            <KeyboardArrowLeft /> Назад
        </ListGroupItem>
        </div>
      );
    }

    return <div />;
  }

  //CATEGORIES END

  scrollToElementLogic() {
    //SCROLL TO
    setTimeout(function () {
      let id = Cookies.get('listing_scroll_to_id');
      let elmnt = document.getElementById(id);
      if (elmnt) {
        elmnt.scrollIntoView();
        window.scrollBy(0, -100);
      }
    }, 500);
  }

  initLoad(isFilters = false) {
    NetworkClient.get("listing").then(response => {
      let categoryId = this.getParams().category_id;
      let isn = this.getParams().isn;
      let filters_state = localStorage.getItem('filters_state');
      let listingBack = undefined;
      if (this.props.location.state !== undefined && this.props.location.state.loadListings !== undefined) {
        listingBack = this.props.location.state.loadListings;
      }

      if (listingBack !== undefined && listingBack == 1 && isFilters == false) {
        let fs = JSON.parse(filters_state);
        if (fs != null) {

          fs.isFilters = true;
        }

        this.setState(fs, () => { this.fetchListings(); });
        //SCROLL TO
        this.scrollToElementLogic();
        return;
      }

      let fs = JSON.parse(filters_state);
      console.log(fs);
      if (filters_state != null && fs.categories.length > 0 && filters_state.length > 0 && isn == '' && categoryId == '') {
        fs.isFilters = true;
        this.setState(fs);
      }
      else {

        if (isFilters == true) {
          this.setState({ isFilters: true });
          return;
        }

        if (categoryId != '') {
          new URLSearchParams(window.location.search).delete('category_id');
          localStorage.removeItem('filters_state');
          this.setState({ categories: response.categories, isLoading: true });
          this.setState({
            shownCategories: this.state.categories.filter(x => x.parent == 0)
          }, () => {
            NetworkClient.get('get-used-categories/' + categoryId)
              .then(r => {
                let selectedCategoriesInOrder = r.categories;
                for (let i = 0; i < selectedCategoriesInOrder.length; i++) {
                  let cat = selectedCategoriesInOrder[i];
                  this.changeShownCategories(cat.cat, cat.parent);
                }
                this.setState({ isLoading: false, isFilters: false }, () => {
                  this.fetchListings();
                })
              })
          });

          this.setState({ cities: response.cities });
        }
        else if (isn != '') {
          this.fetchListings();
          localStorage.removeItem('filters_state');
          this.setState({ isFilters: false });
          this.setState({ categories: response.categories });
          this.setState({
            shownCategories: this.state.categories.filter(x => x.parent == 0)
          });
          this.setState({ cities: response.cities });

        }
        else {
          this.setState({ categories: response.categories });
          this.setState({
            shownCategories: this.state.categories.filter(x => x.parent == 0)
          });
          this.setState({ cities: response.cities });
        }
      }
      //SCROLL TO
      this.scrollToElementLogic();
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log('COMPONENT DID MOUNT');
    this.initLoad();
  }
  //**************** */
  //**************** */
  //* Cities set and villages  */
  //**************** */
  //**************** */
  setCity = c => event => {
    this.setState({ villages: [] });
    if (c.name.length > 0) {
      NetworkClient.post("getVillages", { cityId: c.id }).then(data => {
        this.setState({ villages: data });
      });
    }

    this.setState({
      city: c.name,
      cityId: c.id,
      isOpenfilterDrawer: false,
      citiesFields: false,
      village: ""
    });
  };

  setVillage(v) {
    this.setState({
      village: v.name,
      villageId: v.id,
      isOpenfilterDrawer: false,
      villagesFields: false
    });
  }

  //**************** */
  //**************** */
  //* Cities DRAWER  */
  //**************** */
  //**************** */
  printCities() {
    return (
      <div className="card my-2">
        <ul className="list-group list-group-flush">
          {this.state.citiesFields &&
            this.state.cities.map(c => (
              <li
                name="city"
                onClick={this.setCity.bind(this)(c)}
                // onClick={() => this.setCityVillage(c)}
                className="list-group-item"
                key={c.id}
              >
                {c.name}
                {c.name == this.state.city && (
                  <Check
                    className="float-right "
                    style={{ color: "rgb(255, 119, 0)" }}
                  />
                )}
              </li>
            ))}

          {this.state.villagesFields &&
            this.state.villages.map(v => (
              <li
                name="village"
                onClick={() => this.setVillage(v)}
                // onClick={() => this.setCityVillage(c)}
                className="list-group-item"
                key={v.id}
              >
                {v.name}
                {v.name == this.state.village && (
                  <Check
                    className="float-right "
                    style={{ color: "rgb(255, 119, 0)" }}
                  />
                )}
              </li>
            ))}
        </ul>
      </div>
    );
  }

  printPriceFields() {
    return (
      <Row>
        <Col xs={6}>
          <Label>Цена от</Label>
          <input
            className="form-control"
            type="number"
            value={this.state.priceFromTemp}
            onChange={this.handlePriceFromChange.bind(this)}
          />
        </Col>

        <Col xs={6}>
          <Label>Цена до</Label>
          <input
            className="form-control"
            type="number"
            value={this.state.priceToTemp}
            onChange={this.handlePriceToChange.bind(this)}
          />
        </Col>
      </Row>
    );
  }
}

export default FiltersScreen;
