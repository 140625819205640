import React, { Component } from 'react'

export default class Loader extends Component {
    styles = {
        container: {
            width:'100%', 
            height: '100%', 
            position: 'absolute',
            background: 'black',
            opacity: 0.5,
            zIndex: 9999,
            textAlign: 'center',
            paddingTop: 150
        },
        color: {
            color: 'white'
        }
    }

  constructor(props) {
      super(props);
  }  

  render() {
    return (
      <div>
         {this.props.isLoading === true && 
         <div style={this.styles.container}>
            <i style={{ color: 'white' }} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
         </div>
         }
      </div>
    )
  }
}
