import React from "react";
import Constants from '../../constants/Constants';
import {
  Modal,
  Container,
  Card,
  Row,
  Col,
  Button,
  FormGroup,
  ListGroupItem
} from "reactstrap";
import Header from "../../components/ListingCreate/Header";
import {
  AirplanemodeActive,
  Sync,
  Star,
  NotificationImportant
} from "@material-ui/icons";
import "./PromoteListingScreen.css";
import Cookies from 'js-cookie';

const selected = {
  border: "2px solid #83a1cb ",
  boxShadow: "0 0 20px #999"
};

const colorSelected = {
  color: '#3b6fb6'
}

export default class PromoteListing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStatus: [0, 1, 0],
      selectedPromoOptions: [0, 0, 0, 0],
      promoStatus: 1,
      listingId: -1,
      paymentMethod: 'epay',
      step: 1,
      smsCodes: [null, null, null],
      smsCode: ''
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ listingId: id });
  }

  selected(promote) {
    this.setState({
      selectedPromo: true
    });
  }

  selectPromoPack(arr) {
    this.setState({ selectedStatus: arr });
    this.setState({ selectedPromoOptions: [0, 0, 0, 0] });
  }

  selectPromoOption(index) {
    let selPromoOptions = this.state.selectedPromoOptions;
    if (selPromoOptions[index] == 1) {
      selPromoOptions[index] = 0;
    }
    else {
      selPromoOptions[index] = 1;
    }

    this.setState({ selectedStatus: [0, 0, 0] });
    this.setState({ selectedPromoOptions: selPromoOptions });
  }

  totalSum() {

    if (this.state.selectedStatus[0] === 1) {
      return parseFloat(2.40).toFixed(2);
    }

    if (this.state.selectedStatus[1] === 1) {
      return parseFloat(3).toFixed(2);
    }

    if (this.state.selectedStatus[2] === 1) {
      return parseFloat(6).toFixed(2);
    }

    let price = 0;

    for (let i = 0; i < this.state.selectedPromoOptions.length; i++) {
      if (this.state.selectedPromoOptions[i] === 1) {
        price += 2.4;
      }
    }

    return parseFloat(price).toFixed(2);
  }

  printPromoPackages() {
    return (<div>
      <Row>
        <Col xs="12" style={{ fontWeight: 700 }}>
          <span
            className="badge text-white"
            style={{ background: "#f70", fontSize: 18, marginRight: 20 }}
          >
            1
                </span>
          Избери <span style={{ color: "#f70" }}>промо пакет:</span>
        </Col>
      </Row>
      <ListGroupItem className="mt-3"
        onClick={() => { this.selectPromoPack([1, 0, 0]) }}
        style={this.state.selectedStatus[0] === 1 ? selected : null}>
        <Row>
          <Col xs="8">
            <span
              style={this.state.selectedStatus[0] === 1 ? colorSelected : null}
              className="promo-title"  >Silver </span> /5 дни/ <br />
            <AirplanemodeActive className="promo-airplane" />
            <Sync className="promo-refresh" />
            <Star className="promo-star" style={{ color: "grey" }} />
          </Col>
          <Col xs="4" className="text-right">
            <p className="mb-0" style={{ fontSize: 22 }}>
              2.40 лв.
                    </p>
          </Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem className="mt-3" onClick={() => { this.selectPromoPack([0, 1, 0]) }} style={this.state.selectedStatus[1] === 1 ? selected : null}>
        <Row>
          <Col xs="8">
            <span style={this.state.selectedStatus[1] === 1 ? colorSelected : null} className="promo-title">Gold </span> /5 дни/ <br />
            <AirplanemodeActive className="promo-airplane" />
            <Sync className="promo-refresh" />
            <Star className="promo-star" />
          </Col>
          <Col xs="4" className="text-right">
            <p className="mb-0" style={{ fontSize: 22 }}>
              3.00 лв.
                  </p>
          </Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem className="mt-3" onClick={() => { this.selectPromoPack([0, 0, 1]) }} style={this.state.selectedStatus[2] === 1 ? selected : null}>
        <Row>
          <Col xs="8">
            <span style={this.state.selectedStatus[2] === 1 ? colorSelected : null} className="promo-title">Platinum </span> /15 дни/ <br />
            <AirplanemodeActive className="promo-airplane" />
            <Sync className="promo-refresh" />
            <Star className="promo-star" />
          </Col>
          <Col xs="4" className="text-right">
            <p className="mb-0" style={{ fontSize: 22 }}>
              6.00 лв.
                  </p>
          </Col>
        </Row>
      </ListGroupItem>
    </div>)
  }

  printPromoOptions() {
    return (<div>
      <Row className="mt-4">
        <Col xs="12" style={{ fontWeight: 700 }}>
          <span
            className="badge text-white"
            style={{ background: "#f70", fontSize: 18, marginRight: 20 }}
          >
            2
                </span>
          Или използвай единични{" "}
          <span style={{ color: "#f70" }}>промо опции</span> по избор:
              </Col>
      </Row>
      <ListGroupItem
        onClick={() => { this.selectPromoOption(0) }}
        style={Object.assign({}, { padding: 0, marginTop: 20 }, this.state.selectedPromoOptions[0] === 1 ? selected : null)}>
        <Row>
          <Col xs="2">
            <Star className="promo-star" />
          </Col>
          <Col xs="6">Първа страница за 10 дни!</Col>
          <Col xs="4" className="price">
            2.40 лв.
                </Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem
        style={this.state.selectedPromoOptions[1] === 1 ? selected : null}
        className="p-0 mt-2"
        onClick={() => { this.selectPromoOption(1) }}>
        <Row>
          <Col xs="2">
            <AirplanemodeActive className="promo-airplane" />
          </Col>
          <Col xs="6">Рекламен блок с TOP обяви - 10 дни</Col>
          <Col xs="4" className="price">
            2.40 лв.
                </Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem
        style={this.state.selectedPromoOptions[2] === 1 ? selected : null}
        className="p-0 mt-2"
        onClick={() => { this.selectPromoOption(2) }}>
        <Row>
          <Col xs="2">
            <Sync className="promo-refresh" style={{ margin: "unset" }} />
          </Col>
          <Col xs="6">Обновяване 5 броя</Col>
          <Col xs="4" className="price">
            2.40 лв.
                </Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem
        style={this.state.selectedPromoOptions[3] === 1 ? selected : null}
        className="p-0 mt-2" onClick={() => { this.selectPromoOption(3) }}>
        <Row>
          <Col xs="2">
            <NotificationImportant className="promo-speshno" />
          </Col>
          <Col xs="6">СПЕШНО! - 10 дни</Col>
          <Col xs="4" className="price">
            2.40 лв.
                </Col>
        </Row>
      </ListGroupItem>
    </div>)
  }

  selectPaymentMethod(method) {
    this.setState({ paymentMethod: method, smsCodes: [null, null, null], smsCode: '' })
  }

  handleChangeSMSCode(value) {
    let index = 0;
    if (this.promoStatus() == 3) {
      index = 1;
    }
    else if (this.promoStatus() == 4) {
      index = 2;
    }

    let smsCodes = [null, null, null];
    smsCodes[index] = value;
    this.setState({ smsCodes: smsCodes });
    this.setState({ smsCode: value });
  }

  printStep2SmsInfo() {
    let promoStatus = this.promoStatus();
    //SILVER
    if (promoStatus == 2) {
      return (<Card className={"mt-2 p-2"}>
        <div style={{ fontWeight: 700 }}>SMS Плащане</div>
        <p style={{ fontSize: 12, color: '#666' }}>При плащане с SMS цената е двойно по - висока, тъй като половината от сумата остава в оператора.</p>
        <p>
          Изпратете SMS на номер <b>1094</b> със съдържание <b>silver</b>
          <b>4.80</b> лв. с ДДС, за абонатите на M-tel, Vivacom и Telenor.
         <br /><b>След като изпратите SMS ще получите код който трябва да въведете в полето "Въведи SMS код", след което натиснете бутона ПЛАТИ.</b>
        </p>
      </Card>)
    }
    //GOLD
    if (promoStatus == 3) {
      return (<Card className={"mt-2 p-2"}>
        <div style={{ fontWeight: 700 }}>SMS Плащане</div>
        <p style={{ fontSize: 12, color: '#666' }}>При плащане с SMS цената е двойно по - висока, тъй като половината от сумата остава в оператора.</p>
        <p>
          Изпратете SMS на номер <b>1096</b> със съдържание <b>gold</b>  <b>6.00</b> лв. с ДДС, за абонатите на M-tel, Vivacom и Telenor.
      <br /><b>След като изпратите SMS ще получите код който трябва да въведете в полето "Въведи SMS код", след което натиснете бутона ПЛАТИ.</b>
        </p>
      </Card>)
    }
    //Platinum
    if (promoStatus == 4) {
      return (<Card className={"mt-2 p-2"}>
        <div style={{ fontWeight: 700 }}>SMS Плащане</div>
        <p style={{ fontSize: 12, color: '#666' }}>При плащане с SMS цената е двойно по - висока, тъй като половината от сумата остава в оператора.</p>
        <p>
          Необходимо е да изпратите <b>два</b> SMS на номер <b>1096</b> със съдържание <b>platinum</b>  <b>6.00</b> лв. с ДДС, за абонатите на M-tel, Vivacom и Telenor.
      <br /><b>След като изпратите SMS ще получите код който трябва да въведете в полето "Въведи SMS код", след което натиснете бутона ПЛАТИ.</b>
        </p>
      </Card>)
    }
  }

  printSmsPayment() {
    return (<div>
      <Card
        onClick={() => { this.selectPaymentMethod('sms') }}
        className={"mt-1 p-2"} style={this.state.paymentMethod === 'sms' ? selected : null}>
        <Row>
          <Col xs={3}>
            <img style={{ width: 60 }} className={"img-fluid"} src="https://prodavash.bg/img/sms.png" />
          </Col>
          <Col xs={8}>
            <span style={{ fontWeight: 700 }}>SMS</span>
          </Col>
        </Row>
      </Card>

      {this.state.paymentMethod === 'sms' && this.printStep2SmsInfo()}

      {this.state.paymentMethod === 'sms' &&
        <Card className={'mt-1'}>
          <input type="text" onChange={(event) => { this.handleChangeSMSCode(event.target.value) }} placeholder={'Въведи SMS код'} className={'form-control'} />
        </Card>
      }
    </div>)
  }

  printSelectedOptions() {

    let index = this.state.selectedStatus.findIndex(x => x === 1);
    if (index != -1) {
      if (index == 0) {
        return 'Пакет Silver';
      }
      else if (index == 1) {
        return 'Пакет Gold';
      }
      else if (index == 2) {
        return 'Пакет Platinum';
      }
    }

    if (index == -1) {
      let items = '';
      for (let i = 0; i < this.state.selectedPromoOptions.length; i++) {
        if (this.state.selectedPromoOptions[i] === 1) {
          items += (i + 1) + ',';
        }
      }

      return items;
    }
  }

  printBankInformation() {
    if (this.state.paymentMethod === 'bank') {
      return (
        <div className={"row"}>
          <div className={"col-md-12"}>
            <div style={{ fontWeight: 700 }}>
              За да платите с опцията банков превод е необходимо да направите превод със следните данни:
            </div>
            <div>Име на получателя: <span style={{ fontWeight: 700 }}>ДАИЛ ЕООД</span></div>
            <div>IBAN: <span style={{ fontWeight: 700 }}>BG50STSA93000025572270</span></div>
            <div>
              Основание: <span style={{ fontWeight: 700 }}>VIP #{this.state.listingId}-<span>{this.printSelectedOptions()}</span></span>
            </div>
          </div>
        </div>)
    }

    return (<div></div>);
  }

  printBankPayment() {
    return (
      <Card
        onClick={() => { this.selectPaymentMethod('bank') }}
        className={"mt-1 p-2"} style={this.state.paymentMethod === 'bank' ? selected : null}>
        <Row>
          <Col xs={3}>
            <img style={{ width: 60 }} className={"img-fluid"} src="https://prodavash.bg/images/payment-bank.jpg" />
          </Col>
          <Col xs={8}>
            <span style={{ fontWeight: 700 }}>С банков превод</span>
          </Col>
        </Row>
        {this.state.paymentMethod === 'bank' && this.printBankInformation()}
      </Card>
    );
  }

  printStep2PaymentMethods() {
    return (
      <div>
        <Card
          onClick={() => { this.selectPaymentMethod('epay') }}
          className={"mt-1 p-2"} style={this.state.paymentMethod === 'epay' ? selected : null}>
          <Row>
            <Col xs={3}>
              <img style={{ width: 60 }} className={"img-fluid"} src="https://prodavash.bg/images/payment-easypay.jpg" />
            </Col>
            <Col xs={8}>
              <span style={{ fontWeight: 700 }}>ePay.bg / EasyPay</span>
            </Col>
          </Row>
        </Card>
        <Card
          onClick={() => { this.selectPaymentMethod('credit-card') }}
          className={"mt-1 p-2"} style={this.state.paymentMethod === 'credit-card' ? selected : null}>
          <Row>
            <Col xs={3}>
              <img style={{ width: 60 }} className={"img-fluid"} src="https://prodavash.bg/images/payment-cards.jpg" />
            </Col>
            <Col xs={8}>
              <span style={{ fontWeight: 700 }}>Visa, MasterCard</span>
            </Col>
          </Row>
        </Card>
        {this.state.selectedStatus.findIndex(x => x == 1) != -1 && this.printSmsPayment()}
        {this.printBankPayment()}

      </div>
    )
  }

  printStep2() {
    return (
      <Row>
        <Col xs="12" style={{ fontWeight: 700 }}>
          <span
            className="badge text-white"
            style={{ background: "#3b6fb6", fontSize: 18, marginRight: 20 }}
          >
            1
                </span>
          Сума <span style={{ color: "#f70" }}>{this.totalSum()} лв.</span>
        </Col>

        <Col xs="12 mt-1 mb-1">
          <a href="#" onClick={(event) => { event.preventDefault(); this.setState({ step: 1 }) }}>Избери друг пакет</a>
        </Col>

        <Col xs="12" style={{ fontWeight: 700 }}>
          <span
            className="badge text-white"
            style={{ background: "#f70", fontSize: 18, marginRight: 20 }}
          >
            2
                </span>
          Избери начин на плащане:
        </Col>
        <Col xs={12}>
          {this.printStep2PaymentMethods()}
        </Col>
      </Row>
    )
  }

  promoStatus() {
    let promoStatus = 1;
    let hasPromStatus = this.state.selectedStatus.findIndex(x => x === 1);
    if (hasPromStatus > -1) {
      promoStatus = hasPromStatus + 2;
    }
    return promoStatus;
  }

  handleSubmit = event => {
    let { step } = this.state;

    if (step == 1) {
      event.preventDefault();
      this.setState({ step: 2 });
    }

    if (step == 2) {
      //Check sms
      if (this.state.paymentMethod === 'sms' && this.state.smsCode.length == 0) {
        alert('Моля въведете SMS код преди да продължите!');
        event.preventDefault();
        return;
      }

      //За смс код трябва да се направи обикновен request защото няма как да покажем грешен код иначе...
      if (this.state.paymentMethod === 'sms' && this.state.smsCode.length != 0) {
        let ps = this.promoStatus();
        let url = Constants.BASE_URL_API + 'listing-promote/' + this.state.listingId;
        let api_token = Cookies.get('api_token');
        let data = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ is_api: 1, promo_status: ps, api_token: api_token })
        };

        fetch(url, data)
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.error == 'Грешен код!') {
              alert('Грешен SMS код!');
            }

            if (responseJson.success == true) {
              this.props.history.push('/success-payment')
            }
          })

        return;
      }

      if (this.state.paymentMethod === 'bank') {
        event.preventDefault();

        return;
      }
    }
  }

  render() {
    return (
      <div>
        <Header history={this.props.history} backPage="/my-listings" />
        <Container
          style={{
            background: "#e1e1e1",
            height: "100%",
            paddingBottom: 20,
            paddingTop: 55
          }}
        >
          {this.state.step === 1 &&
            <Card className="py-4 px-2">
              {this.printPromoPackages()}
              {this.printPromoOptions()}
            </Card>
          }
          {this.state.step === 2 &&
            <Card className={"py-1 px-1"}>
              {this.printStep2()}
            </Card>}
          <form onSubmit={this.handleSubmit} action={Constants.BASE_URL_API + 'listing-promote/' + this.state.listingId} method={'POST'}>
            <input type="hidden" name="promo_status" value={this.promoStatus()} />
            <input type="hidden" name="is_first_page" value={this.state.selectedPromoOptions[0] === 1 ? 1 : 0} />
            <input type="hidden" name="is_top_ads" value={this.state.selectedPromoOptions[1] === 1 ? 1 : 0} />
            <input type="hidden" name="remaining_refresh" value={this.state.selectedPromoOptions[2] === 1 ? 1 : 0} />
            <input type="hidden" name="is_urgent" value={this.state.selectedPromoOptions[3] === 1 ? 1 : 0} />
            <input type="hidden" name="api_token" value={Cookies.get('api_token')} />
            <input type="hidden" name="paymentMethod" value={this.state.paymentMethod} />
            <input type="hidden" name="sms-code[]" value={this.state.smsCodes[0]} />
            <input type="hidden" name="sms-code[]" value={this.state.smsCodes[1]} />
            <input type="hidden" name="sms-code[]" value={this.state.smsCodes[2]} />
            <input type="hidden" name="is_api" value={1} />
            <input type="hidden" name="api_slug" value={this.state.listingId} />

            <Button
              className="btn-block mt-3"
              type={'submit'}
              style={{ background: "#3b6fb6", fontWeight: 700 }}
            >
              ПЛАТИ{" "}
              <span style={{ float: "right", fontWeight: "normal" }}>
                {this.totalSum()} лв.
            </span>
            </Button>
          </form>
          <Button
            style={{
              color: "#3b6fb6",
              background: "white",
              border: "1px solid #3b6fb6"
            }}
            className="btn-block mt-2"
            onClick={() => {
              if (this.state.step == 1) {
                this.props.history.push('/my-listings');
              }
              this.setState({ step: 1 });
            }}
          >
            Не промотирай
          </Button>

        </Container>
      </div>
    );
  }
}
