import React from "react";
import BackArrow from "../../../components/GoBackArrow";
import { TuneRounded, MessageOutlined, Check } from "@material-ui/icons/";
import {
  Container,
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Collapse
} from "reactstrap";
import SingleMessageItem from "./SingleMessageItem";
import NetworkClient from "../../../api/NetworkClient";
import "./MessageScreen.css";
import Cookies from "js-cookie";

const styles = {
  activeButton: {
    width: "49%",
    backgroundColor: "#3b6fb6"
  },
  defaultButton: {
    borderRadius: "0px 5px 5px 0px",
    width: "49%",
    border: "1px solid #3b6fb6",
    background: "white",
    color: "#3b6fb6"
  },
  leftBtnBorder: {
    borderRadius: "5px 0px 0px 5px"
  },
  rightBtnBorder: {
    borderRadius: "0px 5px 5px 0px"
  }
};
const COUNT = 0;
export default class MessageScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // show: false,
      collapse: false,
      isArchived: false,
      isReceived: false,
      isSent: false,
      isUnread: false,
      isAll: true,
      allConversations: [],
      AllArchived: []
    };
  }

  getParams() {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      archived: searchParams.get('archived') || '',
    };
  }

  componentDidMount() {
    const isLogged = Cookies.get("api_token");
    if (!isLogged) {
      this.props.history.push("/auth");
    } else {
      window.scrollTo(0, 0);
      NetworkClient.get("/user/messages").then(data => {
        this.setState({ allConversations: data });
      });
      NetworkClient.get("/user/messages/archive").then(data1 => {
        this.setState({ AllArchived: data1 }, () => {
          let params = this.getParams().archived;
          if (params != '') {
            this.setState({ isArchived: true });
          }
        });
      });
    }
  }
  defaultState() {
    this.setState({
      isAll: false,
      isArchived: false,
      isReceived: false,
      isSent: false,
      isUnread: false,
      collapse: false
    });
  }

  showMsgMenu() {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  }
  // static contextTypes = {
  //   router: () => true // replace with PropTypes.object if you use them
  // };
  showTop() {
    return (
      <div
        //   style={{  }}
        style={{
          zIndex: "999",
          width: "100%",
          height: "50px",
          backgroundColor: "white",
          color: "#3b6fb6",
          boxShadow: "0 1px 4px rgba(0,0,0,.3)",
          inHeight: "50px",
          paddingTop: "9px"
        }}
      >
        <BackArrow history={this.props.history} backPage={"/my-profile"} />

        <div
          style={{
            left: "50%",
            position: "absolute",
            display: "inline",
            textAlign: "center",
            transform: "translateX(-50%)",
            WebkitTransform: "translateX(-50%)",
            msTransform: "translateX(-50%)",
            lineHeight: "34px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: "18px"
          }}
        >
          <MessageOutlined fontSize="large" className="mr-2" />
          Съобщения
        </div>
        {
          this.state.isArchived === false &&
          <TuneRounded
            onClick={this.showMsgMenu.bind(this)}
            fontSize="large"
            style={{ float: "right", margin: "5px" }}
          />
        }
      </div>
    );
  }

  printAllConversations() {
    return this.state.allConversations.map((c, i) => (
      <SingleMessageItem
        key={i}
        id={c.id}
        name={c.name}
        count={c.msg_count}
        replay={c.last_msg}
        isSeen={c.is_seen}
        created={c.last_msg_date}
      />
    ));
  }

  printArchiveMsg() {
    return this.state.AllArchived.map((c, i) => (
      <SingleMessageItem
        key={i}
        id={c.id}
        name={c.name}
        count={c.msg_count}
        replay={c.last_msg}
        isSeen={c.is_seen}
        created={c.last_msg_date}
        isArchived={true}
      />
    ));
  }

  printReceivedMessage() {
    return this.state.allConversations.map((c, i) => {
      // polucheni
      if (c.is_sender != 1) {
        return (
          <SingleMessageItem
            key={i}
            id={c.id}
            name={c.name}
            count={c.msg_count}
            replay={c.last_msg}
            isSeen={c.is_seen}
            created={c.last_msg_date}
          />
        );
      }
    });
  }

  printSentMessages() {
    return this.state.allConversations.map((c, i) => {
      // izprateni
      if (c.is_sender == 1) {
        return (
          <SingleMessageItem
            key={i}
            id={c.id}
            name={c.name}
            count={c.msg_count}
            replay={c.last_msg}
            isSeen={c.is_seen}
            created={c.last_msg_date}
          />
        );
      }
    });
  }

  printUnread() {
    return this.state.allConversations.map((c, i) => {
      // neprocheteni
      if (c.is_seen != true) {
        return (
          <div>
            <SingleMessageItem
              key={i}
              id={c.id}
              name={c.name}
              count={c.msg_count}
              replay={c.last_msg}
              isSeen={c.is_seen}
              created={c.last_msg_date}
            />
          </div>
        );
      }
    });
  }

  filterConversation(items) {
    this.defaultState();

    this.setState({ [items]: true });
  }

  render() {
    return (
      <div>
        {this.showTop()}

        <Container
          className="mt-3"
          style={{ minHeight: "100vh", height: "100%" }}
        >
          {/* {this.state.show && <MessageMenu />} */}
          <Collapse isOpen={this.state.collapse}>
            <ListGroup className="mb-4">
              <ListGroupItem
                onClick={() => this.filterConversation("isAll")}
                className={this.state.isAll && "activeItem"}
              >
                Всички
                {this.state.isAll && <Check style={{ float: "right" }} />}
              </ListGroupItem>

              <ListGroupItem
                className={this.state.isReceived && "activeItem"}
                onClick={() => this.filterConversation("isReceived")}
              >
                Получени
                {this.state.isReceived && <Check style={{ float: "right" }} />}
              </ListGroupItem>

              <ListGroupItem
                className={this.state.isSent && "activeItem"}
                onClick={() => this.filterConversation("isSent")}
              >
                Изпратени
                {this.state.isSent && <Check style={{ float: "right" }} />}
              </ListGroupItem>

              <ListGroupItem
                className={this.state.isUnread && "activeItem"}
                onClick={() => this.filterConversation("isUnread")}
              >
                Непрочетени
                {this.state.isUnread && <Check style={{ float: "right" }} />}
              </ListGroupItem>
              {/* <ListGroupItem>Маркирани</ListGroupItem> */}
            </ListGroup>
          </Collapse>
          <Button
            // style={this.state.styleActiveBtn.isCurrentMsg.active}
            onClick={() => {
              this.filterConversation("isAll")
            }}
            style={
              !this.state.isArchived
                ? Object.assign({}, styles.activeButton, styles.leftBtnBorder)
                : Object.assign({}, styles.defaultButton, styles.leftBtnBorder)
            }
          >
            Текущи ({this.state.allConversations.length})
          </Button>
          <Button
            style={
              this.state.isArchived
                ? Object.assign({}, styles.activeButton, styles.rightBtnBorder)
                : Object.assign({}, styles.defaultButton, styles.rightBtnBorder)
            }
            onClick={() => {
              this.filterConversation("isArchived")
            }}
          >
            Архивирани ({this.state.AllArchived.length})
          </Button>

          {/* {!this.state.isArchived
            ? this.printAllConversations()
            : this.printArchiveMsg()} */}

          {this.state.isAll && this.printAllConversations()}
          {this.state.isArchived && this.printArchiveMsg()}
          {this.state.isSent && this.printSentMessages()}
          {this.state.isReceived && this.printReceivedMessage()}
          {this.state.isUnread && this.printUnread()}
        </Container>
      </div>
    );
  }
}
