import React from 'react'

import TopLayout from '../../layout/TopLayout';
import Cookies from "js-cookie";
import MainPageWrapper from "../../layout/MainPageWrapper";
import NetworkClient from '../../api/NetworkClient';

export default class FacebookLogin extends React.Component {
    constructor(props) {
        super(props);
        let token = this.props.location.search.substring(11);
        Cookies.set("api_token", token);
        NetworkClient.get('/user?api_token='+token)
        .then(response => {
            localStorage.setItem('mainPhoto', '/storage/images/'+ response.user.mainPhoto);
            localStorage.setItem("name", response.user.name);
            window.location.href = '/';
        });
    }

    render() {
        return <div>Loading...</div>
    }
}