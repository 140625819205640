import React from "react";
import { Badge } from "reactstrap";

const styles = {
  badge: {
    backgroundColor: "#3b6fb6",
    color: "white",
    padding: "5px",
    marginRight: "10px",
    fontWeight: "400"
    // borderRadius: "50%"
    // marginRight: "5px",
    // display: "inline-block"
  }
};
class NumberBadge extends React.Component {
  constructor(props){
    super(props);
  }
  render() {
    return (
      <div style={{ float: "right" }}>
        {/* <span style={styles.badge} className="badge">
          0
        </span> */}
        <Badge style={styles.badge} className="badge badge-pill">
          {this.props.count ? this.props.count : 0}
        </Badge>
      </div>
    );
  }
}

export default NumberBadge;
