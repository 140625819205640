import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Simple from "./app/simple/Simple";
import { Button, Container, Row, Col } from "reactstrap";
import Drawer from "@material-ui/core/Drawer";
import TopLayout from "./app/layout/TopLayout";
import SingleListing from "./app/partials/listings/SingleListing";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import HomeScreen from "./app/views/home/HomeScreen";
import AboutScreen from "./app/views/about/AboutScreen";
import DeleteScreen from "./app/views/delete/DeleteScreen";
import ListingPreviewScreen from "./app/views/listing/ListingPreviewScreen";
import MessagesScreen from "./app/views/user-profile/messages/MessageScreen";
import Conversation from "./app/views/user-profile/messages/ConversationScreen";
import ProfleMenuScreen from "./app/views/user-profile/ProfileMenuScreen";
import AuthScreen from "./app/views/auth/AuthScreen";
import CreateListing from "./app/views/listing/CreateListingScreen";
import EditListing from "./app/views/listing/edit/EditListingScreen";
import MyListings from "./app/views/user-profile/MyListingsScreen";
import PromoteListing from "./app/views/user-profile/PromoteListingScreen";
import SettingsScreen from "./app/views/user-profile/ProfileSettingsScreen";
import PasswordScreen from "./app/views/user-profile/PasswordScreen";
import UserProfileInfo from "./app/views/user-profile/ProfileInfoScreen";
import SuccessPaymentScreen from "./app/views/success-payment/SuccessPaymentScreen";
import FacebookLogin from "./app/components/FacebookLogin/FacebookLogin";
import RegisterScreen from "./app/views/auth/RegisterScreen";
import Filters from "./app/views/flters/FiltersScreen";
import ContactScreen from "./app/views/contacts/ContactsScreen";
import PasswordReset from "./app/views/auth/PasswordResetScreen";
import TermsScreen from "./app/views/terms/TermsScreen";
import PasswordResetTokenScreen from "./app/views/auth/PasswordResetTokenScreen";
import PublicProfileScreen from "./app/views/user-profile/PublicProfileScreen";

const styles = {
  topBackground: {
    background: "red",
    fontSize: 24,
    fontFamily: "Comic Sans MS"
  }
};

export default class App extends React.Component {
  state = {
    left: false,
    data: [1, 2, 3],
    users: [
      { username: "gosho", email: "pesho@pesho.com" },
      { username: "gosho1", email: "pesho1@pesho.com" },
      { username: "gosho2", email: "pesho2@pesho.com" }
    ],
    test: "test"
  };
  constructor() {
    super();
  }

  toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ left: open });
  };

  componentDidMount() { }

  render() {
    return (
      <Router>
        <div style={{ background: "#e1e1e1" }}>
          {/*<Route path="/" component={TopLayout}/>*/}
          {/*<TopLayout {...this.props}/>*/}
          <Route path="/" exact component={HomeScreen} />
          <Route path="/about/" component={AboutScreen} />
          <Route path="/delete/" component={DeleteScreen} />
          <Route path="/obqva/:slug" component={ListingPreviewScreen} />
          <Route path="/listing/create" component={CreateListing} />
          <Route path="/listing/edit/:id" component={EditListing} />
          <Route path="/messages/" component={MessagesScreen} />
          <Route path="/message/:conversationId" component={Conversation} />
          <Route path="/my-profile/" component={ProfleMenuScreen} />
          <Route path="/profile-settings" component={SettingsScreen} />
          <Route path="/profile-password" component={PasswordScreen} />
          <Route path="/profile-info" component={UserProfileInfo} />
          <Route path="/my-listings/" component={MyListings} />
          <Route path="/filters/" component={Filters} />
          <Route path="/success-payment/" component={SuccessPaymentScreen} />
          <Route path="/user/promote/listing/:id" component={PromoteListing} />
          <Route path="/login/facebook" component={FacebookLogin} />
          <Route path="/auth" component={AuthScreen} />
          <Route path="/contacts" component={ContactScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/password/reset" component={PasswordReset} />
          <Route path="/password-change/token" component={PasswordResetTokenScreen} />
          <Route path="/ads/user/:id" component={PublicProfileScreen} />
          <Route path="/terms" component={TermsScreen} />
        </div>
      </Router>
    );
  }
}
