import React from "react";
import Slider from "../../components/SliderPrevewListing";
import Breadcrumb from "../../components/Breadcrumb";
import FooterMessageAndCall from "../../components/FooterMessageAndCall";
import SingleListing from "../../partials/listings/SingleListing";
import {
  Share,
  PinDrop,
  Create,
  Send,
  RepeatOneSharp
} from "@material-ui/icons";
import NetworkClient from "../../api/NetworkClient";
import renderHTML from "react-render-html";
import { KeyboardArrowLeft } from "@material-ui/icons";
import AdSense from 'react-adsense';
import {Link} from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
  CardSubtitle,
  CardText
} from "reactstrap";
import GoBackArrow from "../../components/GoBackArrow";
import constants from "../../constants/Constants";
import Cookies from 'js-cookie';

const styles = {
  cardTitle: {
    position: "relative",
    fontSize: "38px",
    fontWeight: "700"
  },
  cardTitleSpan: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "#666",
    fontSize: "13px",
    fontWeight: 400
  },
  fontFamily: {
    fontFamily: "font-family: Open Sans,sans-serif"
  },
  listingTitle: {
    fontSize: "22px",
    lineHeight: "24px",
    fontWeight: "700",
    fontFamily: "Open Sans Condensed,sans-serif",
    wordBreak: "break-word",
    textOverflow: "ellipsis"
  },
  cardUserInfo: {
    fontSize: "20px",
    lineHeight: "22px",
    marginBottom: "2px",
    fontWeight: "700",
    color: "#3b6fb6"
  }
};




export default class ListingPreview extends React.Component {
  state = {
    backgroundColor: "rgba(59, 111, 182, 0.286)",
    date_ago: "",
    listing: [],
    userListing: [],
    userCity: [],
    userVillage: [],
    moreListings: [],
    sameUserListings: [],
    attributes: [],
    topCategories: [],
    mainImage: "/img/icons/usr-nophoto.svg",
    countListings: 0
  };
  constructor(props) {
    super(props);
  }

  printListings(arrListing) {
    return this.state[arrListing].map((listing, i) => (
      <SingleListing listing={listing} key={i} col={6} />
    ));
  }

  listenScrollEvent = e => {
    if (window.scrollY > 250) {
      this.setState({ color: "rgba(59, 111, 182, 0.777)" });
    } else {
      let opacity = window.scrollY == 0 ? 0 : (window.scrollY / 1000) * 2.1;
      this.setState({ color: "rgba(59, 111, 182, " + opacity + ")" });
    }
  };

  loadListing() {
    NetworkClient.get(this.props.location.pathname).then(response => {
      Cookies.set('listing_scroll_to_id', response.listing.id);
      this.setState({ listing: response.listing });
      this.setState({ attributes: response.attributes });
      window.scrollTo(0, 0);
      this.setState({ date_ago: response.date_ago });
      this.setState({ userListing: response.listing.user });
      this.setState({ userCity: response.listing.city });
      this.setState({ userVillage: response.listing.village });
      this.setState({ topCategories: response.topCategories });
      this.setState({ sameUserListings: response.sameUserListings.data });
      this.setState({ moreListings: response.moreListings.data });
      this.setState({ mainImage: response.listing.user.mainPhoto });
      this.setState({countListings: response.countListings});
    });
  }

  refresListing() { }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadListing();
    }
  }

  componentDidMount() {
    this.loadListing();
    window.addEventListener("scroll", this.listenScrollEvent);
  }


  showTop() {
    return (
      <div
        //   style={{  }}
        style={{
          zIndex: "999",
          width: "100%",
          height: "40px",
          position: "fixed",
          color: "white",
          backgroundColor: this.state.color
        }}
      >
        <KeyboardArrowLeft onClick={() => {
          let obj = {
            pathname: '/filters',
            search: '',
            state: { loadListings: 1 }
          };

          this.props.history.push(obj);
        }} fontSize="large" />
        <Share style={{ float: "right", margin: "5px 5px 0 0", display: 'none' }} />
      </div>
    );
  }

  printAttributes(attributes) {
    return attributes.map((attribute, i) => (
      <li key={i}>
        <span style={{ fontWeight: "700" }}>{attribute.label}:</span>
        <span>&nbsp;{attribute.items.join(",")}</span>
      </li>
    ));
  }

  // footer
  render() {
    let images = this.state.listing.images;

    return (
      <div style={styles.fontFamily}>
        {this.showTop()}
        {images ? (
          <Slider images={images} />
        ) : (
            <div className="text-center text-white display-3">
              <span>БЕЗ СНИМКА</span>
              {/* <img src="http://localhost:8000/images/no-images.jpg" width="200" /> */}
            </div>
          )}

        <Container style={{ paddingBottom: "77px" }}>
          <Breadcrumb
            categories={this.state.topCategories}
            history={this.props.history}
          />
          <Card>
            <CardBody>
              <CardTitle style={styles.cardTitle}>
                {this.state.listing.price_show ? this.state.listing.price_show : ""}
                {this.state.listing.price_show &&
                  this.state.listing.price_currency == 1
                  ? " лв."
                  : this.state.listing.price_show &&
                  renderHTML("<span> &euro; </span>")}
                {!this.state.listing.price_show && (
                  <span style={{ fontSize: 30 }}>По договаряне</span>
                )}
                <span style={styles.cardTitleSpan}>{this.state.date_ago}</span>
                <h1 style={styles.listingTitle}>{this.state.listing.title}</h1>
                <hr />
              </CardTitle>

              <ul className="list-unstyled">
                {this.printAttributes(this.state.attributes)}
              </ul>
            </CardBody>
          </Card>
          <Card style={{ marginTop: "10px" }}>
            <CardBody>
              <CardText
                dangerouslySetInnerHTML={{
                  __html: this.state.listing.description
                }}
              />
            </CardBody>
          </Card>
          <AdSense.Google
              client='ca-pub-6108900312282823'
              slot='3028449836'
              style={{ display: 'block' }}
              format='auto'
              responsive='true'
            />
          <Card style={{ marginTop: "10px" }}>
            <CardBody>
            <Link to={'/ads/user/' + this.state.listing.user_id}>
              <Row>
                <Col xs={4}>
                  <img
                    src={constants.BASE_DOMAIN_NAME + this.state.mainImage}
                    width="70"
                  />
                </Col>
                <Col xs={8} className="px-0 py-0">

                  <span style={styles.cardUserInfo}>
                    {this.state.userListing.isAnon == 1 ? "Скрито име" : this.state.userListing.name}
                  </span>
                  <br />
                  <span style={{color: '#3b6fb6 !important'}}>{this.state.countListings} обяви на потребителя</span><br/>
                  <span style={{fontSize: 14, color: '#666'}}>
                    В Prodavash.BG от {
                      this.state.userListing.created_at != undefined
                      ?   this.state.userListing.created_at.substring(0,10)
                      : ''}
                  </span>

                </Col>
              </Row>
              </Link>

              <div />
            </CardBody>
          </Card>
          <Card style={{ marginTop: "10px" }}>
            <CardBody>
              <PinDrop />
              {this.state.userCity ? this.state.userCity.name : ""}{" "}
              {this.state.userVillage ? this.state.userVillage.name : ""}
            </CardBody>
          </Card>
          <div className="my-4">
            <span className={"views"}>
              Видяно: {this.state.listing.views} пъти
            </span>
            <a
              style={{
                float: "right",
                marginTop: "3px",
                color: "#e00",
                lineHeight: "12px",
                paddingRight: "16px"
              }}
              onClick={() => { this.props.history.push('/contacts') }}
            >
              Съобщи за нередност!
            </a>
          </div>
          <div
            style={{
              fontSize: "19px",
              padding: "0 10px",
              marginBottom: "10px"
            }}
          >
            Други обяви на потребителя: <br />
            <Row style={{ marginBottom: "27px" }}>
              {this.printListings("sameUserListings")}
            </Row>
            Още обяви в Prodavash.BG:
          </div>
          <Row>{this.printListings("moreListings")}</Row>
        </Container>
        <FooterMessageAndCall listing={this.state.listing} handleSend={this.sendMessage.bind(this)} />

      </div>
    );
  }
  sendMessage(reply) {

    let api_token = Cookies.get('api_token');
    if (!api_token) {
      this.props.history.push('/auth');
      return;
    }

    let listingId = this.state.listing.id;

    if (reply.length == 0) {
      alert("Моля въведете съобщение!");
      return;
    }

    NetworkClient.post(`/user/message/${listingId}`, {
      reply: reply
    }).then(response => {
      alert("Успешно изпратено съобщение!");
    });
  }
}
