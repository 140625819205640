import React from "react";

class TiresAndWheels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seasons: ["Летни", "Зимни", "Всесезонни"],
      width: [
        70,
        80,
        85,
        90,
        95,
        100,
        105,
        110,
        115,
        120,
        125,
        130,
        135,
        140,
        145,
        150,
        155,
        160,
        165,
        170,
        175,
        180,
        185,
        190,
        195,
        200,
        205,
        210,
        215,
        220,
        225,
        230,
        235,
        240,
        245,
        250,
        255,
        260,
        265,
        270,
        275,
        280,
        285,
        295,
        300,
        305,
        315,
        325,
        330,
        335,
        345,
        355,
        375,
        395,
        425,
        435,
        445
      ],
      height: [
        25,
        30,
        35,
        40,
        45,
        50,
        55,
        60,
        65,
        70,
        75,
        80,
        82,
        85,
        90,
        95,
        100,
        105,
        110,
        115,
        120
      ],
      diameter: [
        10,
        11,
        12,
        13,
        14,
        15,
        15.5,
        16,
        16.5,
        17,
        18,
        19,
        19.5,
        20,
        21,
        22,
        22.5,
        23,
        24,
        26,
        28
      ],
      wheelsBrands: [
        "Accelera",
        "Achilles",
        "Admiral",
        "Alliance",
        "America",
        "Atturo",
        "Autogrip",
        "Avon",
        "Barum",
        "BCT",
        "BFGoodrich",
        "Bridgestone",
        "Capitol",
        "Ceat",
        "Clear",
        "Continental",
        "Cooper",
        "Dayton",
        "Debica",
        "Delinte",
        "Dextero",
        "Dunlop",
        "Duro",
        "Durun",
        "Effiplus",
        "Eurostone",
        "Falken",
        "Federal",
        "Firestone",
        "Fortuna",
        "Fulda",
        "Fullway",
        "General",
        "Gislaved",
        "GoldenTyre",
        "Goodride",
        "Goodyear",
        "Gremax",
        "GTRadial",
        "Haida",
        "Hankook",
        "Hero",
        "HIFLY",
        "HighPerformer",
        "Infinity",
        "InsaTurbo",
        "Interco",
        "Interstate",
        "Kelly",
        "Kenda",
        "Kinforest",
        "KingMeiler",
        "KingsTire",
        "Kingstar",
        "Kleber",
        "Kormoran",
        "Kumho",
        "Lassa",
        "Lexani",
        "Linglong",
        "Maloya",
        "Marangoni",
        "Marix",
        "Marshal",
        "Mastersteel",
        "Matador",
        "Maxtrek",
        "Maxxis",
        "Meteor",
        "Michelin",
        "MickeyThompson",
        "Minerva",
        "Nankang",
        "Nexen",
        "Nokian",
        "Novex",
        "Pace",
        "Petlas",
        "Pirelli",
        "Pneumant",
        "Recip",
        "Regal",
        "Riken",
        "Roadstone",
        "Rockstone",
        "Rotalla",
        "Rotex",
        "Runway",
        "Sailun",
        "Sava",
        "Semperit",
        "Silverstone",
        "Sonny",
        "Star",
        "StarPerformer",
        "Starco",
        "Starfire",
        "Starmaxx",
        "Stunner",
        "Sunew",
        "Sunny",
        "Syron",
        "Tigar",
        "Toyo",
        "Trayal",
        "Triangle",
        "Tyfoon",
        "Uniroyal",
        "Viking",
        "Vredestein",
        "VSP",
        "Wanli",
        "Westlake",
        "WinterTact",
        "Yokohama",
        "Yokohama",
        "Zeetex",
        "Zeetex",
        "Zetum",
        "Zetum",
        "Други"
      ],
      material: ["Алуминиеви", "Железни", "Магнезиеви", "Други"],
      asd: []
    };
  }
  render() {
    return <li className="list-group-item rightArrow">Сезони</li>;
  }
}

export default TiresAndWheels;
