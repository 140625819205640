import React from "react";
import { Row, Col } from "reactstrap";
import GoBackArrow from "../GoBackArrow";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default function header(props) {
  return (
    <div
      style={{
        top: 0,
        width: "100%",
        zIndex: "2000",
        position: "fixed",
        height: "50px",
        backgroundColor: "white",
        color: "#3b6fb6",
        boxShadow: "0 1px 4px rgba(0,0,0,.3)",
        fontSize: 27
      }}
    >
      <Row>
        <Col xs={2}>
          <GoBackArrow history={props.history} backPage={props.backPage} />
        </Col>
        <Col xs={8} className={"text-center pt-1"}>
          <span
            onClick={() => {
              props.history.push('/');
            }}
            style={{
              fontFamily: "Arial, cursive",
              color: "#F70",
              fontSize: "1.5rem",
              fontWeight: "800"
            }}
          >
            <span style={{ color: "#505ce2" }}>PRODAVASH</span>.BG
          </span>
        </Col>
        <Col xs={2}>
          {props.addListing && (
            <Link to="/listing/create">
              <Add style={{ fontSize: 37, color: "tomato" }} />
            </Link>
          )}
        </Col>
      </Row>
    </div>
  );
}
